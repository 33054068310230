const faqs = {
    pregunta1: {
        titulo: 'When will they notify me of the date of my purchased flight?',
        parrafos: [
            'If your purchase corresponds to a Shared ticket, we will contact you when your flight has been completed to coordinate the flight date.',
            'If your purchase corresponds to a group Humming Day promotion ticket, in the next few days we will contact you to coordinate the flight date.'
        ]
    },

    pregunta4: {
        titulo: 'On what date does the flight I bought take place?',
        parrafos: [
            'If your purchase corresponds to a Shared ticket, we will contact you when your flight has been completed to coordinate the flight date.',
            'If your purchase corresponds to a group Humming Day promotion ticket, in the days following your purchase we will contact you to coordinate the flight date.',
            'The dates of your flight will be coordinated in days allowed for the Transition health phase (Phase 2) or higher.',
            'Make your web check-in 48 hours before the flight, logging in with your username at hummingbird.cl. Here you can modify and / or confirm the final data of the passengers.',
        ]
    },


    pregunta5: {
        titulo: 'Can I buy a ticket as a gift?',
        parrafos: [
            'If your purchase is a gift, after the purchase is made, contact us by email or whatsapp and request a GiftCard for the beneficiary.',
        ]
    },

    pregunta6: {
        titulo: 'Why do they ask me for so much personal information to make the purchase?',
        parrafos: [
            "In aeronautics, operational safety is an urgent element to protect people's lives. The aeronautical authority obliges every aircraft operator to communicate passenger data in their Flight Plan, a document that is delivered before each flight. Additionally, these data are an important legal safeguard for liability insurance coverage to which the operators are affiliated.",
        ]
    },


    pregunta7: {
        titulo: 'How will I know which company and in which aircraft my flight will take place?',
        puntos: [
            'Operating company',
            'Aircraft model',
            'Pilot in command',
            'Address and time of presentation to boarding',
            'Necessary documentation',
            'Baggage allowed',
            'Items prohibited on board',
        ],
        parrafos: [
            'Once your web check-in is done, you will receive an email with your flight information',
            'You can make your web check-in 48 hours before the flight, logging in with your username at hummingbird.cl. Here you can modify and / or confirm the final data of the passengers.']
    },


    pregunta8: {
        titulo: 'What experience do Hummingbird and the companies that make the flights have?',
        parrafos: [
            'Hummingbird is a company made up of a multidisciplinary team of aviation and technology professionals, such as Pilots, Commercial Aviation Engineers, Systems Engineers, Full-Stack Developers and Front-End Developers. Among our external providers are various media support companies, such as Community Management, Design, Advertising and support in legal and accounting advice.',
            'Our aircraft operating partners are companies certified by the aeronautical authority. We take care to check that your legal documentation is up to date and monitor their renewal within the established deadlines.',
            'In each of the experiences offered by our platform, you will find the following information regarding the operator:',
        ],
        puntos: [
            'Average rating made by other users who have flown in the same experience',
            'Number of flights or experiences that the operator has made with Hummingbird',
            'Number of passengers who have purchased the flight or experience',
        ],
    },


    pregunta9: {
        titulo: 'What are the safety measures?',
        parrafos: [
            'At Hummingbird we guarantee the flight safety of our passengers, through the following policy.',
            'All of our aircraft operating partners have their current certifications, issued by the General Directorate of Civil Aeronautics. This means that the aeronautical authority has reviewed and approved them to carry out air operations, guaranteeing:',
            'At Hummingbird, we monitor and control the renewal of certificates in each operator. We verify that the security policies of our operators are under the international standard of Operational Security Management System (SMS), which guarantees that our operators have, among other elements, with:',
        ],
        puntos1: [
            'Commercial Operator Certificate, with authorization to commercialize flights, under the DAN-135 regulation',
            'Airworthiness of aircraft',
            'Up-to-date aircraft maintenance, permanently controlled and carried out by Aeronautical Maintenance Centers authorized for each type of aircraft.',
            'Pilots with recurring training and valid aeronautical licenses.',
            'Civil Liability Insurance.',
        ],
        puntos2: [
            'Operational Safety Policy',
            'Risk management and continuous improvement',
            'Emergency response plan',
            'Evaluation of experience',
            'Secure passenger registration',
            'Checking the size and weight of luggage',
            'Ausencia de mercancías peligrosas',
            'Evaluation of the pilot in command of the aircraft'
        ],
    },

    pregunta10: {
        titulo: 'What are the security measures against Covid-19?',
        parrafos: [
            'Our operators have hygiene and safety protocols in place to protect the crew and passengers from Covid-19.',
            'With Hummingbird, avoid contagion in busy airports and fly from the private offices of operators in small airfields.',
        ],
        puntos: [
            'Temperature measurement',
            'Use of alcohol gel and masks throughout the experience.',
            'Cleaning and disinfection of the aircraft before and after each flight. Cleaning includes manipulable surfaces and elements, such as phones, handles, command and control elements of the aircraft.',
            'We minimize the number of personnel and procedures prior to the execution of the flight, promoting social distancing, washing and / or disinfection of hands',
        ],
    },
}

export default faqs