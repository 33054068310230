import Vue from 'vue'
import firebase from 'firebase'
import router from '../../router';
import store from '@/store'


export default {
    namespaced: true,
    state: {
        usuario: null,
        authLoading: true
    },
    mutations: {
        LOG_IN(state, user) {
            state.usuario = user;

            if (!state.usuario.photoURL) {
                const icons = store.state.icons;

                state.usuario.photoURL = icons.purple.user;
            }
            if (!state.usuario.displayName) {

                state.usuario.displayName = state.usuario.email;
            }
        },
        LOG_OUT(state) {
            state.usuario = null
            localStorage.removeItem('uid')
        },
        LOADING_ON(state) {
            state.authLoading = true
        },
        LOADING_OFF(state) {
            state.authLoading = false
        }
    },
    actions: {
        async signInWithEmailAndPass({ commit }, user) {
            try {
                await firebase
                    .auth()
                    .signInWithEmailAndPassword(user.email, user.password);

                let usuario = firebase.auth().currentUser
                let documentoUsuario = null

                const usuarioEnFirestore = await firebase
                    .firestore()
                    .collection("usuarios")
                    .where("uid", "==", usuario.uid)
                    .get();

                usuarioEnFirestore.forEach((doc) => {
                    documentoUsuario = doc.data();
                });

                if (documentoUsuario) {
                    usuario = documentoUsuario
                } else {

                    const datosUsuario = {
                        displayName: usuario.displayName,
                        email: usuario.email,
                        photoURL: usuario.photoURL,
                        uid: usuario.uid,
                        misVuelos: [],
                        misExperiencias: [],
                        telefono: '',
                        rut: '',
                        fechaNacimiento: '',

                    }

                    await firebase.firestore().collection('usuarios').doc(datosUsuario.uid).set(datosUsuario)

                    usuario = datosUsuario
                }

                commit('LOG_IN', usuario)
                return true;
            }
            catch (error) {
                Vue.$toast.error(error.message, {
                    position: "top",
                    duration: 3000,
                });
                return false
            }
        },
        async signInWithFb() {
            try {
                const provider = new firebase.auth.FacebookAuthProvider();
                await firebase.auth().signInWithRedirect(provider);

            } catch (error) {
                alert(error.message)
                return false
            }
        },
        async signInWithGoogle() {
            try {
                const provider = new firebase.auth.GoogleAuthProvider();
                await firebase.auth().signInWithRedirect(provider);

            } catch (error) {
                console.log(error)
                return false
            }
        },

        async createUser({ }, usuario) {
            try {
                await firebase
                    .auth()
                    .createUserWithEmailAndPassword(usuario.email, usuario.password)

                return true
            } catch (error) {
                Vue.$toast.error(error.message, {
                    position: "top",
                    duration: 3000,
                });
                return false
            }

        },
        async getDataFromRedirect({ commit }) {
            try {
                commit('LOADING_ON')
                const result = await firebase.auth().getRedirectResult();
                let usuario = null
                let documentoUsuario = null

                if (result.user != null) {
                    usuario = result.user


                    const usuarioEnFirestore = await firebase
                        .firestore()
                        .collection("usuarios")
                        .where("uid", "==", usuario.uid)
                        .get();


                    usuarioEnFirestore.forEach((doc) => {
                        documentoUsuario = doc.data();
                    });

                    if (documentoUsuario) {
                        usuario = documentoUsuario
                    } else {

                        const datosUsuario = {
                            displayName: usuario.displayName,
                            email: usuario.email,
                            photoURL: usuario.photoURL,
                            uid: usuario.uid,
                            misVuelos: [],
                            misExperiencias: [],
                            telefono: '',
                            rut: '',
                            pasaporte: '',
                            nacionalidad: '',
                            direccion: ''
                        }

                        await firebase.firestore().collection('usuarios').doc(datosUsuario.uid).set(datosUsuario);

                        usuario = datosUsuario
                    }

                    commit('LOG_IN', usuario)
                    localStorage.setItem('uid', JSON.stringify(usuario.uid))

                }
                commit('LOADING_OFF')

                return true;
            } catch (error) {
                Vue.$toast.error(error.message, {
                    position: "top",
                    duration: 3000,
                });
                commit('LOADING_OFF')

                return false
            }
        },
        async logout({ commit }) {
            try {
                await firebase.auth().signOut()
                await commit('LOG_OUT')
                router.push({ name: 'Login' })
                Vue.$toast.info('Sesión cerrada', {
                    position: "top",
                    duration: 3000,
                });

            } catch (error) {
                console.log(error)
            }
        },
        async actualizarDatosUsuario({ state }, datos) {
            try {
                const userId = state.usuario.uid;

                await firebase.firestore().collection('usuarios').doc(userId).update({
                    displayName: datos.nombre,
                    email: datos.email,
                    rut: datos.rut,
                    pasaporte: datos.pasaporte,
                    telefono: datos.telefono,
                    direccion: datos.direccion,
                    nacionalidad: datos.pais
                })

                Vue.$toast.success('Datos actualizados de manera correcta', {
                    duration: 3000,
                    position: 'top'
                });
            } catch (error) {
                Vue.$toast.error(error.message, {
                    position: "top",
                    duration: 3000,
                });
            }
        },
        async autoLogin({ commit }) {
            const uidLS = JSON.parse(localStorage.getItem('uid'))
            let usuario = null;

            try {
                if (uidLS) {
                    await firebase
                        .firestore()
                        .collection("usuarios")
                        .doc(uidLS)
                        .onSnapshot((doc) => {
                            usuario = doc.data();
                            commit('LOG_IN', usuario)
                        })
                }
            } catch (error) {
                console.log(error);
            }

        }
    },
    getters: {
      
    }
}