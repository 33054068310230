<template>
  <div class="SelectFechas">
    <h2>
      {{ lenguages[idioma].heroSection.buscador.fecha || "Fecha" }}
    </h2>
    <v-menu
      v-model="abrirCalendario"
      :close-on-content-click="closeOnClick"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          v-bind="attrs"
          v-on="on"
          :color="colors.mainPurple"
          :placeholder="
            lenguages[idioma].heroSection.buscador.seleccionaTusFechas ||
              'Selecciona tus fechas'
          "
          flat
          solo
          height="15"
          class="SelectFechas__input"
          name="fechas"
        ></v-text-field>
      </template>
      <div @click="seleccionAutomaticaFechaVuelta">
        <v-date-picker
          v-model="date"
          :range="esIdaYVuelta"
          :color="colors.mainPurple"
          locale="es-es"
          first-day-of-week="1"
          no-title
          :min="new Date().toISOString().slice(0, 10)"
          show-adjacent-months
          :show-current="false"
          @change="seleccionarFecha"
        ></v-date-picker>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      date: null,
      abrirCalendario: false,
      vueltaAutomatica: true,
    };
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Buscador", ["tipoDeVuelo", "busqueda"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    esIdaYVuelta() {
      return this.busqueda.tipoDeVuelo === "Solo ida" ||
        this.busqueda.tipoDeViaje === "Experiencias"
        ? false
        : true;
    },
    closeOnClick() {
      return this.esIdaYVuelta ? false : true;
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    async seleccionarFecha() {
      let fecha = { ida: "", vuelta: "" };

      if (typeof this.date === "object") {
        const fechaOrdenada = this.date.sort();
        fecha.ida = fechaOrdenada[0];
        fecha.vuelta = fechaOrdenada[1];
      } else {
        fecha.ida = this.date;
      }

      await this.SET_BUSQUEDA_PARAMS({ fechas: fecha });
    },
    seleccionAutomaticaFechaVuelta() {
      if (
        this.esIdaYVuelta &&
        this.vueltaAutomatica &&
        this.date.length === 1
      ) {
        const fechaIda = moment(this.date[0]);
        const formato = "YYYY-MM-DD";
        this.date.push(fechaIda.add(7, "d").format(formato));
        this.seleccionarFecha();
        this.vueltaAutomatica = false;
      }
    },
  },
  mounted() {
    if (this.busqueda.tipoDeVuelo === "Ida y vuelta") {
      this.date = [this.busqueda.fechas.ida, this.busqueda.fechas.vuelta];
    } else {
      this.date = this.busqueda.fechas.ida;
    }
  },
};
</script>

<style lang="scss" scoped>
.SelectFechas {
  @include inputInside;
  position: relative;
  height: 73px;
  &__placeholder {
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid #000;
  }
  &__input {
    transform: translateY(-7px);
  }
}
</style>
