const misDatos = {
    nombreYApellido: "Nombre y apellido",
    tipoDeDocumento: "Tipo y número de documento",
    paisDeOrigen: "País de origen",
    correoElectronico: "Correo electrónico",
    numeroMovil: "Número móvil",
    direccion: "Dirección",
    intereses: "Intereses",
    helicoptero: "Helicóptero",
    avion: "Avión",
    vinas: "Viñas",
    playa: "Playa",
    mar: "Mar",
    montanas: "Montañas",
    deportes: "Deportes",
    aventuras: "Aventuras",
    cancelar: "cancelar",
    confirmarEdicion: "confirmar edición"
}

export default misDatos