import router from "../../router";
import store from "@/store";
import firebase from "firebase"
import { getVuelosFunction } from "./functions"
export default {
  namespaced: true,
  state: {
    busquedaHomeMobile: "",
    panelActivo: "",
    busqueda: {
      origen: "",
      destino: "",
      fechas: {
        ida: "",
        vuelta: "",
      },
      aeronave: {
        ida: "",
        vuelta: "",
      },
      pasajeros: 1,
      tipoDeVuelo: "Solo ida",
      tipoDeViaje: "Experiencias",
      tipoDeServicio: {
        ida: "",
        vuelta: "",
      },
    },
  },
  mutations: {
    SET_BUSQUEDA_PARAMS(state, busqueda) {
      if (typeof busqueda === "object") {
        const propiedad = Object.keys(busqueda);
        const valor = Object.values(busqueda);

        state.busqueda[propiedad] = valor.find((e) => e) || '';
      } else {
        state.busqueda = busqueda;
      }

      state.panelActivo = [];
    },
    BUSQUEDA_A_STORE(state, busqueda) {
      state.busquedaHomeMobile = busqueda;
    },
    CAMBIAR_PANEL_ACTIVO(state, panel) {
      state.panelActivo = panel;
    },
  },
  actions: {
    reunirCriteriosYBuscar({ state, rootState: { Auth: { usuario } } }) {

      const busqueda = { ...state.busqueda }
      if (usuario) busqueda.usuario = usuario.email;
      busqueda.timestamp = firebase.firestore.FieldValue.serverTimestamp()
      firebase.firestore().collection("busquedas").add(busqueda)
      router.push({ name: "ResultadosBusqueda" });
    },
  },
  getters: {
    origenes: () => {
      const experiencias = store.state.Experiencias.experiencias
      const origenesEXP = [...new Set(experiencias.map(({ data }) => data.origen))]
      return origenesEXP
    },
    destinos: (state) => {
      const experiencias = store.state.Experiencias.experiencias
      const destinosEXP = [...new Set(experiencias.map(({ data }) => data.destino.replace(/[0-9-]/, "##").split("##")[0].trim()))]
      return destinosEXP
    },
    getVuelos: ({ busqueda }) => {
      let experiencias = store.state.Experiencias.experiencias
      let vuelos = store.state.Vuelos.vuelos
      const viajes = getVuelosFunction({ busqueda, experiencias, vuelos })
      return viajes
    }
    // getVuelos: ({  busqueda }) => {
    //   const vuelos = store.state.Vuelos.vuelos.filter(v => {
    //     return moment(`${v.data.fecha} ${v.data.horaDespegue}`).isSameOrAfter(moment().add(2, 'h'))
    //       && (v.data.capacidad - v.data.ventas) > 0
    //   });
    //   const experiencias = store.state.Experiencias.experiencias.filter(v => {
    //     return moment(`${v.data.fecha} ${v.data.horaDespegue}`).isSameOrAfter(moment().add(2, 'h'))
    //       && (v.data.capacidad - v.data.ventas) > 0
    //   });
    //   let vuelosFiltradosIda = [];
    //   let vuelosFiltradosVuelta = [];
    //   let fechaIda = moment(busqueda.fechas.ida);
    //   let fechaVuelta = moment(busqueda.fechas.vuelta);

    //   if (
    //     busqueda.destino != "" ||
    //     busqueda.origen != "" ||
    //     busqueda.aeronave.ida != "" ||
    //     busqueda.fechas.ida != ""
    //   ) {
    //     //Vuelos
    //     if (busqueda.tipoDeViaje === "Vuelos") {
    //       //IDA//
    //       vuelosFiltradosIda = vuelos.filter((v) => {
    //         return (
    //           //Origen
    //           formatString(v.data.origen) === formatString(busqueda.origen) &&

    //           //destino
    //           formatString(v.data.destino).includes(
    //             formatString(busqueda.destino)
    //           ) &&

    //           //fecha
    //           moment(v.data.fecha).isBetween(
    //             fechaIda.subtract(7, "d"),
    //             moment(busqueda.fechas.ida).add(30, "d")
    //           ) &&
    //           moment(v.data.fecha + ' ' + v.data.horaDespegue).isSameOrAfter(moment().add(2, 'h')) &&

    //           // PASAJEROS
    //           v.data.capacidad - v.data.ventas >= busqueda.pasajeros
    //         );
    //       });
    //       //Aeronave
    //       if (
    //         busqueda.aeronave.ida != "" &&
    //         busqueda.aeronave.ida != "Todo tipo de aeronave"
    //       ) {
    //         vuelosFiltradosIda = vuelosFiltradosIda.filter((v) => {
    //           return (
    //             formatString(busqueda.aeronave.ida) ==
    //             formatString(v.data.aeronave)
    //           );
    //         });
    //       }

    //       // Tipo servicio (Solo se pregunta en mobile)
    //       if (busqueda.tipoDeServicio.ida === "Charter") {
    //         vuelosFiltradosIda = vuelosFiltradosIda.filter((v) => {
    //           return v.data.ventas === 0;
    //         });
    //       }

    //       //VUELTA//
    //       if (busqueda.tipoDeVuelo === "Ida y vuelta") {
    //         vuelosFiltradosVuelta = vuelos.filter((v) => {
    //           return (
    //             //Origen
    //             formatString(v.data.origen) ===
    //             formatString(busqueda.destino) &&
    //             //destino
    //             formatString(v.data.destino).includes(
    //               formatString(busqueda.origen)
    //             ) &&
    //             //fecha
    //             moment(v.data.fecha).isBetween(
    //               fechaVuelta.subtract(7, "d"),
    //               moment(busqueda.fechas.vuelta).add(30, "d")
    //             ) &&
    //             moment(v.data.fecha).isSameOrAfter(moment(), "d")
    //           );
    //         });
    //         //Aeronave
    //         if (
    //           busqueda.aeronave.vuelta != "" &&
    //           busqueda.aeronave.vuelta != "Todo tipo de aeronave"
    //         ) {
    //           vuelosFiltradosVuelta = vuelosFiltradosVuelta.filter((v) => {
    //             return (
    //               formatString(busqueda.aeronave.vuelta) ==
    //               formatString(v.data.aeronave)
    //             );
    //           });
    //         }
    //         //Tipo de servicio
    //         if (busqueda.tipoDeServicio.vuelta === "Charter") {
    //           vuelosFiltradosVuelta = vuelosFiltradosVuelta.filter((v) => {
    //             return v.data.ventas === 0;
    //           });
    //         }
    //       }
    //     }
    //     //Experiencias
    //     else if (busqueda.tipoDeViaje === "Experiencias") {
    //       // Origen + Destino
    //       let { destino, origen, fechas, aeronave, pasajeros } = busqueda;
    //       aeronave = aeronave.ida || aeronave.vuelta;
    //       fechas = fechas.ida || fechas.vuelta;

    //       destino = formatString(destino);
    //       origen = formatString(origen);
    //       aeronave = formatString(aeronave);

    //       vuelosFiltradosIda = experiencias.map((e) => {
    //         let {
    //           aeronave: aeronaveE,
    //           fecha: fechaE,
    //           origen: origenE,
    //           destino: destinoE,
    //           capacidad: capacidadE,
    //           ventas: ventasE
    //         } = e.data;

    //         // Filtro por asientos
    //         const asientosDisponibles = capacidadE - ventasE
    //         if (pasajeros > asientosDisponibles) return false

    //         // Filtro por aeronave
    //         if ((aeronave == "helicoptero" || aeronave == "avion") && aeronave !== aeronaveE) {
    //           return false
    //         }

    //         // Filtro por Destino
    //         if (destino && formatString(destino) !== formatString(destinoE)) return false

    //         // Filtro por Origen
    //         if (origen && formatString(origen) !== formatString(origenE)) return false


    //         //  Filtro por fechas
    //         if (
    //           fechas &&
    //           (!moment(fechas).isBetween(
    //             moment(fechaE).subtract(30, "d"),
    //             moment(fechaE).add(7, "d")
    //           )
    //             ||
    //             moment(fechaE).isBefore(moment()))) {
    //           return false
    //         }


    //         return e;
    //       });

    //       vuelosFiltradosIda = vuelosFiltradosIda.filter(Boolean);

    //     }
    //   }
    //   return { ida: vuelosFiltradosIda, vuelta: vuelosFiltradosVuelta };
    // },
  },
};
