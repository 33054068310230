import firebase from "firebase";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    vuelosElegidos: {
      ida: null,
      vuelta: null,
    },
    pasajerosAnotados: false,
    datosPasajeros: [],
    ventaActual: {},
    datosVenta: {},
    checkInPasajeros: null,
    ventas: [],

    promoAplicada: false
  },
  mutations: {
    SET_PROMO(state, price) {
      state.promoAplicada = true;
      state.vuelosElegidos.ida.data.precio = price
    },

    VUELOS_ELEGIDOS(state, vuelos) {
      state.vuelosElegidos = vuelos;
    },
    EDITAR_PASAJEROS(state, pasajeros) {
      state.vuelosElegidos.info.pasajeros = pasajeros;
    },
    PASAJEROS_ANOTADOS(state, boolean) {
      state.pasajerosAnotados = boolean;
    },
    LISTA_DE_PASAJEROS(state, pasajeros) {
      state.datosPasajeros = pasajeros;
    },
    VENTA_REALIZADA(state, venta) {
      state.ventaActual = venta;
    },
    AGREGAR_DATOS_VENTA(state, datosVenta) {
      state.datosVenta = datosVenta;
    },
    FORMULARIO_CHECK_IN(state, datos) {
      state.checkInPasajeros = datos
    },
    GET_VENTAS(state, ventas) {
      state.ventas = ventas;
    },
    SET_FECHA_DE_VUELO(state, fecha) {
      state.vuelosElegidos.ida.data.fecha = fecha
    }
  },
  actions: {
    async agregarVenta({ commit }, venta) {
      try {
        let ventaOK = await firebase
          .firestore()
          .collection("ventas")
          .add(venta);
        let idVenta = ventaOK.id;
        venta.id = idVenta;

        commit("VENTA_REALIZADA", venta);
        return idVenta;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async agregarVentaAUsuario({ commit, state }, venta) {
      try {
        const usuario = store.state.Auth.usuario;
        const tipoDeviaje = state.ventaActual.details.info.tipoDeViaje;

        if (tipoDeviaje === "Vuelos") {
          await firebase
            .firestore()
            .collection("usuarios")
            .doc(usuario.uid)
            .update({
              misVuelos: firebase.firestore.FieldValue.arrayUnion(venta),
            });
          return true;
        } else if (tipoDeviaje === "Experiencias") {
          await firebase
            .firestore()
            .collection("usuarios")
            .doc(usuario.uid)
            .update({
              misExperiencias: firebase.firestore.FieldValue.arrayUnion(venta),
            });
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async descontarAsientosComprados({ commit, state }, venta) {
      try {
        const tipoDeviaje = state.ventaActual.details.info.tipoDeViaje;
        const tipoDevuelo = state.ventaActual.details.info.tipoDeVuelo;
        const pasajeros = state.ventaActual.details.info.pasajeros;

        if (tipoDeviaje === "Vuelos") {
          await firebase
            .firestore()
            .collection("vuelos")
            .doc(venta.details.ida.id)
            .update({
              ventas: firebase.firestore.FieldValue.increment(pasajeros),
            });


          if (tipoDevuelo === "Ida y vuelta") {
            await firebase
              .firestore()
              .collection("vuelos")
              .doc(venta.details.vuelta.id)
              .update({
                ventas: firebase.firestore.FieldValue.increment(pasajeros),
              });

          }
          return true;
        } else if (tipoDeviaje === "Experiencias") {
          await firebase
            .firestore()
            .collection("experiencias")
            .doc(venta.details.ida.id)
            .update({
              ventas: firebase.firestore.FieldValue.increment(pasajeros),
            });

          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async getVentaById({ }, id) {
      try {
        await firebase
          .firestore()
          .collection("ventas")
          .doc(id)
          .get()
          .then((doc) => {
            return doc.data();
          });
      } catch (error) {
        alert(error.message)
        return false
      }
    },
    async getVentas({ commit }) {
      try {
        await firebase
          .firestore()
          .collection("ventas")
          .orderBy("date", "desc")
          .get()
          .then((docs) => {
            const ventas = []
            docs.forEach(doc => {
              ventas.push({ id: doc.id, data: doc.data() })

            })
            commit("GET_VENTAS", ventas)
          });
      } catch (error) {
        alert(error.message)
        return false
      }
    },
    set_Promo({ commit }, price) {
      commit("SET_PROMO", price)
    }
  },
  getters: {
    montoTotal: (state) => {
      const precioIda = state.vuelosElegidos.ida.data.precio || 0;
      const precioVuelta = state.vuelosElegidos.vuelta
        ? state.vuelosElegidos.vuelta.data.precio
        : 0;
      const pasajeros = state.vuelosElegidos.info.pasajeros || 0;
      let total = 0;

      total = precioIda * pasajeros;

      state.vuelosElegidos.vuelta ? (total += precioVuelta * pasajeros) : total;

      return total;
    },
  },
};
