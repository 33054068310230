<template>
  <div id="Banner">
    <h1 style="font-size: 36px" class="my-4">
      {{ lenguages[idioma].banner.titulo || "Vuela seguro con Hummingbird" }}
    </h1>
    <v-row class="full-percent-height ma-0">
      <v-col class="imagen-fondo full-vh" cols="12" lg="6"> </v-col>
      <v-col class="texto-right pt-3 pt-lg-0" cols="12" lg="6">
        <ul>
          <li>
            <span v-html="lenguages[idioma].banner.punto1"> </span>
          </li>
          <li>
            <span v-html="lenguages[idioma].banner.punto2">
             
            </span>
          </li>
          <li>
            <span v-html="lenguages[idioma].banner.punto3">
              
            </span>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Banner",
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
};
</script>

<style scoped>
a {
  color: #645dc7 !important;
}
.imagen-fondo {
  background: url("https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2FimagenesDestinos%2FimagenesDestinosComprimidas%2FPiloto%20con%20mascarilla_1920x1920.jpg?alt=media&token=af1f95e2-f662-4131-8c4e-3ea63a95c3a8");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  border-radius: 10px;
}

.card {
  height: 335px;
  padding: 20px;
}

.full-percent-height {
  height: 100%;
}

ul {
  list-style: initial;
}

ul li {
  margin-bottom: 15px;
}

.texto-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 23px;
  padding: 0px 10px;
}

@media (max-width: 600px) {
  .texto-right {
    font-size: 16px;
  }
}

@media (min-width: 960px) {
  .texto-right {
    padding: 0px 70px;
  }
}

@media (max-width: 600px) {
  .card {
    height: auto;
  }
  .imagen-fondo {
    background-position: top;
    height: 250px;
  }
}
</style>
