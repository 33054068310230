const contacto = {
    titulo: "¿Alguna duda?",
    subTitulo: "¡Envíanos un mensaje!",
    nombreYApellido: 'Nombre y apellido',
    correo: 'Correo',
    mensaje: 'Mensaje',
    botonEnviar: "Enviar"
}


export default contacto