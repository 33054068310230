import firebase from 'firebase'

export default {
    namespaced: true,
    state: {
        aerodromos: []
    },
    mutations: {
        GET_AERODROMOS(state, aerodromos) {
            state.aerodromos = aerodromos;
        }
    },
    actions: {
        async addAerodromo({ }, data) {
            try {
                await firebase.firestore().collection('aerodromos').add(data);
            } catch (error) {
                console.log(error)
            }
        },
        async getAerodromos({ commit }) {
            try {
                await firebase
                    .firestore()
                    .collection('aerodromos')
                    .onSnapshot(snapshot => {
                        let aerodromos = []
                        snapshot.forEach(aerodromo => {
                            aerodromos.push({ id: aerodromo.id, data: aerodromo.data() })
                        })
                        commit('GET_AERODROMOS', aerodromos)
                    })
            } catch (error) {
                console.log(error);
            }
        },


    }
}