var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('header',{staticClass:"heroSection"},[_c('video',{ref:"video",attrs:{"muted":"","loop":"","webkit-playsinline":"true","playsinline":"true","autoplay":"","src":_vm.videoHeroSection},domProps:{"muted":true}}),_c('v-container',{staticClass:"relative"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('img',{staticClass:"heroSection__logo",attrs:{"src":_vm.logos.hummingbird.main,"alt":"logo humming bird","draggable":"false"}}),_c('div',{staticClass:"d-none d-sm-block"},[_c('LoginButton')],1)]),_c('h1',{staticClass:"heroSection__title mx-3 mx-md-auto text-center text-md-left"},[_vm._v(" "+_vm._s(_vm.lenguages[_vm.idioma].heroSection.dondeViajar || " A Dónde quieres viajar?")+" ")]),(!_vm.mobile)?_c('Buscador'):_c('div',{staticClass:"d-flex justify-center"},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"col-9 mx-auto",attrs:{"dark":"","depressed":"","color":"#645dc7"}},'v-btn',attrs,false),on),[_vm._v(" Buscar una experiencia "),_c('v-icon',{staticClass:"ml-4",attrs:{"dark":""}},[_vm._v(" mdi-magnify ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',{staticClass:"pa-5",attrs:{"color":"#f3f3f3"}},[_c('Buscador')],1)]}}])})],1)],1)],1),_c('div',{staticClass:"z-index",staticStyle:{"background":"white"}},[_c('section',{staticClass:"descubre"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"descubre__title"},[_vm._v(" "+_vm._s(_vm.lenguages[_vm.idioma].experienciasHome.compartidas.titulo || "Experiencias Premium")+" ")]),_c('h4',{staticClass:"descubre__subtitle d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.lenguages[_vm.idioma].experienciasHome.compartidas.subTitulo || "Vive experiencias inolvidables")+" ")])])],1),_c('Slider',{staticClass:"d-none d-lg-block pa-0 ",attrs:{"vuelos":_vm.experienciasCompartidas}}),_c('div',{staticClass:"d-flex descubre__slider py-5 d-lg-none"},_vm._l((_vm.experienciasCompartidas),function(experiencia,i){return _c('div',{key:i},[_c('router-link',{attrs:{"to":{
                name: 'Detalle',
                params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
              }}},[_c('CardExperiencias',{staticClass:"mr-md-8",attrs:{"infoExperiencia":experiencia}})],1)],1)}),0),_c('router-link',{staticClass:"btn-experiencias-correccion",attrs:{"to":{ name: 'Experiencias' }}},[_c('Boton',{attrs:{"texto":_vm.lenguages[_vm.idioma].experienciasHome.botonVerMas ||
                'Ver todas las experiencias',"icono":_vm.icons.green.arrowToRight,"borderRadius":"25px","height":"55px"}})],1)],1)],1),_c('v-container',[_c('PasoAPaso')],1)],1),_c('div',{staticClass:"z-index"},[(_vm.experienciasBlackFlyDay.length)?_c('section',{staticClass:"descubre pt-5 ",staticStyle:{"background":"black"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"descubre__title set_golden_Text",staticStyle:{"text-shadow":"1px 1px 1px gray"}},[_vm._v(" BLACK FLY DAY ")]),_c('h4',{staticClass:"descubre__subtitle d-none d-sm-block white--text"},[_vm._v(" Ofertas imperdibles para este Black Friday. ")])])],1),_c('Slider',{staticClass:"d-none d-lg-block pa-0 ",attrs:{"vuelos":_vm.experienciasBlackFlyDay}}),_c('div',{staticClass:"d-flex descubre__slider py-5 d-lg-none"},_vm._l((_vm.experienciasBlackFlyDay),function(experiencia,i){return _c('div',{key:i},[_c('router-link',{attrs:{"to":{
                name: 'Detalle',
                params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
              }}},[_c('CardExperiencias',{staticClass:"mr-md-8",attrs:{"infoExperiencia":experiencia}})],1)],1)}),0),_c('router-link',{staticClass:"btn-experiencias-correccion",attrs:{"to":{ name: 'Experiencias' }}},[_c('Boton',{attrs:{"texto":_vm.lenguages[_vm.idioma].experienciasHome.botonVerMas ||
                'Ver todas las experiencias',"icono":_vm.icons.green.arrowToRight,"borderRadius":"25px","height":"55px"}})],1)],1)],1):_vm._e(),(_vm.promocionXPlus1.length > 0)?_c('section',{staticClass:"descubre"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"descubre__title"},[_vm._v("HUMMING DAY")]),_c('h2',{staticStyle:{"font-size":"26px"}},[_vm._v("¡Vuela en grupo, paga menos!")]),_c('h4',{staticClass:"descubre__subtitle d-none d-sm-block"},[_vm._v(" Vive una experiencia en grupo con un asiento GRATIS ")])])],1),_c('Slider',{staticClass:"d-none d-lg-block pa-0 ",attrs:{"vuelos":_vm.promocionXPlus1}}),_c('div',{staticClass:"d-flex descubre__slider py-5 d-lg-none"},_vm._l((_vm.promocionXPlus1),function(experiencia,i){return _c('div',{key:i},[_c('router-link',{attrs:{"to":{
                name: 'Detalle',
                params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
              }}},[_c('CardExperiencias',{staticClass:"mr-md-8",attrs:{"infoExperiencia":experiencia}})],1)],1)}),0),_c('router-link',{staticClass:"btn-experiencias-correccion",attrs:{"to":{ name: 'Experiencias' }}},[_c('Boton',{attrs:{"texto":_vm.lenguages[_vm.idioma].experienciasHome.botonVerMas ||
                'Ver todas las experiencias',"icono":_vm.icons.green.arrowToRight,"borderRadius":"25px","height":"55px","color":"#002A87"}})],1)],1)],1):_vm._e(),_c('section',{staticClass:"descubre"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"descubre__title"},[_vm._v(" "+_vm._s(_vm.lenguages[_vm.idioma].experienciasHome.privadas.titulo || "Experiencias Premium")+" ")]),_c('h4',{staticClass:"descubre__subtitle d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.lenguages[_vm.idioma].experienciasHome.privadas.subTitulo || "Vive experiencias inolvidables")+" ")])])],1),_c('Slider',{staticClass:"d-none d-lg-block pa-0 ",attrs:{"vuelos":_vm.experienciasPrivadas}}),_c('div',{staticClass:"d-flex descubre__slider py-5 d-lg-none"},_vm._l((_vm.experienciasPrivadas),function(experiencia,i){return _c('div',{key:i},[_c('router-link',{attrs:{"to":{
                name: 'Detalle',
                params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
              }}},[_c('CardExperiencias',{staticClass:"mr-md-8",attrs:{"infoExperiencia":experiencia}})],1)],1)}),0),_c('router-link',{staticClass:"btn-experiencias-correccion",attrs:{"to":{ name: 'Experiencias' }}},[_c('Boton',{attrs:{"texto":_vm.lenguages[_vm.idioma].experienciasHome.botonVerMas ||
                'Ver todas las experiencias',"icono":_vm.icons.green.arrowToRight,"borderRadius":"25px","height":"55px"}})],1)],1)],1),(false)?_c('section',{staticClass:"descubre pt-5 ",staticStyle:{"background":"white"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"descubre__title  colorItau ",staticStyle:{"text-shadow":"1px 1px 1px gray"}},[_vm._v(" "+_vm._s(_vm.lenguages[_vm.idioma].experienciasHome.itau.titulo)+" ")]),_c('h4',{staticClass:"descubre__subtitle d-none d-sm-block "},[_vm._v(" "+_vm._s(_vm.lenguages[_vm.idioma].experienciasHome.itau.subTitulo)+" ")])])],1),_c('Slider',{staticClass:"d-none d-lg-block pa-0 ",attrs:{"vuelos":_vm.experienciasItau}}),_c('div',{staticClass:"d-flex descubre__slider py-5 d-lg-none"},_vm._l((_vm.experienciasItau),function(experiencia,i){return _c('div',{key:i},[_c('router-link',{attrs:{"to":{
                name: 'Detalle',
                params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
              }}},[_c('CardExperiencias',{staticClass:"mr-md-8",attrs:{"infoExperiencia":experiencia}})],1)],1)}),0),_c('router-link',{staticClass:"btn-experiencias-correccion",attrs:{"to":{ name: 'Experiencias' }}},[_c('Boton',{attrs:{"texto":_vm.lenguages[_vm.idioma].experienciasHome.botonVerMas ||
                'Ver todas las experiencias',"icono":_vm.icons.green.arrowToRight,"borderRadius":"25px","height":"55px"}})],1)],1)],1):_vm._e(),(_vm.viajesEmptyLegs.length > 0)?_c('section',{staticClass:"descubre"},[_c('v-container',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"descubre__title"},[_vm._v("¡Ofertas!")]),_c('h4',{staticClass:"descubre__subtitle d-none d-sm-block"},[_vm._v(" Aprovecha los precios de los vuelos empty legs ")])])],1),_c('Slider',{staticClass:"d-none d-lg-block pa-0 ",attrs:{"vuelos":_vm.viajesEmptyLegs}}),_c('div',{staticClass:"d-flex descubre__slider py-5 d-lg-none"},_vm._l((_vm.viajesEmptyLegs),function(experiencia,i){return _c('div',{key:i},[_c('router-link',{attrs:{"to":{
                name: 'Detalle',
                params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
              }}},[_c('CardExperiencias',{staticClass:"mr-md-8",attrs:{"infoExperiencia":experiencia}})],1)],1)}),0),_c('router-link',{staticClass:"btn-experiencias-correccion",attrs:{"to":{ name: 'Experiencias' }}},[_c('Boton',{attrs:{"texto":_vm.lenguages[_vm.idioma].experienciasHome.botonVerMas ||
                'Ver todas las experiencias',"icono":_vm.icons.green.arrowToRight,"borderRadius":"25px","height":"55px"}})],1)],1)],1):_vm._e()]),_c('v-container',{staticClass:"mb-15",attrs:{"id":"Corporativo-promociones"}},[_c('VideoCoorporativo'),_c('CarouselPromociones',{staticClass:"promociones"})],1),_c('Cotizacion'),_c('v-container',[_c('Banner')],1),_c('Contacto'),_c('Footer'),_c('Cookies')],1)}
var staticRenderFns = []

export { render, staticRenderFns }