import nav from "./nav";
import heroSection from "./heroSection";
import pasoAPaso from "./pasoAPaso";
import experienciasHome from "./experienciasHome";
import armaTuExperiencia from "./armaTuExperiencia";
import banner from "./banner";
import contacto from "./contacto";
import footer from "./footer";
import cookies from "./cookies";
import seguridadOperacional from "./seguridadOperacional";
import newsLetter from "./newsLetter";
import faqs from "./FAQS";
import login from "./login";
import miPerfil from "./miPerfil";
import misDatos from "./misDatos";
import buscador from "./buscador";
import cotizacion from "./cotizacion";
import detalle from "./detalle";
import cardReservarVuelo from "./cardReservarVuelo";
import pago from "./pago";

export default {
    nav,
    heroSection,
    pasoAPaso,
    experienciasHome,
    armaTuExperiencia,
    banner,
    contacto,
    footer,
    cookies,
    seguridadOperacional,
    newsLetter,
    faqs,
    login,
    miPerfil,
    misDatos,
    buscador,
    cotizacion,
    detalle,
    cardReservarVuelo,
    pago
}