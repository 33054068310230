const login = {
    iniciaSesion: "Inicia sesión",
    yaTienesCuenta: "¿YA TIENES CUENTA?",
    noTienesCuenta: "¿NO TIENES CUENTA?",
    registrate: 'REGÍSTRATE',
    correoElectronico: 'Correo electrónico',
    contrasena: 'Contraseña',
    iniciarSesion: 'Iniciar Sesión',
    registrate: 'Regístrate',
    continuarConFacebook : "Continuar con Facebook",
    continuarConGoogle : "Continuar con Google",
}
export default login