<template>
  <div class="setOrigen px-6">
    <h2>
      {{ lenguages[idioma].heroSection.buscador.origen || "Origen" }}
    </h2>
    <v-combobox
      class="setOrigen__textField"
      :placeholder="
        lenguages[idioma].heroSection.buscador.desdeDonde ||
          '¿Desde dónde quieres viajar?'
      "
      v-model="origen"
      flat
      solo
      @change="setOrigen"
      :items="origenes"
      :item-color="colors.mainPurple"
      :color="colors.mainPurple"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> No hay coincidencias </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "setOrigen",
  data() {
    return {
      origen: "",
    };
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Buscador", ["busqueda"]),
    ...mapGetters("Buscador", ["origenes"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    setOrigen() {
      this.SET_BUSQUEDA_PARAMS({ origen: this.origen });
    },
  },
  mounted() {
    this.origen = this.busqueda.origen;
  },
};
</script>

<style lang="scss" scoped>
.setOrigen {
  @include inputInside;
  @include flex(column, flex-start, flex-start);
  height: 73px;
  overflow: hidden;
  line-height: 18px;
  padding: 10px;
  input {
    box-shadow: none;
    font-size: 0.9375em;
    padding: 0;
    * {
      background: #f00;
    }
  }
}
</style>
