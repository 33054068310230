const buscador = {
  titulo: "Experiencias",
  subTitulo: "Seleccionamos estas ofertas para ti, con precios sorprendentes.",
  ordenadoPor: "Ordenado por",
  experienciasBlackFlyDay: "Experiencias Black Fly Day",
  experienciasITAU: "Experiencias ITAU",
  destino: "Destino",
  aeronave: "Aeronave",
  precio: "Precio",
  zona: "Zona",
  servicio: "Servicio",
  tiempo: "Tiempo",
  asientos: "Asientos",
  cualquierAeronave: "Cualquier Aeronave",
  cualquierPrecio: "Cualquier Precio",
  loSentimos: "Lo sentimos, no tenemos experiencias acorde a los filtros aplicados"
}


export default buscador