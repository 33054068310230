import firebase from 'firebase'
import moment from "moment";
import axios from "axios";

export default {
    namespaced: true,
    state: {
        experiencias: [],
        monedas: {
            dolar: null
        },
        moneda: "usd"
    },
    mutations: {
        GET_EXPERIENCIAS(state, experiencias) {
            state.experiencias = experiencias
        },
        SET_DOLAR_PRICE(state, dolarPrice) {
            state.monedas.dolar = dolarPrice || 930
        },

        changeMoneda(state, select) {
            const { target: { value } } = select
            state.moneda = value
        }
    },
    actions: {
        async getDolarPrice({ commit, dispatch }) {
            const { data: monedas } = await axios.get("https://api.gael.cloud/general/public/monedas")
            let dolarPrice = monedas.find(m => m.Codigo == "USD").Valor.replace(",", ".")
            if (!dolarPrice || dolarPrice == "ND") {
                dolarPrice = await dispatch("getDolarPrice2")
            }
            commit("SET_DOLAR_PRICE", dolarPrice)
        },
        async getDolarPrice2({ commit }) {
            const { data: { dolar } } = await axios.get("https://mindicador.cl/api")
            const dolarPrice = dolar.valor
            return dolarPrice
        },
        getExperiencias({ commit }) {
            firebase
                .firestore()
                .collection('experiencias')
                .onSnapshot(snapshot => {
                    let experiencias = []
                    snapshot.forEach(experiencia => {

                        experiencias.push({
                            data: experiencia.data(),
                            id: experiencia.id
                        })
                    })
                    // Experiencia Test
                    // experiencias.push(ExperienciaTest)


                    experiencias = experiencias.sort((a, b) =>
                        a.data.precio > b.data.precio ? 1 : -1)


                    commit('GET_EXPERIENCIAS', experiencias)
                })

        },
        async agregarExperiencia({ commit }, experiencia) {
            try {
                await firebase.firestore().collection('experiencias').add(experiencia);
                alert(`Experiencia a ${experiencia.destino} el ${experiencia.fecha} agregada`)
                return true
            } catch (error) {
                alert(error)
                return false
            }
        },
        async deleteExperiencia({ commit }, experienciaID) {
            await firebase.firestore().collection('experiencias').doc(experienciaID).delete();
        },
        async editarExperiencia({ commit }, { experiencia, id }) {
            try {
                experiencia.timestamp = firebase.firestore.FieldValue.serverTimestamp()
                await firebase.firestore().collection('experiencias').doc(id).set(experiencia);
                alert(`Experiencia a ${experiencia.destino} el ${experiencia.fecha} editada con éxito`)
                return true
            } catch (error) {
                alert(error)
                return false
            }
        },
        async copias_Experiencia({ commit }, id) {
            try {
                const timestamp = firebase.firestore.FieldValue.serverTimestamp()
                let experiencia = await firebase.firestore().collection('experiencias').doc(id).get();
                experiencia = experiencia.data()
                experiencia.timestamp = timestamp
                const experienciaClonCreada = await firebase.firestore().collection('experiencias').add(experiencia);
                alert("Se ha creado una nueva experiencia clonada con ID : " + experienciaClonCreada.id)
                return true
            } catch (error) {
                alert(error)
                return false
            }
        }
    },
    getters: {
        getExperienciasAMostrar: (state) => state.experiencias.filter(e => !e.data.oculta),
        promocionXPlus1: (state) => {
            const { experiencias } = state
            const promocionXPlus1 = experiencias.filter(v => v.data.promocionXPlus1)
            return promocionXPlus1
        },
        viajesEmptyLegs: (state) => {
            const { experiencias } = state
            const viajesEmptyLegs = experiencias.filter(v => v.data.emptyLegs)
            return viajesEmptyLegs
        },
        proximasExperiencias: (state) => {
            const ordenarPorFecha = (a, b) => {
                if (a.data.fecha > b.data.fecha) {
                    return 1;
                }
                if (a.data.fecha < b.data.fecha) {
                    return -1;
                }
                return 0;
            };

            let vuelosOrdenadosPorFecha = state.experiencias.sort(ordenarPorFecha);


            let sinVuelosPasados = vuelosOrdenadosPorFecha.filter(v => {
                return moment(`${v.data.fecha} ${v.data.horaDespegue}`).isSameOrAfter(moment().add(2, 'h'))
                    && (v.data.capacidad - v.data.ventas) > 0
            })

            let experienciasConReserva = state.experiencias.filter(v => v.data.reserva)

            return sinVuelosPasados.concat(experienciasConReserva)
        },
        experienciasOrdenadasPorPrecio: (state) => {
            const { experiencias } = state
            return experiencias.sort((a, b) =>
                a.data.precio > b.data.precio ? 1 : -1
            );
        },
        destinosExperienciasYVentas: (state) => {

            let experiencias = state.experiencias || []
            let destinos = [];

            const ordernarPorVentas = (a, b) => {
                if (a.ventas > b.ventas) {
                    return 1;
                }
                if (a.ventas < b.ventas) {
                    return -1;
                }
                return 0;
            };

            experiencias.forEach(experiencia => {
                destinos.push({
                    destino: experiencia.data.destino,
                    ventas: experiencia.data.ventas
                })
            })

            let destinosSinRepeticion = destinos.reduce((acu, act) => {
                const destinoRepetido = acu.find(el => el.destino === act.destino);

                if (destinoRepetido) {
                    return acu.map(el => {
                        if (el.destino === act.destino) {
                            return {
                                ...el,
                                ventas: el.ventas + act.ventas
                            }
                        }
                        return el;
                    })
                }
                return [...acu, act];
            }, [])

            let destinosOrdenadosPorVentas = destinosSinRepeticion.sort(ordernarPorVentas).reverse()

            return destinosOrdenadosPorVentas;
        },
        soloDestinosExperiencias: (state, getters) => {
            const getterDestinosYVentas = getters.destinosExperienciasYVentas

            const destinos = getterDestinosYVentas.map(v => v.destino)
            return destinos
        },
        getExperienciaById: (state) => (id) => {
            const experiencias = state.experiencias
            const selected = experiencias.find((v) => v.id === id);
            return selected;

        },
        getExperienciasByOperador: state => id => {
            debugger
            return state.experiencias.filter(e => e.data.operador.id === id)
        },
        experienciasTipoReserva: (state) => {
            return state.experiencias.filter(e => e.data.reserva)
        },
        experienciasFavoritas: (state, b, State) => {
            const { usuario } = State.Auth
            if (usuario) {
                const { uid } = usuario
                let { experiencias } = state
                experiencias = experiencias.filter((exp) => {
                    const { data: { favoritos } } = exp
                    if (!favoritos) return false
                    return favoritos && favoritos.includes(uid)
                })
                return experiencias
            }
            else {
                return []
            }
        }
    }
}