<template>
  <div id="Contacto" class="mb-10">
    <section class="container">
      <h1 class="descubre__title">
        {{ lenguages[idioma].contacto.titulo || "¿Alguna duda?" }}
      </h1>
      <h4 class="descubre__subtitle d-none d-sm-block">
        {{ lenguages[idioma].contacto.subTitulo || "¡Envíanos un mensaje!" }}
      </h4>
      <hr class="mt-3 mb-5" />

      <div id="form">
        <h3>
          {{
            lenguages[idioma].contacto.nombreYApellido || "Nombre y apellido"
          }}
          :
        </h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          v-model="nombre"
          placeholder="Ejemplo: Pedro Martinez"
        ></v-text-field>
        <h3>{{ lenguages[idioma].contacto.correo || "Correo" }}:</h3>
        <v-text-field
          outlined
          :color="colors.mainPurple"
          placeholder="Ejemplo: pedromartinez@gmail.com"
          v-model="email"
        ></v-text-field>

        <h3>
          {{ lenguages[idioma].contacto.mensaje || "Mensaje" }}
          :
        </h3>
        <v-textarea
          outlined
          :color="colors.mainPurple"
          placeholder="Ingrese aquí su mensaje"
          v-model="mensaje"
        ></v-textarea>

        <div @click="send" class="d-flex">
          <Boton :texto="lenguages[idioma].contacto.botonEnviar" class="btn" />
          <v-progress-circular
            indeterminate
            color="#645dc7"
            class="ml-3"
            v-show="loading"
          ></v-progress-circular>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
#Contacto {
  background: #fcfcfc;
}
.btn {
  width: 15%;
  height: 45px;
}
@media (max-width: 576px) {
  .btn {
    width: 100%;
  }
}
</style>

<script>
import { mapState } from "vuex";
import Boton from "@/components/Botones/Boton";
import axios from "axios";

const expEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export default {
  name: "Contacto",
  data() {
    return {
      nombre: "",
      email: "",
      mensaje: "",
      loading: false,
    };
  },
  methods: {
    async send() {
      try {
        this.loading = true;
        const { nombre, email, mensaje } = this;
        if (!nombre || !email || !mensaje)
          throw { message: "Debe llenar todos los campos" };
        if (!expEmail.test(email))
          throw { message: "El formato del correo electrónico es incorrecto" };
        await axios.post(
          "https://us-central1-hummingbird-453db.cloudfunctions.net/mailing/contacto",
          { nombre, email, mensaje }
        );

        this.$toast.open({
          message: "¡Mensaje enviado!",
          position: "bottom",
          duration: 3000,
        });

        this.nombre = "";
        this.email = "";
        this.mensaje = "";
      } catch (e) {
        console.log(e);
        this.$toast.error(e.message, {
          position: "bottom",
          duration: 3000,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    Boton,
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
};
</script>
