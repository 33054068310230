const faqs = {
    pregunta1: {
        titulo: '¿Cuándo me avisarán la fecha de mi vuelo comprado?',
        parrafos: [
            'Si tu compra corresponde a un ticket de Compartido, te contactaremos cuando tu vuelo se haya completado para coordinar la fecha del vuelo.',
            'Si tu compra corresponde a un ticket grupal de promoción Humming Day, en los próximos días te contactaremos para coordinar la fecha del vuelo.'
        ]
    },


    pregunta4: {
        titulo: '¿En qué fecha se realiza el vuelo que compré?',
        parrafos: [
            'Si tu compra corresponde a un ticket Compartido, te contactaremos cuando tu vuelo se haya completado para coordinar la fecha del vuelo.',
            'Si tu compra corresponde a un ticket grupal de promoción Humming Day, en los días siguientes a tu compra te contactaremos para coordinar la fecha del vuelo.',
            'La fechas de tu vuelo se coordinará en días permitidos de fase sanitaria Transición (Fase 2) o superior.',
            'Realiza tu web check-in 48 horas antes del vuelo, ingresando con tu usuario en hummingbird.cl. Aquí podrás modificar y/o confirmar los datos definitivos de los pasajeros.',
        ]
    },


    pregunta5: {
        titulo: '¿Puedo comprar un ticket como regalo?',
        parrafos: [
            'Si tu compra es un regalo, después de realizada la compra contáctanos al correo o whatsapp y solicita una GiftCard para el beneficiario.',
        ]
    },

    pregunta6: {
        titulo: '¿Por qué me piden tantos datos personales para realizar la compra?',
        parrafos: [
            'En aeronáutica, la seguridad operacional es un elemento impostergable para resguardar la vida de las personas. La autoridad aeronáutica obliga a todo operador de aeronaves a comunicar los datos de los pasajeros en su Plan de Vuelo, documento que se entrega antes de cada vuelo. Adicionalmente, estos datos son un importante resguardo legal para coberturas de seguros de responsabilidad civil a los que están afiliados los operadores.',
        ]
    },


    pregunta7: {
        titulo: '¿Cómo sabré qué empresa y en qué aeronave se realizará mi vuelo?',
        puntos: [
            'Empresa operadora',
            'Modelo de aeronave',
            'Piloto al mando',
            'Dirección y hora de presentación al embarque',
            'Documentación necesaria',
            'Equipaje permitido',
            'Elementos prohibidos a bordo',
        ],
        parrafos: [
            'Una vez realizado tu web check-in, recibirás un correo electrónico con la información de tu vuelo',
            'Podrás realizar tu web check-in 48 horas antes del vuelo, ingresando con tu usuario en hummingbird.cl. Aquí podrás modificar y/o confirmar los datos definitivos de los pasajeros.']
    },


    pregunta8: {
        titulo: '¿Qué experiencia tienen Hummingbird y las empresas que realizan los vuelos?',
        parrafos: [
            'Hummingbird es una empresa conformada por un equipo multidisciplinario de profesionales de la aviación y de la tecnología, tales como Pilotos, Ingenieros en Aviación Comercial, Ingenieros de Sistemas, Desarrolladores Full-Stack y Desarrolladores Front-End. Entre nuestros proveedores externos se encuentran diversas empresas de apoyo en medios, tales como Community Management, Diseño, Publicidad y apoyo en asesoría legal y contable.',
            'Nuestros partners operadores de aeronaves son empresas certificadas por la autoridad aeronáutica. Nos preocupamos de revisar que su documentación legal esté al día y monitorear la renovación de los mismos en los plazos establecidos.',
            'En cada una de las experiencias que ofrece nuestra plataforma, encontrarás la siguiente información respecto al operador:',
        ],
        puntos: [
            'Calificación promedio que han realizado otros usuarios que han volado en la misma experiencia',
            'Cantidad de vuelos o experiencias que ha realizado el operador con Hummingbird',
            'Cantidad de pasajeros que la han comprado el vuelo o experiencia',
        ],
    },


    pregunta9: {
        titulo: '¿Cuáles son las medidas de seguridad operacional?',
        parrafos: [
            'En Hummingbird garantizamos la seguridad del vuelo de nuestros pasajeros, a través de la siguiente política.',
            'Todos nuestros partners operadores de aeronaves cuentan con sus certificaciones vigentes, emitidos por la Dirección General de Aeronáutica Civil. Esto significa que la autoridad aeronáutica los ha revisado y aprobado para realizar operaciones aéreas, garantizando:',
            'En Hummingbird, realizamos seguimiento y control de renovación de certificados en cada operador. Verificamos que las políticas de seguridad de nuestros operadores estén bajo el estándar internacional de Sistema de Gestión de Seguridad Operacional (SMS), el cual garantiza que nuestros operadores cuentan, entre otros elementos, con:',
        ],
        puntos1: [
            'Certificado de Operador Comercial, con autorización para comercializar vuelos, bajo la normativa DAN-135',
            'Aeronavegabilidad de las aeronaves',
            'Mantenimiento de las aeronaves al día, controlado de manera permanente y realizado por Centros de Mantenimiento Aeronáutico habilitados para cada tipo de aeronave.',
            'Pilotos con instrucción recurrente y licencias aeronáutica vigente.',
            'Seguros de Responsabilidad Civil.',
        ],
        puntos2: [
            'Política de Seguridad Operacional',
            'Gestión de riesgos y mejora continua',
            'Plan de respuesta ante Emergencias',
            'Evaluación de la experiencia',
            'Registro seguro de pasajeros',
            'Verificación del tamaño y peso del equipaje',
            'Ausencia de mercancías peligrosas',
            'Evaluación del piloto al mando de la aeronave',
        ],
    },

    pregunta10: {
        titulo: '¿Cuáles son las medidas de seguridad ante el Covid-19?',
        parrafos: [
            'Nuestros operadores cuentan con protocolos de higiene y seguridad para proteger a la tripulación y pasajeros del Covid-19.',
            'Con Hummingbird, evita contagios en aeropuertos concurridos y vuelas desde las oficinas particulares de los operadores en aeródromos pequeños.',
        ],
        puntos: [
            'Medición de la temperatura',
            'Uso de alcohol gel y mascarillas durante toda la experiencia.',
            'Limpieza y desinfección de las aeronaves antes y después de cada vuelo. La limpieza incluye superficies y elementos manipulables, tales como fonos, manillas, elementos de mando y control de la aeronave.',
            'Minimizamos la cantidad de personal y trámites previos a la ejecución del vuelo, promoviendo el distanciamiento social, lavado y/o desinfección de manos',
        ],
    },
}

export default faqs