const detalle = {
  salidaYLlegada: "Salida y llegada",
  llegada: "Llega",
  tiempoDeVuelo: "Tiempo de vuelo",
  tiempoTotal: "Tiempo total de la experiencia",
  minutos: "minutos",
  fechasDisponibles: "Calendario con fechas disponibles",
  a: "a",
  detallesDelVuelo: "Detalles del vuelo",
  coordinacionDeTuVueloPrivado: "Coordinación de tu vuelo privado",
  coordinacionDeTuVueloCompartido: "Coordinación de tu vuelo compartido",

  coordinacionesExperienciaPrivada:

    [
      'Precio por la aeronave para ti o tu grupo cerrado.',

      'Agenda inmediata disponible, con 48 horas hábiles de anticipación.',

      'Reservas mediante transferencia electrónica o flow/webpay.'],


  coordinacionesExperienciaCompartida: [
    'Precio por persona.',

    'Escoge una de las experiencias compartidas.',

    'Escoge una de las fechas disponibles.',

    'Reserva tu cupo mediante transferencia electrónica o flow/webpay.',

    'Disfruta de una expericia inolvidable.'
  ],


  viajesInterregionalesTitulo: "Viajes interregionales",
  viajesInterregionales: [
    "Pasajeros sin Pase de Movilidad, deben demostrar traslado por tratamiento médico, funeral, retorno a residencia habitual o Permiso Único Colectivo de Comisaría Virtual."
  ],

  medidasDeSeguridadTitulo: "Medidas de Seguridad",
  medidasDeSeguridad: [
    "Todas nuestras aeronaves cuentan con sus certificados vigentes, aprobados por la autoridad aeronáutica de Chile.",
    "Todos nuestros operadores cumplen con nuestro Protocolo Sanitario de protección ante el Covid-19. Conoce más acerca de nuestra sección de Seguridad Operacional.",
    "Para todas nuestras experiencias, puedes consultar por opción de vuelo Privado y tener la aeronave sólo para ti."
  ],

}


export default detalle