<template>
  <v-container class="pt-3">
    <v-carousel height="435px" delimiter-icon="mdi-minus" show-arrows-on-hover hide-delimiters>
      <v-carousel-item
        v-for="(experiencias, i) in vuelosSlider"
        :key="i"
        class="slider-marco"
      >
        <v-row>
          <v-col cols="3" v-for="(experiencia, o) in experiencias" :key="o">
            <router-link
              :to="{
                name: 'Detalle',
                params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
              }"
            >
              <CardExperiencias :infoExperiencia="experiencia" class="mx-0" />
            </router-link>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<style scoped>
.slider-marco {
  transition: padding 500ms;
  margin: auto;
}

.slider-marco:hover {
  padding: 0px 60px;
}

.theme--dark.v-sheet {
  background: transparent;
  color: #000000de;
}

.mdi-chevron-right {
  opacity: 0;
}
</style>

<script>
import CardExperiencias from "@/components/Cards/CardExperiencias";
import Helpers from "@/helpers/utils.js";
const { orderByPrice } = Helpers;
export default {
  name: "Slider",
  components: {
    CardExperiencias,
  },
  methods: {
    orderByPrice,
  },
  props: ["vuelos"],
  computed: {
    vuelosSlider() {
      const { vuelos } = this;

      const { length } = vuelos;
      this.orderByPrice(vuelos);
      const cantBySlide = Math.ceil(length / 4);
      const vuelosOrderBySlider = [];
      for (let i = 1; i <= cantBySlide; i++) {
        let start = i * 4 - 4;
        let end = i * 4;
        const slide = vuelos.slice(start, end);
        vuelosOrderBySlider.push(slide);
      }
      return vuelosOrderBySlider;
    },
  },
};
</script>
