const seguridadOperacional = {
    titulo1: 'POLÍTICA DE SEGURIDAD OPERACIONAL',
    descripcion1: `En Hummingbird garantizamos la seguridad del vuelo de nuestros pasajeros, a través de la siguiente política.
        <br>
        Todos nuestros partners operadores de aeronaves cuentan con sus certificaciones vigentes, emitidos por la Dirección General de Aeronáutica Civil. Esto significa que la autoridad aeronáutica los ha revisado y aprobado para realizar operaciones aéreas, garantizando:`,
    puntos1_1: [
        "Certificado de Operador Comercial, con autorización para comercializar vuelos, bajo la normativa DAN-135.",
        "Aeronavegabilidad de las aeronaves",
        "Mantenimiento de las aeronaves al día, controlado de manera permanente y realizado por Centros de Mantenimiento Aeronáutico habilitados para cada tipo de aeronave.",
        "Pilotos con instrucción recurrente y licencias aeronáutica vigente.",
        "Seguros de Responsabilidad Civil."
    ],
    descripcion2: `En Hummingbird, realizamos seguimiento y control de renovación de certificados en cada operador. Verificamos que las políticas de seguridad de nuestros operadores estén bajo el estándar internacional de Sistema de Gestión de Seguridad Operacional (SMS), el cual garantiza que nuestros operadores cuentan, entre otros elementos, con:`,
    puntos1_2: [
        "Política de Seguridad Operacional",
        "Gestión de riesgos y mejora continua",
        "Plan de respuesta ante Emergencias",
        "Evaluación de la experiencia",
        "Registro seguro de pasajeros",
        "Verificación del tamaño y peso del equipaje",
        "Ausencia de mercancías peligrosas",
        "Evaluación del piloto al mando de la aeronave"
    ],
    titulo2: "PROTOCOLO SANITARIO COVID-19",
    descripcion2_1: "Nuestros operadores cuentan con protocolos de higiene y seguridad para proteger a la tripulación y pasajeros del Covid-19.",
    puntos2: [
        "Medición de la temperatura",
        "Uso de alcohol gel y mascarillas durante toda la experiencia.",
        "Limpieza y desinfección de las aeronaves antes y después de cada vuelo. La limpieza incluye superficies y elementos manipulables, tales como fonos, manillas, elementos de mando y control de la aeronave.",
        "Minimizamos la cantidad de personal y trámites previos a la ejecución del vuelo, promoviendo el distanciamiento social, lavado y/o desinfección de manos."
    ],
    descripcion2_2: `Con Hummingbird, evita contagios en aeropuertos concurridos y vuelas desde las oficinas particulares de los operadores en aeródromos pequeños.`,
}


export default seguridadOperacional