const cardReservarVuelo = {
    en: "With",
    origen: "Departure",
    avion: "Airplane",
    helicoptero: "Helicopter",
    destino: "Experience",
    fechaDeVuelo: "Flight date",
    pasajero: "passenger",
    pasajeros: "passengers",
    precioPorPasajero: "Price per Passenger",
    precioTotal: "Total Price",
    horaDeLlegada: "Check In",
    capacidad: "Capacity",
    noHayAsientosDisponibles: "no seats available",
    ahorro: "Saving",
    precioFinal: "Final price",
    precioNormal: "Normal price",
    reservar: "Reserve",
    preventa: "Shared",
    privado: "Private",
    horaDeAbordaje: "Boarding time",
    codigoDeVuelo: "Flight code",
    aeronave: "Aircraft",
    experiencia: "Experience",
    personasCompraron: "people bought this flight"
}

export default cardReservarVuelo