const pasoAPaso = {
    titulo: "Vuelos compartidos en helicópteros y aviones livianos",
    subTitulo: "¿Cómo volar?",
    pasos: {
        paso1: "Selecciona tu experiencia",
        paso2: "Escoge una de las fechas disponibles",
        paso3: "Reserva y paga sólo el valor de tu cupoo",
        paso4: "Disfruta de una experiencia inolvidable"
    },
    leyenda: "Encuentra mayor información en la descripción de cada experiencia"
}


export default pasoAPaso