const miPerfil = {
    editarMisDatos: "Edit my data", 
    cerrarSesion: "Log out",
    misProximasExperiencias: "My next experiences",
    visualiza: "View everything you need from your flights",
    noTienesExperienciasProximas: "You have no upcoming experiences",
    verTodasLasExperiencias: "See all experiences",
    tienesEnMente: "Do you have your next flight in mind?",
    teRecomendamos: "We recommend these experiences",
    misViajesAnteriores: "My previous experiences",
    misExperienciasFavoritas: "Favorite Experiences",
}

export default miPerfil