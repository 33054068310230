import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import firebase from 'firebase'
import * as VueGoogleMaps from 'vue2-google-maps'
import titleMixin from './mixins/titleMixin'
Vue.mixin(titleMixin)
import VueToast from 'vue-toast-notification';
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBv0-LH8G0xXnnVLXf-9L_GJcCTEyf31GM',
    libraries: 'places'
  }
});

var firebaseConfig = {
  apiKey: "AIzaSyBv0-LH8G0xXnnVLXf-9L_GJcCTEyf31GM",
  authDomain: "hummingbird-453db.firebaseapp.com",
  projectId: "hummingbird-453db",
  storageBucket: "hummingbird-453db.appspot.com",
  messagingSenderId: "263339276711",
  appId: "1:263339276711:web:405aad7a418470339a1e82",
  measurementId: "G-SYNNBNYH3C"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
Vue.config.productionTip = false
Vue.config.silent = true;
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
