<template>
  <div id="Cookies" v-if="show">
    <v-alert class="mb-0 coockie">
      <v-row>
        <v-col class="text-center d-flex justify-center " cols="12" sm="2">
          <v-icon align="center" size="80px">
            mdi-cookie
          </v-icon>
        </v-col>

        <v-col class="pa-0">
          <v-col cols="12" sm="10" class="px-1 text-center text-sm-left">
            {{ lenguages[idioma].cookies.descripcion }}
          </v-col>
          <v-col cols="12">
            <div class="row ">
              <a
                class="col-12 col-sm-auto pt-0 "
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Pol%C3%ADtica%20de%20Privacidad%20%26%20Cookies.docx.pdf?alt=media&token=0ed441cb-9aec-4e1e-a432-9b03f9998e70"
              >
                <Boton
                  :texto="
                    lenguages[idioma].cookies.verPoliticasBoton ||
                      'Ver Politicas de Privacidad'
                  "
                  height="47px"
                  class="mb-3 mr-sm-4 mx-auto "
                  color="white"
                  colorTexto="black"
              /></a>

              <span @click="aceptarCookies" class="col-12 col-sm-auto  pt-0 ">
                <Boton
                  :texto="lenguages[idioma].cookies.aceptar || 'Aceptar'"
                  height="47px"
                  class="mx-auto"
                />
              </span>
            </div>
          </v-col>
        </v-col>
      </v-row>

      <v-row class="botones"> </v-row>
    </v-alert>
  </div>
</template>

<script>
import Boton from "@/components/Botones/Boton";
import { mapState } from "vuex";

export default {
  name: "Cookies",
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    const coockies_aceptadas = localStorage.getItem("coockies_aceptadas");
    if (coockies_aceptadas == "true") this.show = false;
  },
  methods: {
    aceptarCookies() {
      this.show = false;
      localStorage.setItem("coockies_aceptadas", "true");
    },
  },
  components: {
    Boton,
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
};
</script>

<style scoped>
#Cookies {
  width: 50%;
  position: fixed;
  z-index: 10 !important;

  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0px 0px 5px 1px black;
  animation: aparecer;
  animation-iteration-count: 1;

  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

@media (max-width: 600px) {
  #Cookies {
    bottom: 100px;
    width: 90%;
  }
}

@keyframes aparecer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.coockie {
  background: #f5f5f5ef;
}
</style>
