var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"cardExperiencia rounded-xl",staticStyle:{"position":"relative","background":"white"}},[_c('v-carousel',{attrs:{"show-arrows":false,"hide-delimiters":"","height":"185"}},_vm._l(([_vm.infoExperiencia.data.img[0]]),function(img,i){return _c('v-carousel-item',{key:i,staticClass:"cardExperiencia__img",attrs:{"src":img,"reverse-transition":"fade-transition","transition":"fade-transition"}},[(false)?_c('div',{staticClass:"cardExperiencia__status pa-1 text-center"},[_c('h3',{style:(_vm.colorStatus)},[_vm._v(_vm._s(_vm.infoExperiencia.data.status))])]):_vm._e(),_c('h2',[_vm._v(" "+_vm._s(_vm.idioma == "es" ? _vm.infoExperiencia.data.destino == "" || !!_vm.infoExperiencia.data.destino == false ? _vm.infoExperiencia.data.destinoEN : _vm.infoExperiencia.data.destino : _vm.infoExperiencia.data.destino)+" ")])])}),1),_c('div',{staticClass:"corazon",staticStyle:{"position":"absolute","top":"10px","right":"10px"},on:{"click":_vm.setFavorite}},[(_vm.experienciaFavorita || _vm.favorita)?_c('v-icon',{attrs:{"large":"","color":"deep-purple accent-4"}},[_vm._v(" mdi-cards-heart ")]):_c('v-icon',{attrs:{"large":"","color":"white accent-4"}},[_vm._v(" mdi-cards-heart-outline ")])],1),_c('div',{staticClass:"cardExperiencia__info"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[(_vm.infoExperiencia.data.aeronave)?_c('v-card-title',{staticClass:"cardExperiencia__info__chip"},[_c('img',{attrs:{"src":_vm.infoExperiencia.data.aeronave === 'avion'
              ? _vm.icons.white.plane
              : _vm.icons.white.helicopter.straight,"alt":""}})]):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-center mr-2 ml-0",class:{
          cardExperiencia__info__chip__privado: _vm.infoExperiencia.data.privado,
          cardExperiencia__info__chip__reserva: !_vm.infoExperiencia.data.privado,
        },staticStyle:{"font-size":"13px"}},[(_vm.infoExperiencia.data.privado)?_c('span',[_vm._v(_vm._s(_vm.lenguages[_vm.idioma].cardReservarVuelo.privado))]):_c('span',[_vm._v(_vm._s(_vm.lenguages[_vm.idioma].cardReservarVuelo.preventa))])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        !!_vm.infoExperiencia.data.tiempoDeVuelo &&
          !!_vm.infoExperiencia.data.capacidad
      ),expression:"\n        !!infoExperiencia.data.tiempoDeVuelo &&\n          !!infoExperiencia.data.capacidad\n      "}],staticClass:"d-flex align-center justify-space-between pt-2"},[_c('v-card-title',{staticClass:"py-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.infoExperiencia.data.tiempoDeVuelo)+" min ")]),_c('div',{staticClass:"d-flex align-center justify-center mr-3 font-weight-bold "},[_vm._v(" "+_vm._s(_vm.infoExperiencia.data.capacidad)+" "),_c('v-icon',{attrs:{"align":"center","color":"black"}},[_vm._v(" mdi-seat-passenger ")])],1)],1),_c('v-card-title',{staticClass:"cardExperiencia__title"},[_c('div',{staticStyle:{"margin":"auto"}},[_c('h1',{staticClass:"mt-4",staticStyle:{"height":"27px","text-align":"center"}},[_vm._v(" $"+_vm._s(new Intl.NumberFormat("de-DE").format(_vm.precioEnDolares))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.moneda === 'usd'),expression:"moneda === 'usd'"}],staticStyle:{"font-size":"12px"}},[_vm._v("USD")])]),_c('h6',{staticStyle:{"color":"black","font-size":"12px","text-align":"center"}},[_vm._v(" $"+_vm._s(_vm.precioEnMiles(_vm.precioEnCLPBasadoEnDolarDeHoy))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.moneda === 'usd'),expression:"moneda === 'usd'"}],staticStyle:{"font-size":"12px"}},[_vm._v("CLP")])]),_c('h5',{staticStyle:{"margin-top":"-2px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.infoExperiencia.data.privado ? _vm.lenguages[_vm.idioma].cardReservarVuelo.precioTotal : _vm.lenguages[_vm.idioma].cardReservarVuelo.precioPorPasajero)+" ")])])]),(false)?_c('v-card-actions',{staticClass:"cardExperiencia__sales"},[_c('v-rating',{staticClass:"cardExperiencia__sales__rating",attrs:{"background-color":"orange lighten-3","color":"orange","size":"15","readonly":"","half-increments":"","value":_vm.ratingValue}}),_c('h5',[_c('span',[_vm._v(_vm._s(_vm.infoExperiencia.data.ventas)+" personas")]),_vm._v(" compraron esta experiencia ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }