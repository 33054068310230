const cotizacion = {
  titulo: "¡Tell us what you are looking for!",
  email: "Email",
  destinoOExperiencia: "Destination or experience",
  tipoDeAeronave: "Aircraft type",
  helicoptero: "Helicopter",
  avion: "Airplane",
  cantidadDePasajeros: "Number of passengers",
  fechaAproximada: "What approximate date do you want to fly?",
  algoMas: "Anything else you want to tell us?",
  enviar: "send"
}


export default cotizacion