const banner = {
    titulo: "Vuela seguro con Hummingbird",
    punto1: "Tu alternativa de transporte seguro ante las restricciones sanitarias por COVID-19",
    punto2: `Nuestros operadores cuentan con sus certificados aeronáuticos
    vigentes y cumplen nuestro
    <router-link to="/seguridad-operacional"
      >Protocolo Sanitario
    </router-link>`,
    punto3: `En comunas en fase 1, los motivos de traslados son: tratamiento
    médico, funeral o Permiso Único Colectivo de
    <a href="https://comisariavirtual.cl/">Comisaría Virtual</a>`

}


export default banner