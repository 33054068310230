const banner = {
  titulo: "Fly safe with Hummingbird",
  punto1: "Your safe transport alternative in the face of health restrictions due to COVID-19",
  punto2: `Our operators have their aeronautical certificates
    current and comply with our
    <router-link to = "/ safety-operational"
      > Health Protocol
    </router-link>`,
  punto3: `In communes in phase 1, the reasons for transfers are: treatment
    medical, funeral or Unique Collective Permit of
    <a href="https://comisariavirtual.cl/"> Virtual Police Station </a>`

}


export default banner