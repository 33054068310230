const buscador = {
  titulo: "Experiences",
  subTitulo: "We select these offers for you, with surprising prices.",
  ordenadoPor: "Sorted by",
  experienciasBlackFlyDay: "Experiences Black Fly Day",
  experienciasITAU: "ITAU Experiences",
  destino: "Destiny",
  aeronave: "Aircraft",
  precio: "Price",
  zona: "Zone",
  servicio: "Service",
  tiempo: "Time",
  asientos: "Seats",
  cualquierAeronave: "Any Aircraft",
  cualquierPrecio: "Any Price",
  loSentimos: "Sorry, we have no experiences according to the applied filters"
}


export default buscador