import es from "./es/index.js"
import en from "./en/index.js"

export default {
    namespaced: true,
    state: {
        lenguages: {
            es,
            en
        },
        idioma: 'es'
    },
    mutations: {
        CHANGE_LENGUAGE(state, idioma) {
            state.idioma = idioma
        }
    },
    actions: {
        changeLenguage({ commit }, select) {
            const { target: { value: idioma } } = select
            commit("CHANGE_LENGUAGE", idioma)
            if (idioma == "en") commit("Experiencias/changeMoneda", { target: { value: "usd" } }, { root: true })
        }
    },
    getters: {
    }
}