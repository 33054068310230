<template>
  <div>
    <v-row class="py-3">
      <v-col cols="12" sm="7" md="8" class="pl-0">
        <div class="texto">
          <div>
            <h2 class="title-pasoapaso">
              <!-- {{
                lenguages[idioma].pasoAPaso.titulo ||
                  "Vuelos compartidos en helicópteros y aviones livianos"
              }} -->
              {{ lenguages[idioma].pasoAPaso.subTitulo || "¿Cómo volar?" }}
            </h2>
            <!-- <h2 class="subtitle-pasoapaso">
              {{ lenguages[idioma].pasoAPaso.subTitulo || "¿Cómo volar?" }}
            </h2> -->
          </div>
          <h3>
            <v-icon>mdi-cursor-default-click</v-icon>
            {{
              lenguages[idioma].pasoAPaso.pasos.paso1 || "Escoge tu experiencia"
            }}
          </h3>
          <h3>
            <v-icon>mdi-calendar</v-icon>

            {{ lenguages[idioma].pasoAPaso.pasos.paso2 || "Compra tu asiento" }}
          </h3>
          <h3>
            <v-icon>mdi-seat-recline-normal</v-icon>

            {{
              lenguages[idioma].pasoAPaso.pasos.paso3 ||
                "Espera la notificación de que tu vuelo está completo"
            }}
          </h3>
          <h3>
            <v-icon>mdi-airplane</v-icon>

            {{
              lenguages[idioma].pasoAPaso.pasos.paso4 ||
                "Disfruta de una experiencia inolvidable"
            }}
          </h3>
          <h6>
            {{
              lenguages[idioma].pasoAPaso.leyenda ||
                "Encuentra mayor información en la descripción de cada experiencia"
            }}
          </h6>
        </div>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <video controls style="width:100%" autoplay loop>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/privadovscompartido.mp4?alt=media&token=2093e4b9-6410-4cc9-ad48-dbc8c1d6d341"
          />
        </video>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Paso a Paso",
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
};
</script>

<style lang="scss" scoped>
.title-pasoapaso {
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 23px;
  }
}

.subtitle-pasoapaso {
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 16px;
    margin-top: 10px;
  }
}

h6 {
  @media (min-width: 600px) {
    font-style: italic;
  }
  text-align: left;
}
.texto {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
  h2,
  h6,
  div {
    width: 100%;
    padding-left: 10px;
  }
  i {
    color: #645dc7;
  }
  h2 {
    padding-left: 0px;
  }

  h3 {
    padding-left: 20px;
  }
}

.imagen {
  background: url("https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2FimagenesDestinos%2FimagenesDestinosComprimidas%2FVolando%202%20pilotos%20inclinados_1200x1200.jpg?alt=media&token=054ef91d-e2d3-4dcf-a75a-48a2bae473b3");
  background-size: cover;
  border-radius: 10px;
}
</style>
