const pago = {
    metodosDePagoYDatosDelPasajero: "Método de pago y datos de los pasajeros",
    datosDeLosPasajeros: "Datos de los pasajeros",
    nombreCompleto: "Nombre completo",
    tipoYNumeroDeDocumento: "Tipo y número de documento",
    paisDeOrigen: "País de origen",
    correoElectronico: "Email",
    numeroMovil: "Teléfono",
    direccion: "Dirección",
    peso: "Peso",
    siguiente: "Siguiente",
    continuar: "Continuar",
    comoVasAPagar: "¿Cómo vas a pagar?",
    fechaDeReserva: "Seleccione la fecha  de su reserva",
    declaro: "Declaro que los datos ingresados son correctos",
    terminos: "He leído y acepto los Términos y condiciones de HummingBird",
    pagar: "Pagar",
    paraFinalizar: "Para finalizar tu reserva debes iniciar sesión",
    iniciarConFB: "Iniciar con Facebook",
    iniciarConGoogle: "Iniciar con Google",
    actualizar: "Actualizar mis datos de usaurio",
    enviarCorreo: "Enviar correo electrónico a todos los pasajeros",
    codigoPromocional: "Ingresar código promocional",
    aceptar: "Aceptar",
    promocionAplicada: "Promoción aplicada con éxito!",
    codigoIncorrecto: "Codigo incorrecto",
    detallesDelVuelo: "Detalles de Vuelo",
    itauExperiencia: "Esta es una experiencia exclusiva para clientes del Banco ITAU, debe ingresar el código promocional para continuar"
}

export default pago