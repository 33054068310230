const cardReservarVuelo = {
    en: "En",
    origen: "Origen",
    avion: "Avión",
    helicoptero: "Helicóptero",
    destino: "Experiencia",
    fechaDeVuelo: "Fecha de vuelo",
    pasajero: "pasajero",
    pasajeros: "pasajeros",
    precioPorPasajero: "Precio por Pasajero",
    precioTotal: "Precio Total",
    horaDeLlegada: "Hora de llegada",
    capacidad: "Capacidad",
    noHayAsientosDisponibles: "No hay asientos disponibles",
    ahorro: "Ahorro",
    precioFinal: "Precio final",
    precioNormal: "Precio normal",
    reservar: "Reservar",
    preventa: "Compartido",
    privado: "Privado",
    horaDeAbordaje: "Hora de abordaje",
    codigoDeVuelo: "Código de vuelo",
    aeronave: "Aeronave",
    experiencia: "Experiencia",
    personasCompraron: "personas compraron este vuelo"
}

export default cardReservarVuelo