<template>
  <header id="Navbar" class="navbar">
    <div
      class="mx-auto d-flex align-center justify-space-between px-4 px-md-15 "
    >
      <router-link to="/"
        ><img
          :src="logos.hummingbird.mainSinBorde"
          height="75"
          class="hummingBirdLogo"
        />
      </router-link>
      <div class="ml-auto d-flex align-center text-center">
        <!-- <routerLink class="ml-5 d-none d-md-block" to="/vuelos">{{
          lenguages[idioma].nav.vuelos || "Vuelos"
        }}</routerLink> -->
        <routerLink class="ml-5 d-none d-md-block" to="/experiencias">
          {{ lenguages[idioma].nav.experiencias || "Experiencias" }}
        </routerLink>
        <routerLink class="ml-5 d-none d-md-block" :to="{ name: 'Seguridad' }">
          {{
            lenguages[idioma].nav.seguridadOperacional ||
              "Seguridad operacional"
          }}
        </routerLink>

        <!-- <routerLink
          class="ml-5 d-none d-md-block"
          :to="{ name: 'Operadores-Form' }"
          >¿Eres operador?</routerLink
        > -->

        <a
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfMhU8XzvYPlp-hZgymz4ABuLg63ohC9NHLeBh-juBA6P4H9A/viewform?gxids=7628&pli=1"
          class="ml-5 d-none d-md-block"
        >
          {{ lenguages[idioma].nav.eresOperador || "¿Eres operador?" }}
        </a>
        <!-- <routerLink class="ml-5 d-none d-md-block" to="/"
          >Sobre Humming</routerLink
        > -->

        <!-- <routerLink
          class="ml-5 mr-2 mr-lg-0 d-none d-md-block"
          :to="{ name: 'Noticias' }"
        >
          {{ lenguages[idioma].nav.noticias || "Noticias" }}
        </routerLink> -->
        <routerLink
          class="ml-5 mr-2 mr-lg-0 d-none d-md-block"
          :to="{ name: 'Aerodromos' }"
        >
          {{ lenguages[idioma].nav.aerodromos || "Aeródromos" }}
        </routerLink>
        <routerLink
          class="ml-5 mr-2 mr-lg-0 d-none d-md-block"
          :to="{ name: 'FAQ' }"
        >
          {{ lenguages[idioma].nav.faq || "Preguntas Frecuentes" }}
        </routerLink>

        <div class="ml-lg-16 ">
          <!-- <v-list-item-icon> </v-list-item-icon> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <select
                v-bind="attrs"
                v-on="on"
                style="color: white; -webkit-appearance: menulist"
                class="mr-3"
                @change="changeLenguage"
              >
                <option value="es" style="color: black;">ES</option>
                <option value="en" style="color: black">EN</option>
              </select>
            </template>
            <span>{{
              lenguages[idioma].nav.seleccioneElIdioma ||
                "Seleccione el idiomas"
            }}</span>
          </v-tooltip>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <select
                v-bind="attrs"
                v-on="on"
                style="color: white; -webkit-appearance: menulist"
                @change="changeMoneda"
                v-model="moneda"
                class="mr-3"
              >
                <option value="usd" style="color: black">USD</option>
                <option value="clp" style="color: black;">CLP</option>
              </select>
            </template>
            <span>{{
              lenguages[idioma].nav.seleccioneLaMoneda || "Seleccione la moneda"
            }}</span>
          </v-tooltip> -->
        </div>

        <LoginButton
          class="ml-lg-16"
          @toggleMobileNavbar="toggleMobileNavbar"
        />
      </div>

      <!-- Menu Mobile -->
      <NavbarMobile :drawer="drawer" v-if="windowsWidth < 480" />
    </div>
  </header>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import LoginButton from "@/components/Botones/LoginButton";
import NavbarMobile from "@/components/NavbarMobile.vue";
export default {
  name: "Navbar",
  components: {
    LoginButton,
    NavbarMobile,
  },
  data() {
    return {
      drawer: false,
    };
  },

  methods: {
    toggleMobileNavbar() {
      this.drawer = this.drawer ? false : true;
    },
    ...mapActions("Lenguages", ["changeLenguage"]),
    ...mapMutations("Experiencias", ["changeMoneda"]),
  },
  computed: {
    ...mapState(["logos"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState("Experiencias", ["moneda"]),
    windowsWidth() {
      return window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
#Navbar,
.navbar {
  height: 100px;
  width: 100vw;
  background-color: $main-purple;
  position: fixed;
  z-index: 15;
  color: $main-white;
  animation-name: traslateFromTop;
  animation-duration: 1s;
  animation-timing-function: ease;
  a {
    color: inherit;
    font-size: 18px;
    font-weight: 300;
  }

  > div {
    height: 100%;
  }
}
</style>
