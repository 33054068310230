<template>
  <v-card
    class="cardExperiencia rounded-xl"
    style="position: relative; background: white;"
  >
    <v-carousel :show-arrows="false" hide-delimiters height="185">
      <v-carousel-item
        v-for="(img, i) in [infoExperiencia.data.img[0]]"
        :key="i"
        :src="img"
        reverse-transition="fade-transition"
        transition="fade-transition"
        class="cardExperiencia__img"
      >
        <div v-if="false" class="cardExperiencia__status pa-1 text-center">
          <h3 :style="colorStatus">{{ infoExperiencia.data.status }}</h3>
        </div>
        <h2>
          {{
            idioma == "es"
              ? infoExperiencia.data.destino == "" ||
                !!infoExperiencia.data.destino == false
                ? infoExperiencia.data.destinoEN
                : infoExperiencia.data.destino
              : infoExperiencia.data.destino
          }}
        </h2>
      </v-carousel-item>
    </v-carousel>

    <div
      @click="setFavorite"
      class="corazon"
      style="position: absolute; top: 10px; right: 10px"
    >
      <v-icon
        large
        color="deep-purple accent-4"
        v-if="experienciaFavorita || favorita"
      >
        mdi-cards-heart
      </v-icon>
      <v-icon large color="white accent-4" v-else>
        mdi-cards-heart-outline
      </v-icon>
    </div>

    <div class="cardExperiencia__info">
      <div class="d-flex align-center justify-space-between">
        <v-card-title
          class="cardExperiencia__info__chip"
          v-if="infoExperiencia.data.aeronave"
        >
          <img
            :src="
              infoExperiencia.data.aeronave === 'avion'
                ? icons.white.plane
                : icons.white.helicopter.straight
            "
            alt=""
          />
          <!-- <h5>
            {{
              infoExperiencia.data.aeronave === "avion"
                ? lenguages[idioma].cardReservarVuelo.avion
                : lenguages[idioma].cardReservarVuelo.helicoptero
            }}
          </h5> -->
        </v-card-title>

        <div
          class="d-flex align-center justify-center mr-2 ml-0"
          :class="{
            cardExperiencia__info__chip__privado: infoExperiencia.data.privado,
            cardExperiencia__info__chip__reserva: !infoExperiencia.data.privado,
          }"
          style="font-size: 13px;"
        >
          <span v-if="infoExperiencia.data.privado">{{
            lenguages[idioma].cardReservarVuelo.privado
          }}</span>
          <span v-else>{{ lenguages[idioma].cardReservarVuelo.preventa }}</span>
        </div>
      </div>
      <div
        v-show="
          !!infoExperiencia.data.tiempoDeVuelo &&
            !!infoExperiencia.data.capacidad
        "
        class="d-flex align-center justify-space-between pt-2"
      >
        <v-card-title class="py-0 font-weight-bold">
          {{ infoExperiencia.data.tiempoDeVuelo }} min
        </v-card-title>

        <div class="d-flex align-center justify-center mr-3 font-weight-bold ">
          {{ infoExperiencia.data.capacidad }}
          <v-icon align="center" color="black">
            mdi-seat-passenger
          </v-icon>
        </div>
      </div>

      <v-card-title class="cardExperiencia__title">
        <div style="margin:auto">
          <h1 style="height: 27px;text-align:center" class="mt-4">
            ${{ new Intl.NumberFormat("de-DE").format(precioEnDolares) }}
            <span v-show="moneda === 'usd'" style="font-size:12px">USD</span>
          </h1>
          <h6 style="color: black; font-size: 12px; text-align: center">
            ${{ precioEnMiles(precioEnCLPBasadoEnDolarDeHoy) }}
            <span v-show="moneda === 'usd'" style="font-size:12px">CLP</span>
          </h6>
          <h5 style="margin-top:5px; text-align:center; margin-top: -2px">
            {{
              infoExperiencia.data.privado
                ? lenguages[idioma].cardReservarVuelo.precioTotal
                : lenguages[idioma].cardReservarVuelo.precioPorPasajero
            }}
          </h5>
        </div>
      </v-card-title>
      <!-- <v-card-text class="cardExperiencia__text">
        <p>
          {{
            idioma == "es"
              ? infoExperiencia.data.descripcion
              : infoExperiencia.data.descripcionEN
          }}
        </p>
      </v-card-text> -->
      <v-card-actions class="cardExperiencia__sales" v-if="false">
        <v-rating
          background-color="orange lighten-3"
          color="orange"
          size="15"
          readonly
          half-increments
          class="cardExperiencia__sales__rating"
          :value="ratingValue"
        ></v-rating>
        <h5>
          <span>{{ infoExperiencia.data.ventas }} personas</span> compraron esta
          experiencia
        </h5>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import firebase from "firebase";

export default {
  name: "CardExperiencias",
  props: ["infoExperiencia"],
  data() {
    return {
      favorita: false,
    };
  },
  methods: {
    async setFavorite(e) {
      e.preventDefault();
      let {
        infoExperiencia: {
          data: { favoritos },
          id,
        },
        usuario,
        experienciaFavorita,
        favorita,
      } = this;
      if (experienciaFavorita || favorita) {
        this.favorita = false;
        if (usuario) {
          const { uid } = usuario;
          favoritos = favoritos.filter((ids) => ids !== uid);
          firebase
            .firestore()
            .collection("experiencias")
            .doc(id)
            .update({ favoritos });
          return true;
        }
      } else {
        this.favorita = true;
        if (usuario) {
          const { uid } = usuario;
          if (!favoritos) favoritos = [];
          favoritos.push(uid);
          firebase
            .firestore()
            .collection("experiencias")
            .doc(id)
            .update({ favoritos });
        } else {
          favoritos.push("anonimo");
          firebase
            .firestore()
            .collection("experiencias")
            .doc(id)
            .update({ favoritos });
        }
      }
    },
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState("Experiencias", ["moneda", "monedas"]),
    ...mapGetters("Operadores", ["getRatingByOperadorId"]),
    ...mapState(["icons", "colors"]),
    ...mapState("Auth", ["usuario"]),

    ratingValue() {
      return this.infoExperiencia?.data?.operador?.id
        ? Math.round(
            getRatingByOperadorId(infoExperiencia.data.operador.id) * 10
          ) / 10
        : Math.round(infoExperiencia.data.rating * 10) / 10;
    },

    precioEnDolares() {
      const {
        monedas: { dolar },
        infoExperiencia: {
          data: { precio, precioEnDolares, privado, capacidad },
        },
      } = this;
      if (precioEnDolares) {
        if (privado) return Math.ceil(+precioEnDolares) * capacidad;
        else return Math.ceil(+precioEnDolares);
      }
      const precioEnDolar = precio / dolar;

      return privado
        ? Math.ceil(precioEnDolar) * capacidad
        : Math.ceil(precioEnDolar);
    },
    precioEnCLPBasadoEnDolarDeHoy() {
      const {
        monedas: { dolar },
        infoExperiencia: {
          data: { precio, precioEnDolares, privado, capacidad },
        },
      } = this;
      if (precioEnDolares) {
        if (privado) return Math.ceil(+precioEnDolares * +dolar) * capacidad;
        else return Math.ceil(+precioEnDolares * +dolar);
      }

      return privado ? Math.ceil(precio) * capacidad : Math.ceil(precio);
    },
    experienciaFavorita() {
      if (this.usuario) {
        let {
          infoExperiencia: {
            data: { favoritos },
          },
          usuario: { uid },
        } = this;
        if (!favoritos) {
          favoritos = [];
        }
        if (favoritos && favoritos.includes(uid)) return true;
        else return false;
      } else {
        false;
      }
    },
    precioEnMiles: () => (numero) => {
      if (numero === undefined) {
        numero = 0;
      }

      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    fechaConMesEscrito: () => (fecha) => {
      const separarFecha = fecha.split("-").reverse();

      let mes = separarFecha[1];
      switch (mes) {
        case "01":
          mes = "ENE";
          break;
        case "02":
          mes = "FEB";
          break;
        case "03":
          mes = "MAR";
          break;
        case "04":
          mes = "ABR";
          break;
        case "05":
          mes = "MAY";
          break;
        case "06":
          mes = "JUN";
          break;
        case "07":
          mes = "JUL";
          break;
        case "08":
          mes = "AGO";
          break;
        case "09":
          mes = "SEP";
          break;
        case "10":
          mes = "OCT";
          break;
        case "11":
          mes = "NOV";
          break;
        case "12":
          mes = "Diciembre";
          break;
      }
      separarFecha[1] = mes;
      const newFecha = separarFecha.join(" ").slice(0, 12);
      return newFecha;
    },
    colorStatus() {
      return {
        fontSize: "14px",
        color:
          this.infoExperiencia.data.status === "Confirmado"
            ? `${this.colors.green}`
            : this.infoExperiencia.data.status === "Abierto"
            ? `${this.colors.beige}`
            : "inherit",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-white {
  background: white;
}
.cardExperiencia {
  box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.15) !important;
  border-radius: 20px;
  margin: auto;
  position: relative;
  width: 235px;
  // height: 400px;
  padding-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
  &__img {
    h2 {
      position: absolute;
      left: 13px;
      bottom: 32px;
      text-shadow: 0 0 2px $main-black, 1px 1px 10px $main-black;
    }
  }
  &__info {
    padding-top: 12px;
    &__chip {
      background-color: $main-purple;
      border-radius: 40px;
      color: $main-white;
      padding: 0 8px;
      margin-left: 15px;
      width: fit-content;
      min-height: 28px;
      img {
        width: 25px;
        margin-right: 7px;
      }
      h5 {
        font-weight: 700;
      }
      &__reserva {
        @extend .cardExperiencia__info__chip;
        // background: #43ce3a;
        background: $main-purple;
      }
      &__privado {
        @extend .cardExperiencia__info__chip;
        background: $main-orange;
      }
    }
  }
  &__status {
    background-color: $main-white;
    color: $main-purple;
    box-shadow: 0px 0px 10px #00000007;
  }
  &__title {
    color: $main-purple;
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 5px;
    h1 {
      &::first-letter {
        font-size: 0.875em;
      }
    }
    h5 {
      color: $main-black;
      font-size: 0.625em;
      font-weight: 700;
      padding-left: 2px;
    }
  }
  &__text {
    padding-bottom: 0;
    height: 110px;
    overflow: auto;

    p {
      margin: 0;
      font-size: 11px;
    }
  }
  &__sales {
    padding-top: 9px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    &__rating {
      button {
        padding: 0;
      }
    }
    h5 {
      padding-left: inherit;
      padding-right: inherit;
      span {
        color: $main-purple;
        font-weight: 600;
      }
    }
  }
}

.v-carousel__controls {
  display: none;
}
</style>

<style lang="scss">
.cardExperiencia {
  .v-item--active {
    background-color: $main-white;
    transform: scale(0.3);
    transition: none;
  }
  .v-btn__content {
    i {
      font-size: 10px !important;
      // transform: scale(0.4);
      opacity: 0.5;
    }
  }
}
</style>
