<template>
  <div id="Cotizacion">
    <section class="container py-10">
      <hr class="mb-4" />
      <h1 class="descubre__title">
        {{
          lenguages[idioma].armaTuExperiencia.titulo ||
            "Arma tu experiencia a medida"
        }}
      </h1>
      <h2 class="mb-4">
        {{
          lenguages[idioma].armaTuExperiencia.subTitulo ||
            "¡Cuéntanos lo que buscas!"
        }}
      </h2>
      <h2
        class="font-weight-regular mb-3"
        v-html="lenguages[idioma].armaTuExperiencia.descripcion"
      ></h2>

      <div>
        <router-link :to="{ name: 'Cotizacion' }">
          <Boton
            :texto="lenguages[idioma].armaTuExperiencia.botonCotizar"
            colorTexto="black"
            height="45px"
            borderRadius="50px"
            color="white"
            :icono="icono"
          />
        </router-link>
      </div>
      <hr class="mt-6" />
    </section>
  </div>
</template>

<style scoped>
#Cotizacion {
  background: #645dc7;
  color: white;
}
.btn {
  width: 20%;
  height: 45px;
}
@media (max-width: 576px) {
  .btn {
    width: 100%;
  }
}
</style>

<style scoped>
.SelectAeronave__select .v-input__control .v-input__slot {
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  padding: 0 27px;
  padding: 0 !important;
  width: 85%;
}
</style>

<script>
import { mapState } from "vuex";
import Boton from "@/components/Botones/Boton";

export default {
  name: "Cotizacion",
  components: {
    Boton,
  },
  computed: {
    ...mapState(["colors", "icons"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
};
</script>
