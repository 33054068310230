export default {
    namespaced: true,
    state: {
        aeronaves: [
            "Airbus AS350 B3/H125",
            "Airbus EC130 B4",
            "Airbus EC130 B4",
            "Augusta A119 MKII Koala",
            "Bell 407",
            "Bell 505",
            "Cessna 172",
            "Gippsaero GA8",
            "Piper Cheyenne PA31T",
            "Robinson R44",
            "Robinson R66",
            "Harmony LSA",
            "Bombardier Challenger 605"
        ]
    },

}