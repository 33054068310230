import html2pdf from "html2pdf.js";

import jsPDF from "jspdf"
import moment from "moment"

const formatString = (text = "") => {
  return text
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};


const generatePdf = (compra) => {
  const { details, date: fechaDeCompra, id: codigoVenta, userData } = compra
  let { ida, vuelta, info, total: montoTotal } = details

  const idIda = ida.id
  ida = ida.data

  const { fecha: fechaIda, horaDespegue: horaDespegueIda, aeronave: aeronaveIDa } = ida


  const doc = new jsPDF({});


  doc.setFont("helvetica", "bold");
  const { origen: idaOrigen, destino: idaDestino } = ida
  doc.text(20, 20, `${idaOrigen} - ${idaDestino}`);

  doc.setFont("helvetica", "normal");
  let { tipoDeVuelo, pasajeros, tipoDeViaje } = info
  let stringPasajeros = pasajeros > 1 ? `${pasajeros} pasajeros` : `${pasajeros} pasajero`
  let subtituloHeader = tipoDeViaje === 'Experiencias'
    ? `${tipoDeViaje} - ${stringPasajeros}`
    : `${tipoDeVuelo} - ${stringPasajeros}`;
  doc.text(20, 30, subtituloHeader)
    ;

  doc.line(20, 40, 200, 40);



  doc.setFont("helvetica", "bold");
  doc.text(20, 50, vuelta ? "Fecha del vuelo de ida" : "Fecha del vuelo");

  doc.setFont("helvetica", "normal");
  doc.text(20, 60, fechaIda);

  doc.setFont("helvetica", "bold");
  doc.text(20, 70, "Hora de abordaje");

  doc.setFont("helvetica", "normal");
  doc.text(20, 80, horaDespegueIda);

  doc.setFont("helvetica", "bold");
  doc.text(20, 90, "Aeronave");

  doc.setFont("helvetica", "normal");
  doc.text(20, 100, aeronaveIDa);

  doc.setFont("helvetica", "bold");
  doc.text(20, 110, "Código de vuelo");

  doc.setFont("helvetica", "normal");
  doc.text(20, 120, idIda);


  if (vuelta) {
    const idVuelta = vuelta.id
    vuelta = vuelta.data


    doc.setFont("helvetica", "bold");
    doc.text(100, 50, "Fecha de vuelo",);

    doc.setFont("helvetica", "normal");
    doc.text(100, 60, vuelta.fecha);

    doc.setFont("helvetica", "bold");
    doc.text(100, 70, "Hora de abordaje");

    doc.setFont("helvetica", "normal");
    doc.text(100, 80, vuelta.horaDespegue);

    doc.setFont("helvetica", "bold");
    doc.text(100, 90, "Aeronave");

    doc.setFont("helvetica", "normal");
    doc.text(100, 100, vuelta.aeronave);

    doc.setFont("helvetica", "bold");
    doc.text(100, 110, "Código de vuelo");

    doc.setFont("helvetica", "normal");
    doc.text(100, 120, idVuelta);
  }

  doc.line(20, 130, 200, 130);

  doc.setFont("helvetica", "bold");
  doc.text(20, 150, "Fecha de compra",);

  doc.setFont("helvetica", "normal");
  doc.text(20, 160, moment(fechaDeCompra).locale("es").format('LLLL'));

  doc.setFont("helvetica", "bold");
  doc.text(20, 170, "Monto Total");

  doc.setFont("helvetica", "normal");
  doc.text(20, 180, '$' + montoTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));

  doc.setFont("helvetica", "bold");
  doc.text(20, 190, "Código de venta");

  doc.setFont("helvetica", "normal");
  doc.text(20, 200, codigoVenta);

  doc.setFont("helvetica", "bold");
  doc.text(20, 210, "Comprado por");

  doc.setFont("helvetica", "normal");
  doc.text(20, 220, userData.displayName);


  doc.setFont("helvetica", "bold").setTextColor("#645dc7");
  doc.text(20, 250, "https://hummingbird.cl/");



  const qr = document.getElementsByClassName("qrcode")[0]

  doc.addImage(qr, "JPEG", 200, 200, 164, 164);


  doc.save(`Humming Bird - Compra - ${codigoVenta}.pdf`);





}



// const generatePdf = (ticketOriginal) => {
//   const body = document.getElementsByTagName('BODY')[0]

//   const ticket = ticketOriginal.cloneNode(true)

//   body.append(ticket)

//   const btnGeneratePdf = ticket.children[0].children[1].children[11]
//   const volverAlInicio = ticket.children[0].children[1].children[12]
//   btnGeneratePdf.remove()
//   volverAlInicio.remove()
//   const HTML_Width = 700;
//   const top_left_margin = 15;
//   const PDF_Width = HTML_Width + top_left_margin * 2;
//   const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;


//   html2canvas(ticket, {
//     allowTaint: true,
//     cors: true,
//   }).then(function (canvas) {

//     canvas.getContext("2d");

//     const imgData = canvas.toDataURL("image/jpeg", 1.0);
//     const pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
//     pdf.setFont('sans serif')
//     pdf.addImage(
//       imgData,
//       "JPG",
//       top_left_margin,
//       top_left_margin
//     );

//     ticket.style.display = "none"
//     pdf.save("HTML-Document.pdf");
//   });
// }

const precioEnMiles = (numero) => {
  if (numero === undefined) {
    numero = 0;
  }

  return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


const orderByPrice = (vuelos) => {
  return vuelos.sort((a, b) => a.data.precio > b.data.precio ? 1 : -1)
}

const makePdf = async (element) => {
  await html2pdf()
    .set({
      margin: 1,
      filename: "documento.pdf",
      image: {
        type: "jpeg",
        quality: 0.98,
      },
      html2canvas: {
        scale: 3, // A mayor escala, mejores gráficos, pero más peso
        letterRendering: true,
      },
      jsPDF: {
        unit: "in",
        format: "a3",
        orientation: "portrait", // landscape o portrait
      },
    })
    .from(element)
    .save()

}

export default { formatString, generatePdf, precioEnMiles, orderByPrice, makePdf };
