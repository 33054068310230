const cotizacion = {
  titulo: "¡Cuéntanos lo que buscas!",
  email: "Email",
  destinoOExperiencia: "Destino o experiencia",
  tipoDeAeronave: "Tipo de aeronave",
  helicoptero: "Helicóptero",
  avion: "Avión",
  cantidadDePasajeros: "Cantidad de pasajeros",
  fechaAproximada: "¿En qué fecha aproximada quiere volar?",
  algoMas: "¿Algo más que queiras decirnos?",
  enviar: "enviar"
}


export default cotizacion