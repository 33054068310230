const experienciasHome = {
    economicas: {
        titulo: "Experiencias Económicas",
        subTitulo: "Seleccionamos estas ofertas para ti, con precios sorprendentes.",
    },
    premium: {
        titulo: "Experiencias Premium",
        subTitulo: "Vive experiencias inolvidables.",
    },
    compartidas: {
        titulo: "Experiencias Compartidas",
        subTitulo: "Vuela en grupo a menor costo",
    },

    privadas: {
        titulo: "Experiencias Privadas",
        subTitulo: "Vuela en una aeronave exclusiva para ti y tu grupo",
    },

    itau: {
        titulo: "Experiencias ITAU",
        subTitulo :"Exclusivo para clientes del banco ITAU"
    },
    botonVerMas: "Ver todas las experiencias"
}


export default experienciasHome