<template>
  <div class="SelectPasajeros">
    <h2>
      {{ lenguages[idioma].heroSection.buscador.pasajeros || "Pasajeros" }}
      
      </h2>
    <v-select
      dense
      placeholder="Cantidad de pasajeros"
      :items="numero"
      :color="colors.mainPurple"
      v-model="pasajerosEscogidos"
      @change="enviarPasajerosAStore"
      flat
      solo
      full-width
      class="SelectPasajeros__select"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      pasajerosEscogidos: "",
    };
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState(["colors"]),
    ...mapState("Buscador", ["busqueda"]),
    numero() {
      let numeros = [];

      for (let i = 1; i <= 10; i++) {
        numeros.push(i);
      }

      return numeros;
    },
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    enviarPasajerosAStore() {
      this.SET_BUSQUEDA_PARAMS({ pasajeros: +this.pasajerosEscogidos });
    },
  },
  mounted() {
    this.pasajerosEscogidos = this.busqueda.pasajeros;
  },
};
</script>

<style lang="scss" scoped>
.SelectPasajeros {
  @include inputInside;
  // width: 272px;
  height: 73px;
  position: relative;
  &__select {
    position: absolute;
    top: 26px;
  }
}
</style>

<style lang="scss">
.SelectPasajeros__select {
  .v-input__control {
    .v-input__slot {
      padding: 0 !important;
      width: 85%;
    }
  }
}
</style>