const armaTuExperiencia = {
    titulo: "Arma tu experiencia a medida",
    subTitulo: "¡Cuéntanos lo que buscas!",
    descripcion: `Encontramos el vuelo y la aeronave ideal para tu necesidad. Nos preocupamos de cada detalle de tu experiencia.
    <br>  <br>
Todos nuestros partners operadores cumplen con nuestra Política de Seguridad Operacional y Protocolo Covid-19.`,
    botonCotizar: "Cotizar"
}


export default armaTuExperiencia