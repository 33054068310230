<template>
  <div class="SelectAeronave">
    <h2>
      {{ lenguages[idioma].heroSection.buscador.aeronave || "Aeronave" }}
    </h2>
    <v-select
      dense
      :placeholder="
        lenguages[idioma].heroSection.buscador.seleccionaTusAeronave ||
          'Selecciona tu aeronave'
      "
      :items="aeronaves"
      :color="colors.mainPurple"
      v-model="aeronaveEscogida"
      @change="enviarAeronaveAStore"
      flat
      solo
      height="15"
      full-width
      class="SelectAeronave__select"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      aeronaves: ["Avión", "Helicóptero", "Todo tipo de aeronave"],
      aeronaveEscogida: "",
    };
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Buscador", ["busqueda"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    enviarAeronaveAStore() {
      let aeronaveIdaYVuelta = {
        ida: this.aeronaveEscogida,
        vuelta: this.aeronaveEscogida,
      };
      this.SET_BUSQUEDA_PARAMS({ aeronave: aeronaveIdaYVuelta });
    },
  },
  mounted() {
    this.aeronaveEscogida = this.busqueda.aeronave.ida;
  },
};
</script>

<style lang="scss" scoped>
.SelectAeronave {
  @include inputInside;
  @include flex(column, space-between, flex-start);
  overflow: hidden;
  height: 73px;
  // width: 273px;
  padding: 4px 20px;
  position: relative;
  &__select {
    position: absolute;
    top: 26px;
    width: 100%;
  }
}
</style>

<style lang="scss">
.SelectAeronave__select {
  .v-input__control {
    .v-input__slot {
      padding: 0 !important;
      width: 85%;
    }
  }
}
</style>
