<template>
  <v-tooltip bottom :disabled="!desactivarBoton">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-btn
          class="Search"
          fab
          :color="colors.mainPurple"
          @click="buscar"
          :disabled="desactivarBoton"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
    </template>
    <span>{{ mensajeTooltip }}</span>
  </v-tooltip>
</template>

<script>
import Boton from "@/components/Botones/Boton";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Boton,
  },
  computed: {
    ...mapState(["icons", "colors"]),
    ...mapState("Buscador", ["busqueda"]),
    desactivarBoton() {
      const {
        origen,
        destino,
        aeronave: { ida: aeronave },
      } = this.busqueda;

      // return !(origen || destino || aeronave);
      return false;
    },
    mensajeTooltip() {
      return this.busqueda.tipoDeViaje === "Vuelos"
        ? "Para buscar tu vuelo completa todos los campos."
        : "Ingresa un origen, destino o aeronave para realizar tu busqueda.";
    },
  },
  methods: {
    ...mapActions("Buscador", ["reunirCriteriosYBuscar"]),
    buscar() {
      this.reunirCriteriosYBuscar();
    },
  },
};
</script>

<style lang="scss" scoped>
.Search {
  color: $main-white;
}
</style>
