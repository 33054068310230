const pasoAPaso = {
    titulo: "Shared flights in helicopters and light airplanes",
    subTitulo: "How to fly?",
    pasos: {
        paso1: "Select your experience",
        paso2: "Choose one of the available dates",
        paso3: "Reserve and pay only the value of your quota",
        paso4: "Enjoy an unforgettable experience"
    },
    leyenda: "Find more information in the description of each experience"
}


export default pasoAPaso