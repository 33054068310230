const misDatos = {
    nombreYApellido: "Full Name",
    tipoDeDocumento: "Type and number of document",
    paisDeOrigen: "Country",
    correoElectronico: "Email",
    numeroMovil: "Phone",
    direccion: "Address",
    intereses: "Interests",
    helicoptero: "Helicopter",
    avion: "Airplane",
    vinas: "Vineyards",
    playa: "Beach",
    mar: "Sea",
    montanas: "Moutains",
    deportes: "Sports",
    aventuras: "Adventure",
    cancelar: "cancel",
    confirmarEdicion: "confirm edit"
}

export default misDatos