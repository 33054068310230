<template>
  <div class="d-sm-flex align-items-end">
    <v-row>
      <v-col class="pb-0" cols="12" md="4">
        <label> Región</label>
        <v-select
          :dense="mobile"
          attach
          placeholder="Región"
          rounded
          :items="regionesLabels"
          label="Región"
          solo
          v-model="seleccion.region"
        ></v-select>
      </v-col>

      <v-col class="pb-0" cols="12" md="2">
        <label> Aeronave</label>
        <v-select
          :dense="mobile"
          :items="aeronaves"
          rounded
          label="Aeronave"
          solo
          v-model="seleccion.aeronave"
        ></v-select>
      </v-col>

      <v-col class="pb-0" cols="12" md="2">
        <label> Servicio</label>
        <v-select
          :dense="mobile"
          :items="servicios"
          rounded
          label="Servicio"
          solo
          v-model="seleccion.servicio"
        ></v-select>
      </v-col>

      <v-col class="pb-0" cols="12" md="2">
        <label> Pasajeros</label>
        <v-select
          :dense="mobile"
          :items="asientos"
          rounded
          label="Pasajeros"
          solo
          v-model="seleccion.asientos"
        ></v-select>
      </v-col>

      <v-col class="pb-0" cols="12" md="2">
        <label> Duración</label>
        <v-select
          :dense="mobile"
          :items="tiempos"
          rounded
          label="Tiempo"
          solo
          v-model="seleccion.tiempo"
        ></v-select>
      </v-col>
    </v-row>

    <v-col cols="12" md="1" class="ma-0 pa-0 d-flex justify-center">
      <v-btn
        v-if="mobile"
        @click="search"
        size="small"
        class="pa-6 col-11"
        dark
        color="#645dc7"
      >
        Buscar experiencia
        <v-icon dark class="ml-3">
          mdi-magnify
        </v-icon>
      </v-btn>

      <v-btn
        v-else
        @click="search"
        fab
        dark
        color="#645dc7"
        class="ml-sm-4  mt-4"
      >
        <v-icon dark>
          mdi-magnify
        </v-icon>
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import zonas from "@/assets/zonas";

export default {
  name: "Buscador-Home",
  methods: {
    search() {
      const { seleccion } = this;
      const zonaEncontrada = zonas.find((zona) =>
        zona.regiones.some((r) => r.region === seleccion.region)
      );
      if (zonaEncontrada) seleccion.zona = zonaEncontrada.zona;
      this.$router.push({ path: "experiencias", query: seleccion });
    },
  },
  data() {
    return {
      seleccion: {
        region: "",
        aeronave: "",
        asientos: "",
        tiempo: "",
        servicio: "",
        zona: "",
      },
      servicios: [
        { text: "Privado", value: "privado" },
        { text: "Compartido", value: "compartido" },
        { text: "Cualquiera", value: "" },
      ],

      tiempos: [
        { text: "Hasta 15 minutos", value: "15" },
        { text: "Hasta 30 minutos", value: "30" },
        { text: "Hasta 45 minutos", value: "45" },
        { text: "Hasta 60 minutos", value: "60" },
        { text: "Hasta 90 minutos", value: "60" },
        { text: "Cualquiera", value: "" },
      ],
      asientos: [
        { text: "Hasta 2 asientos", value: "2" },
        { text: "Hasta 4 asientos", value: "4" },
        { text: "Hasta 6 asientos", value: "6" },
        { text: "Hasta 8 asientos", value: "8" },
        { text: "Hasta 10 asientos", value: "10" },
        { text: "Cualquiera", value: "" },
      ],
      aeronaves: [
        {
          text: "Helicóptero",
          value: "helicoptero",
        },
        {
          text: "Avión",
          value: "avion",
        },
        {
          text: "Cualquier aeronave",
          value: "",
        },
      ],
    };
  },
  computed: {
    regionesLabels() {
      return zonas
        .map((z) => z.regiones)
        .flat()
        .map((r) => ({ text: r.region, value: r.region_number }))
        .concat({ text: "Cualquiera", value: "" });
    },
    mobile() {
      return (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "xs"
      );
    },
  },
};
</script>

<style scoped>
label {
  color: rgba(255, 255, 255, 0.886);
  margin-left: 20px;
  position: relative;
  top: -6px;
}

@media (max-width: 540px) {
  label {
    color: #494949;
  }
}
</style>
