import firebase from 'firebase'
import store from '@/store'
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        operadores: []
    },
    mutations: {
        GET_OPERADORES(state, operadores) {
            state.operadores = operadores;
        }
    },
    actions: {
        async addOperador({ }, data) {
            try {
                const firebaseOperadores = firebase.firestore().collection('operadores');

                //Agregar operador
                let agregarOperadorAFirebase = await firebaseOperadores.add(data);
                const idOperador = agregarOperadorAFirebase.id;

                //Incluir id dentro de documento
                await firebaseOperadores.doc(idOperador).update({ id: idOperador });

            } catch (error) {
                console.log(error)
            }
        },
        async getOperadores({ commit }) {
            try {
                await firebase
                    .firestore()
                    .collection('operadores')
                    .onSnapshot(snapshot => {
                        let operadores = []
                        snapshot.forEach(vuelo => {
                            operadores.push(vuelo.data())
                        })
                        commit('GET_OPERADORES', operadores)
                    })
            } catch (error) {
                console.log(error);
            }
        },

    },
    getters: {
        comentariosOfOperadoresById: (state, getters, rootState) => ({ idsVuelos_Y_O_Experiencias, uid }) => {
            // let { vuelos } = { ...rootState }.Vuelos;
            // let { experiencias } = { ...rootState }.Experiencias;
            // const vuelosCoincidentes = vuelos.concat(experiencias)
            // return vuelosCoincidentes
            const idsVuelos_Y_O_ExperienciasComentadas = []
            state.operadores.forEach(operador => {
                    operador.calificaciones.forEach( c => {
                        if(idsVuelos_Y_O_Experiencias.includes(c.compra.id) && c.uidUser == uid) {
                            const idComentado = idsVuelos_Y_O_Experiencias.find( id => id == c.compra.id)
                            idsVuelos_Y_O_ExperienciasComentadas.push(idComentado)
                        }
                    })
            });
            
            return idsVuelos_Y_O_ExperienciasComentadas
        },
        getOperadorById: (state) => (id) => {
            let operador = state.operadores.find(o => {
                return o.id === id
            })
            return operador
        },
        getOperadorByName: (state) => (name) => {
            let operador = state.operadores.find(o => {
                return o.nombre === name
            })
            return operador
        },

        getRatingByOperadorId: (state) => (id) => {
            let operador = state.operadores.find(o => {
                return o.id === id
            })
            return operador ? operador.rating  : 5.0
        },

        getVuelosByOperador: (state, getters, rootState) => (id) => {
            let vuelos = { ...rootState.Vuelos };
            let experiencias = rootState.Experiencias;

            let filtrarVuelos = vuelos.filter(v => v.data.operador.id === id);
            let filtrarExperiencias = experiencias.filter(v => v.data.operador.id === id);
            let vuelosYExperiencias = filtrarVuelos.concat(filtrarExperiencias);

            return vuelosYExperiencias
        }
    }
}