<template>
  <div>
    <v-navigation-drawer v-model="drawer" absolute temporary id="Menu-Mobile">
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <!-- <v-list-item>
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-title
              ><routerLink to="/vuelos">Vuelos</routerLink></v-list-item-title
            >
          </v-list-item> -->

          <v-list-item>
            <!-- <v-list-item-icon> </v-list-item-icon> -->
            <v-list-item-title>
              <routerLink to="/experiencias">
                {{
                  lenguages[idioma].nav.experiencias || "Experiencias"
                }}</routerLink
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <!-- <v-list-item-icon> </v-list-item-icon> -->
            <v-list-item-title>
              <routerLink :to="{ name: 'Seguridad' }">{{
                lenguages[idioma].nav.seguridadOperacional ||
                  "Seguridad operacional"
              }}</routerLink></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <!-- <v-list-item-icon> </v-list-item-icon> -->
            <v-list-item-title>
              <a
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfMhU8XzvYPlp-hZgymz4ABuLg63ohC9NHLeBh-juBA6P4H9A/viewform?gxids=7628&pli=1"
                >{{
                  lenguages[idioma].nav.eresOperador || "¿Eres operador?"
                }}</a
              ></v-list-item-title
            >
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-title>
              <routerLink :to="{ name: 'Noticias' }">{{
                lenguages[idioma].nav.noticias || "Noticias"
              }}</routerLink></v-list-item-title
            >
          </v-list-item> -->

          <v-list-item>
            <!-- <v-list-item-icon> </v-list-item-icon> -->
            <v-list-item-title>
              <routerLink :to="{ name: 'Aerodromos' }">{{
                lenguages[idioma].nav.aerodromos || "Aeródromos"
              }}</routerLink></v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <!-- <v-list-item-icon> </v-list-item-icon> -->
            <v-list-item-title>
              <routerLink :to="{ name: 'FAQ' }">{{
                lenguages[idioma].nav.faq || "Preguntas Frecuentes"
              }}</routerLink></v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["drawer"],
  name: "Navbar-Mobile",
  data() {
    return {
      group: null,
    };
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
};
</script>

<style scoped>
#Menu-Mobile {
  height: 100vh !important;
}
</style>
