<template>
  <div class="backBar d-md-none">
    <v-container >
      <div class=" d-flex align-center">
        <img :src="icons.gray.backArrow" alt="volver" @click="$router.go(-1)" class="pr-5" />
        <h2 v-if="tituloUno">{{tituloUno}}</h2>
      </div>
      <h2 v-if="tituloDos" class="pl-3 pt-3">{{tituloDos}}</h2>
    </v-container>
    
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "backBar",
  data() {
    return {};
  },
  computed: {
    ...mapState(["icons"]),
    tituloUno(){
      let titulo;
      return titulo
    },
    tituloDos(){
      let titulo;
      if(this.$route.name === 'Pago') {
        titulo =  'Método de pago'
      }
      return titulo

    }
  },

};
</script>

<style lang="scss" scoped>
.backBar {
  min-height: 48px;
  background: $main-white;
  // background: linear-gradient($main-white , transparent);
  box-shadow: 0px 7px 10px rgba(128, 122, 122, 0.1);
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  transform: all .6s ease;
}
</style>