const detalle = {
  salidaYLlegada: "Departure and Arrival",
  llegada: "Arrives",
  tiempoDeVuelo: "Flight time",
  tiempoTotal: "Total experience time",
  minutos: "minutes",
  fechasDisponibles: "Calendar with available dates",
  a: "to",
  detallesDelVuelo: "Flight  details",
  coordinacionDeTuVueloPrivado: "Coordination of your private flight",
  coordinacionDeTuVueloCompartido: "Coordination of your shared flight",

  coordinacionesExperienciaPrivada:

    [
      'Price for the aircraft for you or your closed group.',

      'Immediate agenda available, 48 business hours in advance.',

      'Reservations by electronic transfer or flow/webpay.'],


  coordinacionesExperienciaCompartida: [
    'Price per person.',

    'Choose one of the shared experiences.',

    'Choose one of the available dates.',

    'Reserve your space by electronic transfer or flow/webpay.',

    'Enjoy an unforgettable experience.',
  ],


  viajesInterregionalesTitulo: "Interregional travel",
  viajesInterregionales: [
    "Passengers without a Mobility Pass, must demonstrate transfer for medical treatment, funeral, return to habitual residence or Single Collective Permit of Virtual Police Station."
  ],

  medidasDeSeguridadTitulo: "Safety measures",
  medidasDeSeguridad: [
    "All our aircraft have their current certificates, approved by the Chilean aeronautical authority.",
    "All our operators comply with our Health Protocol for protection against Covid-19. Learn more about our Operational Safety section.",
    "For all our experiences, you can consult the Private flight option and have the aircraft just for you."
  ],
}

export default detalle