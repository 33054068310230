const seguridadOperacional = {
    titulo1: 'SAFETY POLICY',
    descripcion1: `At Hummingbird we guarantee the flight safety of our passengers, through the following policy.
        for
        All of our aircraft operating partners have their current certifications, issued by the General Directorate of Civil Aeronautics. This means that the aeronautical authority has reviewed and approved them to carry out air operations, guaranteeing:`,
    puntos1_1: [
        "Commercial Operator Certificate, with authorization to commercialize flights, under the DAN-135 regulation.",
        "Airworthiness of aircraft",
        "Up-to-date aircraft maintenance, permanently controlled and carried out by Aeronautical Maintenance Centers authorized for each type of aircraft.",
        "Pilots with recurring training and valid aeronautical licenses.",
        "Civil Liability Insurance."
    ],
    descripcion2: `At Hummingbird, we monitor and control the renewal of certificates in each operator. We verify that the security policies of our operators are under the international standard of Safety Management System (SMS), which guarantees that our operators have, among other elements, with:`,
    puntos1_2: [
        "Safety Policy",
        "Gestión de riesgos y mejora continua",
        "Risk management and continuous improvement",
        "Evaluation of experience",
        "Secure passenger registration",
        "Checking the size and weight of luggage",
        "Absence of dangerous goods",
        "Evaluation of the pilot in command of the aircraft"
    ],
    titulo2: "HEALTH PROTOCOL COVID-19",
    descripcion2_1: "Our operators have hygiene and safety protocols in place to protect the crew and passengers from Covid-19.",
    puntos2: [
        "Temperature measurement",
        "Use of alcohol gel and masks throughout the experience.",
        "Cleaning and disinfection of the aircraft before and after each flight. Cleaning includes manipulable surfaces and elements, such as phones, handles, command and control elements of the aircraft.",
        "We minimize the number of personnel and procedures prior to the execution of the flight, promoting social distancing, washing and / or disinfection of hands."
    ],
    descripcion2_2: `With Hummingbird, avoid contagion in busy airports and fly from the private offices of operators in small airfields.`,
}


export default seguridadOperacional