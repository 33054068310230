<template>
  <v-carousel
    cycle
    :height="anchoDePantalla > 720 ? '600px' : '350px'"
    hide-delimiters
    class="rounded-lg"
  >
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
      eager
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src:
            "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Imagenes%20Promociones%20para%20carrusel%2FPost_12%20(2).png?alt=media&token=1ed5cd6c-b8d7-4389-8e2b-40a67987f48a",
        },
        {
          src:
            "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Imagenes%20Promociones%20para%20carrusel%2FPost_11%20(1).png?alt=media&token=b908d5ef-babc-468f-8899-795da17bb7c8",
        },
        {
          src:
            "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Imagenes%20Promociones%20para%20carrusel%2FPost_10%20(2).png?alt=media&token=658848ff-406f-48a9-8510-095f041d8ccc",
        },
        {
          src:
            "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Imagenes%20Promociones%20para%20carrusel%2FPost_14%20(1).png?alt=media&token=5d5248df-1225-4b0e-9f23-bd67db819fee",
        },
      ],
    };
  },
  computed: {
    anchoDePantalla() {
      return window.innerWidth;
    },
  },
};
</script>

<style></style>
