<template>
  <div id="Dialog">
    <v-dialog v-model="dialog" class="ma-auto popup" style="overflow:hidden">
      <!-- <a
        style="cursor:pointer"
        href="https://www.cuponatic.com/descuento/319747/panoramica-de-santiago-desde-el-aire-en-helicoptero-o-avion"
      >
        <img style="height: 80vh; width: auto" :src="items[0].src" />
      </a> -->
      <v-carousel hide-delimiters :show-arrows="true">
        <v-carousel-item v-for="(item, i) in items" :key="i">
          <a
            href="https://www.cuponatic.com/descuento/319747/panoramica-de-santiago-desde-el-aire-en-helicoptero-o-avion"
          >
            <img
              style="height: auto;
                    width: 100% ;
                    max-height: 800px;"
              :src="item.src"
            />
          </a>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return {
      dialog: false,
      items: [
        {
          src:
            "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Imagenes%20para%20el%20popup%2FD%C3%ADa%20del%20padre%2FD%C3%ADa%20del%20padre%20-%20Artes%20(1)%20(1).jpg?alt=media&token=c9a0a19c-f6e3-4a53-82d8-7e9db6dbcad3",
        },
        {
          src:
            "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Imagenes%20para%20el%20popup%2FD%C3%ADa%20del%20padre%2FD%C3%ADa%20del%20padre%20-%20Artes%20(2)%20(1).jpg?alt=media&token=d88fd668-4abb-4894-a1a2-9cbad2fc451a",
        },
        {
          src:
            "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Imagenes%20para%20el%20popup%2FD%C3%ADa%20del%20padre%2FD%C3%ADa%20del%20padre%20-%20Artes%20(3)%20(1).jpg?alt=media&token=8e34a579-d3a3-48ae-aabf-f5b7825a8066",
        },
        {
          src:
            "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Imagenes%20para%20el%20popup%2FD%C3%ADa%20del%20padre%2FD%C3%ADa%20del%20padre%20-%20Artes%20(4)%20(1).jpg?alt=media&token=c1ff6950-c442-4ec5-9fa0-d716715c8c80",
        },
      ],
    };
  },
};
</script>

<style>
#Dialog img {
  height: 100%;
  width: 100%;
  max-width: 800px;
  display: none;
}
.v-dialog {
  width: auto !important;
}

.v-dialog > div,
.v-dialog .v-image {
  height: auto !important;
}
</style>
