const armaTuExperiencia = {
    titulo: "Customize your experience",
    subTitulo: "Tell us what you are looking for!",
    descripcion: `We find the ideal flight and aircraft for your needs. We care about every detail of your experience.
    
    <br>  <br>
    All our operating partners comply with our Operational Security Policy and Covid-19 Protocol.`,
    botonCotizar: "Quote"
}


export default armaTuExperiencia