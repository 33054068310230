<template>
  <div>
    <div v-if="usuario === null" @click="irAlPerfil">
      <div>
        <div class="d-none d-sm-flex loginButton">
          <span>
            {{ lenguages[idioma].nav.ingresa || "Ingresa" }}
          </span>
          <img :src="icons.purple.user" draggable="false" />
        </div>
        <div class=" d-sm-none  loginButton">
          <span>Menu</span>
          <v-icon>mdi-menu</v-icon>
        </div>
      </div>
    </div>

    <div class="loginButton logged" v-else @click="irAlPerfil">
      <div class="text-right">
        <div class="d-none d-lg-block">
          <span class="logged__username d-block font-weight-regular">
            {{ usuario.displayName.split(" ")[0] }}
          </span>
          <span class="logged__link d-none d-md-block ">
            {{ lenguages[idioma].nav.miPerfil || "Mi Perfil" }}
          </span>
        </div>
      </div>
      <img class="logged__img" :src="usuario.photoURL" draggable="false" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "LoginButton",
  computed: {
    ...mapState("Auth", ["usuario"]),
    ...mapState(["icons"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
  methods: {
    ...mapActions("Auth", ["logout"]),
    irAlPerfil() {
      const width = document.body.clientWidth;

      if (width < 500) {
        this.$emit("toggleMobileNavbar");
      } else if (!this.usuario) {
        this.$router.push({ name: "Login" });
      } else if (this.$route.name != "MiPerfil") {
        this.$router.push({ name: "MiPerfil" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loginButton {
  align-items: center;
  background-color: $main-white;
  border-radius: 40px;
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 55px;
  justify-content: center;
  padding: 12px;
  transition: background-color 0.2s;
  width: 151px;
  a {
    text-decoration: none;
    color: inherit;
  }
  span {
    color: $main-gray;
    font-size: 14px;
    font-weight: 700;
    padding-right: 11px;
  }
  img {
    width: 37px;
  }
  &:hover {
    cursor: pointer;
    background-color: $second-white;
  }
}

.logged {
  width: fit-content;

  &__username {
    font-weight: 400;
  }
  &__img {
    border-radius: 50%;
  }
}
</style>
