const nav = {
    vuelos: "Flyes",
    experiencias: "Experiences",
    seguridadOperacional: "Safety",
    eresOperador: "Are you an operator?",
    noticias: "News",
    aerodromos: "Aerodromes",
    faq: "FAQS",
    ingresa: "Log in",
    buscarVuelos: "Search",
    miPerfil: "My Profile",
    seleccioneElIdioma: "Select language",
    seleccioneLaMoneda: "Select currency"
}

export default nav