<template>
  <div class="home">
    <!-- <Dialog /> -->

    <!--Hero section -->
    <header class="heroSection">
      <video
        muted
        loop
        webkit-playsinline="true"
        playsinline="true"
        autoplay=""
        :src="videoHeroSection"
        ref="video"
      ></video>
      <v-container class="relative">
        <div class="d-flex justify-space-between">
          <img
            :src="logos.hummingbird.main"
            alt="logo humming bird"
            class="heroSection__logo"
            draggable="false"
          />
          <div class="d-none d-sm-block">
            <LoginButton />
          </div>
        </div>

        <h1 class="heroSection__title mx-3 mx-md-auto text-center text-md-left">
          {{
            lenguages[idioma].heroSection.dondeViajar ||
              " A Dónde quieres viajar?"
          }}
        </h1>

        <!-- Buscador -->

        <Buscador v-if="!mobile" />
        <div v-else class="d-flex justify-center">
          <v-dialog transition="dialog-bottom-transition" rounded>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                depressed
                color="#645dc7"
                class="col-9 mx-auto"
                v-bind="attrs"
                v-on="on"
              >
                Buscar una experiencia
                <v-icon dark class="ml-4">
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card class="pa-5" color="#f3f3f3">
                <Buscador />
              </v-card>
            </template>
          </v-dialog>
        </div>
      </v-container>
    </header>

    <div class="z-index" style="background: white">
      <!-- Experiencias Compartidas -->
      <section class="descubre">
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <h1 class="descubre__title">
                {{
                  lenguages[idioma].experienciasHome.compartidas.titulo ||
                    "Experiencias Premium"
                }}
              </h1>
              <h4 class="descubre__subtitle d-none d-sm-block">
                {{
                  lenguages[idioma].experienciasHome.compartidas.subTitulo ||
                    "Vive experiencias inolvidables"
                }}
              </h4>
            </v-col>
          </v-row>
          <Slider
            class="d-none d-lg-block pa-0 "
            :vuelos="experienciasCompartidas"
          />
          <div class="d-flex descubre__slider py-5 d-lg-none">
            <div v-for="(experiencia, i) in experienciasCompartidas" :key="i">
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias
                  :infoExperiencia="experiencia"
                  class="mr-md-8"
                />
              </router-link>
            </div>
          </div>
          <router-link
            class="btn-experiencias-correccion"
            :to="{ name: 'Experiencias' }"
          >
            <Boton
              :texto="
                lenguages[idioma].experienciasHome.botonVerMas ||
                  'Ver todas las experiencias'
              "
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
            />
          </router-link>
        </v-container>
      </section>
      <v-container>
        <PasoAPaso />
      </v-container>
    </div>

    <div class="z-index">
      <!--Black Fly Fri Day-->
      <section
        class="descubre pt-5 "
        v-if="experienciasBlackFlyDay.length"
        style="background: black"
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <h1
                class="descubre__title set_golden_Text"
                style=" text-shadow: 1px 1px 1px gray"
              >
                BLACK FLY DAY
              </h1>
              <h4 class="descubre__subtitle d-none d-sm-block white--text">
                Ofertas imperdibles para este Black Friday.
              </h4>
            </v-col>
          </v-row>
          <!--Slider -->
          <Slider
            class="d-none d-lg-block pa-0 "
            :vuelos="experienciasBlackFlyDay"
          />
          <div class="d-flex descubre__slider py-5 d-lg-none">
            <div v-for="(experiencia, i) in experienciasBlackFlyDay" :key="i">
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias
                  :infoExperiencia="experiencia"
                  class="mr-md-8"
                />
              </router-link>
            </div>
          </div>
          <router-link
            class="btn-experiencias-correccion"
            :to="{ name: 'Experiencias' }"
          >
            <Boton
              :texto="
                lenguages[idioma].experienciasHome.botonVerMas ||
                  'Ver todas las experiencias'
              "
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
            />
          </router-link>
        </v-container>
      </section>

      <!-- Promoción X (X+1) -->
      <section class="descubre" v-if="promocionXPlus1.length > 0">
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <h1 class="descubre__title">HUMMING DAY</h1>
              <h2 style="font-size: 26px">¡Vuela en grupo, paga menos!</h2>
              <h4 class="descubre__subtitle d-none d-sm-block">
                Vive una experiencia en grupo con un asiento GRATIS
              </h4>
            </v-col>
          </v-row>
          <!--Slider -->
          <Slider class="d-none d-lg-block pa-0 " :vuelos="promocionXPlus1" />
          <div class="d-flex descubre__slider py-5 d-lg-none">
            <div v-for="(experiencia, i) in promocionXPlus1" :key="i">
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias
                  :infoExperiencia="experiencia"
                  class="mr-md-8"
                />
              </router-link>
            </div>
          </div>
          <router-link
            class="btn-experiencias-correccion"
            :to="{ name: 'Experiencias' }"
          >
            <Boton
              :texto="
                lenguages[idioma].experienciasHome.botonVerMas ||
                  'Ver todas las experiencias'
              "
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
              color="#002A87"
            />
          </router-link>
        </v-container>
      </section>

      <!-- Experiencias Privadas -->
      <section class="descubre">
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <h1 class="descubre__title">
                {{
                  lenguages[idioma].experienciasHome.privadas.titulo ||
                    "Experiencias Premium"
                }}
              </h1>
              <h4 class="descubre__subtitle d-none d-sm-block">
                {{
                  lenguages[idioma].experienciasHome.privadas.subTitulo ||
                    "Vive experiencias inolvidables"
                }}
              </h4>
            </v-col>
          </v-row>
          <Slider
            class="d-none d-lg-block pa-0 "
            :vuelos="experienciasPrivadas"
          />
          <div class="d-flex descubre__slider py-5 d-lg-none">
            <div v-for="(experiencia, i) in experienciasPrivadas" :key="i">
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias
                  :infoExperiencia="experiencia"
                  class="mr-md-8"
                />
              </router-link>
            </div>
          </div>
          <router-link
            class="btn-experiencias-correccion"
            :to="{ name: 'Experiencias' }"
          >
            <Boton
              :texto="
                lenguages[idioma].experienciasHome.botonVerMas ||
                  'Ver todas las experiencias'
              "
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
            />
          </router-link>
        </v-container>
      </section>
      <!--experiencias economy-->
      <!-- <section class="descubre">
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <h1 class="descubre__title">
                {{
                  lenguages[idioma].experienciasHome.economicas.titulo ||
                    "Experiencias Económicas"
                }}
              </h1>
              <h4 class="descubre__subtitle d-none d-sm-block">
                {{
                  lenguages[idioma].experienciasHome.economicas.subTitulo ||
                    "Seleccionamos estas ofertas para ti, con precios sorprendentes."
                }}
              </h4>
            </v-col>
          </v-row>
          <Slider
            class="d-none d-lg-block pa-0 "
            :vuelos="experienciasEconomicas"
          />
          <div class="d-flex descubre__slider py-5 d-lg-none">
            <div v-for="(experiencia, i) in experienciasEconomicas" :key="i">
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias
                  :infoExperiencia="experiencia"
                  class="mr-md-8"
                />
              </router-link>
            </div>
          </div>
          <router-link
            class="btn-experiencias-correccion"
            :to="{ name: 'Experiencias' }"
          >
            <Boton
            class="mt-2"
              :texto="
                lenguages[idioma].experienciasHome.botonVerMas ||
                  'Ver todas las experiencias'
              "
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
            />
          </router-link>
        </v-container>
      </section> -->

      <!--experiencias Caras-->
      <!-- <section class="descubre">
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <h1 class="descubre__title">
                {{
                  lenguages[idioma].experienciasHome.premium.titulo ||
                    "Experiencias Premium"
                }}
              </h1>
              <h4 class="descubre__subtitle d-none d-sm-block">
                {{
                  lenguages[idioma].experienciasHome.premium.subTitulo ||
                    "Vive experiencias inolvidables"
                }}
              </h4>
            </v-col>
          </v-row>
          <Slider class="d-none d-lg-block pa-0 " :vuelos="experienciasCaras" />
          <div class="d-flex descubre__slider py-5 d-lg-none">
            <div v-for="(experiencia, i) in experienciasCaras" :key="i">
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias
                  :infoExperiencia="experiencia"
                  class="mr-md-8"
                />
              </router-link>
            </div>
          </div>
          <router-link
            class="btn-experiencias-correccion"
            :to="{ name: 'Experiencias' }"
          >
            <Boton
            class="mt-2"
              :texto="
                lenguages[idioma].experienciasHome.botonVerMas ||
                  'Ver todas las experiencias'
              "
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
            />
          </router-link>
        </v-container>
      </section> -->
      <!--Experiencias Itau-->
      <section
        class="descubre pt-5 "
        v-if="false"
        style="background: white"
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="8">
              <h1
                class="descubre__title  colorItau "
                style=" text-shadow: 1px 1px 1px gray"
              >
                {{ lenguages[idioma].experienciasHome.itau.titulo }}
              </h1>
              <h4 class="descubre__subtitle d-none d-sm-block ">
                {{ lenguages[idioma].experienciasHome.itau.subTitulo }}
              </h4>
            </v-col>
          </v-row>
          <Slider class="d-none d-lg-block pa-0 " :vuelos="experienciasItau" />
          <div class="d-flex descubre__slider py-5 d-lg-none">
            <div v-for="(experiencia, i) in experienciasItau" :key="i">
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias
                  :infoExperiencia="experiencia"
                  class="mr-md-8"
                />
              </router-link>
            </div>
          </div>
          <router-link
            class="btn-experiencias-correccion"
            :to="{ name: 'Experiencias' }"
          >
            <Boton
              :texto="
                lenguages[idioma].experienciasHome.botonVerMas ||
                  'Ver todas las experiencias'
              "
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
            />
          </router-link>
        </v-container>
      </section>
      <!--Empty Legs -->
      <section class="descubre" v-if="viajesEmptyLegs.length > 0">
        <v-container class="pb-0">
          <v-row>
            <v-col cols="12" md="8">
              <h1 class="descubre__title">¡Ofertas!</h1>
              <h4 class="descubre__subtitle d-none d-sm-block">
                Aprovecha los precios de los vuelos empty legs
              </h4>
            </v-col>
          </v-row>
          <!--Slider -->
          <Slider class="d-none d-lg-block pa-0 " :vuelos="viajesEmptyLegs" />
          <div class="d-flex descubre__slider py-5 d-lg-none">
            <div v-for="(experiencia, i) in viajesEmptyLegs" :key="i">
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias
                  :infoExperiencia="experiencia"
                  class="mr-md-8"
                />
              </router-link>
            </div>
          </div>
          <router-link
            class="btn-experiencias-correccion"
            :to="{ name: 'Experiencias' }"
          >
            <Boton
              :texto="
                lenguages[idioma].experienciasHome.botonVerMas ||
                  'Ver todas las experiencias'
              "
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
            />
          </router-link>
        </v-container>
      </section>

      <!--Proximos Vuelos -->
      <!-- <section class="proximosVuelos rounded-t-xl">
        <v-container>
          
          <v-row>
            <v-col cols="12" md="8">
              <h2 class="proximosVuelos__title">
                Descubre los próximos vuelos
              </h2>
              <h4 class="proximosVuelos__subtitle ">
                Seleccionamos estas ofertas para ti, con precios sorprendentes.
              </h4>
            </v-col>
          </v-row>
          <v-row v-if="vuelos.length === 0">
            <v-col v-for="n of 6" :key="n" cols="12" sm="6" md="4">
              <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="(vuelo, i) in proximosVuelos"
              :key="i"
            >
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Vuelos', id: vuelo.id },
                }"
              >
                <CardProximoVuelo :infoVuelo="vuelo" />
              </router-link>
            </v-col>
          </v-row>
          <router-link :to="{ name: 'Vuelos' }">
            <Boton
              texto="Ver todos los vuelos"
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
              class="my-4"
            />
          </router-link>
        </v-container>
      </section> -->
    </div>

    <v-container class="mb-15" id="Corporativo-promociones">
      <VideoCoorporativo />
      <CarouselPromociones class="promociones" />
    </v-container>

    <Cotizacion />
    <v-container>
      <Banner />
    </v-container>

    <Contacto />

    <Footer />
    <Cookies />
  </div>
</template>

<script>
import VideoCoorporativo from "@/components/VideoCoorporativo";
import PasoAPaso from "@/components/Home/PasoAPaso";
import CarouselPromociones from "@/components/Home/CarouselPromociones";
import Buscador from "@/components/Home/Buscador";
import Slider from "@/components/Slider";
import Cookies from "@/components/Cookies";
import Banner from "@/components/Home/Banner";
import Dialog from "@/components/Dialog";
import Contacto from "@/components/Home/Contacto";
import Cotizacion from "@/components/Home/Cotizacion";
import Footer from "@/components/Home/Footer";
import Boton from "@/components/Botones/Boton";
import CardProximoVuelo from "@/components/Cards/CardProximoVuelo";
import CardExperiencias from "@/components/Cards/CardExperiencias";
import LoginButton from "@/components/Botones/LoginButton";
import VerTodasBtn from "@/components/Botones/ButtonVerTodas";
import Origen from "@/components/InputsHero/Origen";
import InputDestino from "@/components/InputsHero/Destino";
import DatePicker from "@/components/InputsHero/DatePicker";
import SelectAeronave from "@/components/InputsHero/SelectAeronaveHero";
import SelectPasajeros from "@/components/InputsHero/SelectPasajerosHero";
import SearchBtn from "@/components/InputsHero/SearchBtn";
import RadioIdaYVuelta from "@/components/InputsHero/RadioIdaYVuelta";

import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "Home",
  components: {
    Cookies,
    Boton,
    CardProximoVuelo,
    CardExperiencias,
    LoginButton,
    Origen,
    InputDestino,
    VerTodasBtn,
    DatePicker,
    SelectAeronave,
    SelectPasajeros,
    SearchBtn,
    RadioIdaYVuelta,
    Contacto,
    Footer,
    Dialog,
    Banner,
    Slider,
    PasoAPaso,
    Cotizacion,
    VideoCoorporativo,
    CarouselPromociones,
    Buscador,
  },
  data() {
    return {
      busquedaInput: "",
      tabs: 0,
      videoHeroSection:
        // "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Fvideos%2FHelicopter%20-%201434.mp4?alt=media&token=6d4b2fd9-0352-44c6-bd0c-d150903f669d",
        "/banner.mp4",
    };
  },
  computed: {
    mobile() {
      return (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "xs"
      );
    },

    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState(["colors", "logos", "icons"]),
    ...mapState("Vuelos", ["vuelos"]),
    ...mapGetters("Experiencias", ["getExperienciasAMostrar"]),
    experiencias() {
      return this.getExperienciasAMostrar;
    },
    ...mapState("Auth", ["usuario"]),
    ...mapState("Buscador", ["busqueda"]),
    ...mapGetters("Experiencias", [
      "proximasExperiencias",
      "experienciasTipoReserva",
      "viajesEmptyLegs",
      "promocionXPlus1",
    ]),
    ...mapGetters("Vuelos", ["proximosVuelos", "soloDestinos"]),
    ...mapGetters("Experiencias", ["soloDestinosExperiencias"]),
    ...mapGetters("Buscador", ["getVuelos"]),
    experienciasAMostrar() {
      const experienciasAMostrar = this.proximasExperiencias.slice(0, 6);

      return experienciasAMostrar;
    },
    pestañaTipoDeViaje() {
      return this.busqueda.tipoDeViaje === "Experiencias" ? 0 : 1;
    },
    experienciasEconomicas() {
      return this.proximasExperiencias.filter(
        (exp) => exp.data.precio < 300000
      );
    },
    experienciasBlackFlyDay() {
      return this.proximasExperiencias.filter((exp) => exp.data.hummingDay);
    },
    experienciasItau() {
      return this.proximasExperiencias.filter(
        (exp) => exp.data.itauExperiencia
      );
    },
    experienciasCaras() {
      return this.proximasExperiencias.filter(
        (exp) => exp.data.precio >= 300000
      );
    },
    experienciasCompartidas() {
      return this.experiencias
        .filter((exp) => !exp.data.privado)
        .sort((a, b) => a.data.precioEnDolares - b.data.precioEnDolares);
    },
    experienciasPrivadas() {
      return this.experiencias
        .filter((exp) => {
          return exp.data.privado;
        })
        .sort((a, b) => a.data.precioEnDolares - b.data.precioEnDolares);
    },
  },
  methods: {
    ...mapMutations("Buscador", ["BUSQUEDA_A_STORE", "SET_BUSQUEDA_PARAMS"]),
    realizarBusqueda() {
      this.BUSQUEDA_A_STORE(this.busquedaInput);
      this.$router.push({ name: "TipoDeViaje" });
      this.busquedaInput = "";
    },
    setTipoDeViaje() {
      let tipoViaje = this.tabs === 0 ? "Experiencias" : "Vuelos";
      this.SET_BUSQUEDA_PARAMS({ tipoDeViaje: tipoViaje });
    },
  },
  mounted() {
    this.tabs = this.pestañaTipoDeViaje;
  },
  title() {
    return `HummingBird - Bringing the sky closer`;
  },
};
</script>

<style lang="scss">
#Corporativo-promociones {
  display: grid;
  grid-template-columns: 1fr 600px;
  gap: 19px;
}

@media (max-width: 480px) {
  #Corporativo-promociones {
    grid-template-columns: 1fr;
  }
  #Corporativo-promociones .promociones {
  }
}

.colorItau {
  color: #ff680a;
}
@keyframes golden_Text {
  from {
    color: #f8f34f;
  }
  to {
    color: #645dc7;
  }
}

.set_golden_Text {
  animation-name: golden_Text;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.btn-experiencias-correccion {
  position: relative;
  top: 0;
}
@media (min-width: 1264px) {
  .btn-experiencias-correccion {
    top: -70px;
  }
}
.z-index {
  z-index: 1;
  position: relative;
}
.heroSection {
  // background-image: url("https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Fpasajero%2Fimagen%202.png?alt=media&token=bed21415-e112-4e43-b2f3-c93e4fff492b");
  // background-size: cover;
  // background-position: center;
  height: 500px;
  margin-bottom: -40px;
  padding-top: 80px;
  transition: all 0.5s;
  .relative {
    position: relative;
  }
  video {
    position: absolute;
    top: -260px;
    @media (min-width: 1280px) {
      width: 100%;
    }
    @media (max-width: 1600px) {
      top: -100px;
    }

    @media (max-width: 600px) {
      top: -150px;
      left: -150px;
    }
  }

  &__logo {
    margin-bottom: 2rem;
    width: 180px;
  }
  &__title {
    color: $main-white;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 3px #000;
  }
  &__radioGroup {
    label {
      color: $main-white !important;
      font-weight: 700;
    }
  }
  &__search {
    font-size: 1.125em;
    position: relative;
    &__input {
      input {
        padding-right: 64px;
      }
    }
    &__btn {
      height: 48px !important;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.proximosVuelos,
.descubre {
  background-color: $gray90;
  transition: background-color 2s;
  &__subtitle {
    width: 70%;
  }
}

.descubre {
  &__slider {
    overflow-y: hidden;
    touch-action: manipulation;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 32px;
    &::-webkit-scrollbar {
      display: none;
    }
    .cardExperiencia {
      margin-right: 24px;
    }
  }
}

@media (min-width: 600px) {
  .heroSection {
    background-position: 0 100%;
  }
}

@media (min-width: 900px) {
  .heroSection {
    // background-image: url("https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Fpasajero%2Fimagen%202%20(1).png?alt=media&token=9317603e-eecd-470a-8e32-e378e7f724e1");
    height: fit-content;
    padding-bottom: 47px;
    &__logo {
      width: 212px;
    }
    &__tabs {
      padding-bottom: 24px;
      .v-tabs-slider {
        width: 50%;
        margin: 0 auto;
      }
      .v-tab {
        text-transform: initial;
        font-size: 18px;
        font-weight: 300;
      }
      .v-tabs-bar {
        background-color: transparent !important;
      }
    }
  }
  .proximosVuelos,
  .descubre {
    transition: all 0.5s ease;
    &__title,
    &__subtitle {
      text-align: left;
    }
    &__title {
      font-size: 2.25em;
    }
    &__subtitle {
      width: initial;
      font-size: 1.5em;
      line-height: 32px;
    }
  }
}

.hide {
  visibility: hidden;
}
</style>
