const heroSection = {
    dondeViajar: "¿A Dónde quieres viajar?",
    buscador: {
        experiencias: "Experiencias",
        vuelos: "Vuelos",
        origen: "Origen",
        destino: "Destino",
        fecha: "Fecha",
        aeronave: "Aeronave",
        pasajeros: "Pasajeros",
        desdeDonde: "¿Desde Dónde?",
        ingresaElDestino: "Ingresa el destino",
        seleccionaTusFechas: "Selecciona tus fechas",
        seleccionaTuAeronave: "Selecciona tu aeronave",

    }
}


export default heroSection