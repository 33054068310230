<template>
  <section id="BottomMenu">
    <div class="menuWrapper">
      <router-link :to="{ name: 'Experiencias' }">
        <div>
          <img
            :src="icons.purple.helicopter.straight"
            alt="Icono helicoptero"
          />
          <h5>
            {{ lenguages[idioma].nav.experiencias || "Experiencias" }}
          </h5>
        </div>
      </router-link>
      <!-- <router-link :to="{ name: 'TipoDeViaje' }">
        <div>
          <img :src="icons.purple.magnify" alt="Icono buscar" />
          <h5>
            {{ lenguages[idioma].nav.buscarVuelos || "Buscar vuelos" }}
          </h5>
        </div>
      </router-link> -->
      <router-link to="/login" v-if="usuario === null">
        <div>
          <img :src="icons.purple.user" alt="Icono usuario" />
          <h5>{{ lenguages[idioma].nav.ingresa || "Iniciar sesión" }}</h5>
        </div>
      </router-link>
      <router-link v-else :to="{ name: 'MiPerfil' }">
        <div>
          <img
            :src="usuario.photoURL"
            alt="Icono usuario"
            class="mx-auto"
            width="30"
            height="30"
          />
          <h5>Mi perfil</h5>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "BottomMenu",
  computed: {
    ...mapState("Auth", ["usuario"]),
    ...mapState(["icons"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
  methods: {
    ...mapActions("Auth", ["logout"]),
  },
};
</script>

<style lang="scss">
#BottomMenu {
  align-items: center;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-name: scaleYFromBottom;
  animation-timing-function: ease;
  animation-fill-mode: backwards;
  background-color: $main-white;
  bottom: 0;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 83px;
  margin-top: 83px;
  position: fixed;
  width: 100%;
  z-index: 3;
  .menuWrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
      img {
        height: 30px;
        color: $main-purple;
        border-radius: 50%;
      }
    }
  }
  a {
    color: inherit;
  }
}

@media (min-width: 600px) {
  #BottomMenu {
    display: none;
  }
}
</style>
