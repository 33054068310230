<template>
  <a
    @click="
      gtag_report_conversion(
        `https://wa.me/${contacto.whatsapp}?text=${mensaje}`
      )
    "
    target="_blank"
  >
    <div id="WhatsappFloatButton">
      <img :src="logos.whatsapp" alt="" />
    </div>
  </a>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WhatsappFloatButton",
  data() {
    return {
      mensaje: "Hola, me gustaría consultar por ",
    };
  },
  computed: {
    ...mapState(["logos", "contacto"]),
  },
  methods: {
    gtag_report_conversion(url) {
      
      const callback = function() {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      gtag("event", "conversion", {
        send_to: "AW-10793117570/G0HMCMzGwoMDEILPx5oo",
        event_callback: callback,
      });

      setTimeout( () => {
        window.location = url
      },600)
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#WhatsappFloatButton {
  animation-delay: 3s;
  animation-duration: 1s;
  animation-name: scale;
  animation-timing-function: ease;
  animation-fill-mode: backwards;
  align-items: center;
  background-color: $main-white;
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 70px;
  justify-content: center;
  position: fixed;
  right: 15px;
  transition: background-color 0.2s;
  width: 70px;
  z-index: 3;
  img {
    width: 52%;
  }
  &:hover {
    background-color: $second-white;
    cursor: pointer;
  }
}

@media (min-width: 600px) {
  #WhatsappFloatButton {
    bottom: 45px;
    right: 30px;
  }
}
</style>
