import firebase from "firebase";

export default {
  namespaced: true,
  state: {
    operaciones: [],
  },
  mutations: {
    GET_OPERACIONES(state) {
      firebase
        .firestore()
        .collection("operaciones")
        .onSnapshot((snapshot) => {
          let operaciones = [];
          snapshot.forEach((aerodromo) => {
            operaciones.push({ id: aerodromo.id, data: aerodromo.data() });
          });
          state.operaciones = operaciones;
        });
    },
  },
};
