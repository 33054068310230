<template>
  <v-radio-group
    row
    dark
    class="heroSection__radioGroup d-none d-md-block"
    v-model="tipoDeVueloSeleccionado"
    @change="soloIdaOIdaYVuelta"
  >
    <v-radio label="Solo ida" value="Solo ida"></v-radio>
    <v-radio label="Ida y vuelta" value="Ida y vuelta"></v-radio>
  </v-radio-group>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "RadioIdaYVuelta",
  data() {
    return {
      tipoDeVueloSeleccionado: 'Solo ida'
    };
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    soloIdaOIdaYVuelta() {
      this.SET_BUSQUEDA_PARAMS({tipoDeVuelo : this.tipoDeVueloSeleccionado});
    },
  },
  computed: {
    ...mapState('Buscador' , ['busqueda'])
  },
  mounted(){
    this.tipoDeVueloSeleccionado = this.busqueda.tipoDeVuelo
  }
};
</script>

<style>
</style>