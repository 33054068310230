const contacto = {
    titulo: "Any questions?",
    subTitulo: "¡Envíanos un mensaje!",
    nombreYApellido: 'Full Name',
    correo: 'Email',
    mensaje: 'Message',
    botonEnviar: "Send"
}


export default contacto