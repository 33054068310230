<template>
  <v-btn
    class="rounded-0 elevation-0 d-flex align-center justify-center btnComponent"
    :style="style"
    :width="width"
    :height="height"
    :color="color"
    :dark="dark"
    :disabled="deshabilitado"
  >
    <span v-if="texto">{{ texto }}</span>
    <img v-if="icono" :src="icono" alt="ícono botón" class="pl-2" />
  </v-btn>
</template>

<script>
export default {
  props: {
    texto: String,
    icono: String,
    width: { type: [Number, String] },
    height: [Number, String],
    borderRadius: { type: String, default: "10px" },
    color: { type: String, default: "#645DC7" },
    deshabilitar: Boolean,
    fontSize: { type: String, default: "14px" },
    colorTexto: { type: String, default: "#fff" },
  },
  data() {
    return {
      dark: true,
    };
  },
  computed: {
    style() {
      return {
        borderRadius: `${this.borderRadius} !important`,
        fontSize: `${this.fontSize} !important`,
        color: !this.deshabilitado ? `${this.colorTexto} !important` : "#000",
      };
    },
    deshabilitado() {
      if (this.deshabilitar) {
        this.dark = false;
        return true;
      } else {
        this.dark = true;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btnComponent {
  text-transform: initial;
  letter-spacing: initial;
}
</style>
