<template>
  <v-app>
    <Loader v-if="loading || authLoading" width="100vw" height="100vh" />
    <div v-else>
      <MainNavbar v-show="mostrarNavbar" />
      <BackBar v-if="mostrarBarraAtras" />
      <v-main>
        <transition name="fadeIn" mode="out-in">
          <router-view />
        </transition>
      </v-main>
      <WhatsappFloatButton v-if="!noMostrarBotonWhatsapp" />
      <!-- <BottomMenu v-if="!noMostrarBottomMenu" /> -->
    </div>
  </v-app>
</template>

<script>
import Loader from "@/components/Loader";
import BottomMenu from "@/components/BottomMenu";
import WhatsappFloatButton from "@/components/WhatsappFloatButton";
import MainNavbar from "@/components/MainNavbar";
import BackBar from "@/components/BackBar";
import { mapActions, mapMutations, mapState } from "vuex";
import firebase from "firebase";
export default {
  name: "App",
  components: {
    Loader,
    BottomMenu,
    WhatsappFloatButton,
    BackBar,
    MainNavbar,
  },
  data: () => ({
    scrollY: 0,
  }),
  methods: {
    ...mapActions("Vuelos", ["getVuelos"]),
    ...mapActions("Experiencias", ["getExperiencias", "getDolarPrice"]),
    ...mapActions("Operadores", ["getOperadores"]),
    ...mapActions("Aerodromos", ["getAerodromos"]),
    ...mapActions("Auth", ["getDataFromRedirect", "autoLogin"]),
    ...mapActions("Comentarios", ["getComentarios"]),
    ...mapMutations("Ventas", ["PASAJEROS_ANOTADOS"]),
    async getLoginData() {
      try {
        const data = await this.getDataFromRedirect();

        if (data && this.usuario != null && this.$route.name === "Login") {
          this.$router.push("/");
        }
      } catch (error) {
        console.log("desde App", error);
      }
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("Auth", ["usuario", "authLoading"]),
    noMostrarBottomMenu() {
      return (
        this.$route.name == "Login" ||
        this.$route.name === "VuelosEscogidos" ||
        this.$route.name === "Pago" ||
        this.$route.name === "CompraRealizada" ||
        this.$route.name === "CheckIn" ||
        this.$route.path.includes("/admin")
      );
    },
    noMostrarBotonWhatsapp() {
      return (
        this.$route.name == "Login" ||
        this.$route.name == "AgregarVuelo" ||
        this.$route.name === "VuelosEscogidos" ||
        this.$route.name === "TerminosYCondiciones" ||
        this.$route.name === "Pago" ||
        this.$route.path.includes("/admin") ||
        this.$route.name === "MiVueloDetalle" ||
        this.$route.name === "Registrate"
      );
    },
    mostrarNavbar() {
      const ruta = this.$route.name;
      return (
        ruta === "VuelosEscogidos" ||
        (ruta === "TerminosYCondiciones" &&
          this.$vuetify.breakpoint.width >= 960) ||
        ruta === "TerminosYCondicionesCompra" ||
        (ruta === "Pago" && this.$vuetify.breakpoint.width >= 960) ||
        (ruta === "CompraRealizada" && this.$vuetify.breakpoint.width >= 960) ||
        ruta === "MiPerfil" ||
        ruta === "CheckIn" ||
        ruta === "MiVueloDetalle" ||
        ruta === "Detalle" ||
        ruta === "Vuelos" ||
        ruta === "Experiencias" ||
        ruta === "Seguridad" ||
        ruta === "Operadores-Form" ||
        ruta === "Noticias" ||
        ruta === "Cotizacion" ||
        ruta === "Aerodromos" ||
        ruta === "FAQ" ||
        (ruta === "Error" && this.$vuetify.breakpoint.width >= 960) ||
        (ruta == "Home" && this.scrollY > 200)
      );
    },
    mostrarBarraAtras() {
      return (
        this.$route.name === "TerminosYCondiciones" ||
        this.$route.name === "Pago"
      );
    },
  },
  async created() {
    await this.getExperiencias();
    await this.getVuelos();
    await this.autoLogin();
    await this.getOperadores();
    await this.getComentarios();
    await this.getAerodromos();
  },
  async mounted() {
    window.addEventListener("scroll", (e) => {
      this.scrollY = window.scrollY;
    });
    await this.getLoginData();
    await this.getDolarPrice();

    firebase.analytics().logEvent("select_content", {
      content_type: "page_view",
      content_id: "1",
      items: [{ name: "test" }],
    });
  },
};
</script>

<style lang="scss">
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  a {
    color: inherit !important;
  }
}

html {
  background: $gray-background;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 0.4s;
}

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}
</style>
