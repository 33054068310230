const procesarDatosDeBusqueda = (busqueda) => {
    busqueda.aeronave = busqueda.aeronave.ida
    busqueda.fecha = busqueda.fechas.ida
    delete busqueda.fechas
    delete busqueda.tipoDeServicio
    delete busqueda.tipoDeVuelo
    delete busqueda.tipoDeViaje
    delete busqueda.fecha
    busqueda.aeronave = busqueda.aeronave !== "Todo tipo de aeronave" ? busqueda.aeronave.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '';
    for (let key in busqueda) {
        if (!busqueda[key]) delete busqueda[key]
    }
    return busqueda
}

const tieneAsientosSuficientes = ({ capacidad, ventas }, busqueda) => {
    const asientosDisponibles = capacidad - ventas
    const asientosSuficientes = asientosDisponibles >= busqueda.pasajeros
    return asientosSuficientes
}

const cumpleConLosParametrosDeBusqueda = (busqueda, viaje) => {
    try {
        const busquedaSinPasajeros = { ...busqueda }
        delete busquedaSinPasajeros.pasajeros
        let cumpleConLosParametrosDeBusqueda = true
        for (let key in busquedaSinPasajeros) {
            if (key == "origen" || key == "destino") {
                if (!viaje[key].toLowerCase().includes(busquedaSinPasajeros[key].toLowerCase())) {
                    cumpleConLosParametrosDeBusqueda = false
                }
            }
            else if (busquedaSinPasajeros[key].toLowerCase() !== viaje[key].toLowerCase()) {
                cumpleConLosParametrosDeBusqueda = false
            }
        }
        return cumpleConLosParametrosDeBusqueda
    } catch (e) {
        console.log(e);
    }
}

const revisarRangoDeFechas = (cantDeDias, fecha) => {
    // ...
}

const viajesFiltradosPorBusqueda = ({ viajes, busqueda }) => {
    viajes = viajes.filter(({ data: viaje }) => {
        const asientosSuficientes = tieneAsientosSuficientes(viaje, busqueda)
        const cumpleConTodosLosParametrosDeBusqueda = cumpleConLosParametrosDeBusqueda({ ...busqueda }, viaje)
        return asientosSuficientes && cumpleConTodosLosParametrosDeBusqueda
    })
    return viajes
}

const getVuelosFunction = ({ busqueda, experiencias, vuelos }) => {

    const { tipoDeViaje } = busqueda
    busqueda = procesarDatosDeBusqueda({ ...busqueda })
    let viajes;
    if (tipoDeViaje == "Experiencias") viajes = experiencias
    else if (tipoDeViaje == "Vuelos") viajes = vuelos
    let viajesFiltrados = viajesFiltradosPorBusqueda({ viajes, busqueda })
    return viajesFiltrados
}

export { getVuelosFunction }