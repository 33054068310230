const experienciasHome = {
    economicas: {
        titulo: "Economic Experiences",
        subTitulo: "We select these offers for you, with surprising prices.",
    },
    premium: {
        titulo: "Premium Experiences",
        subTitulo: "Live unforgettable experiences.",
    },
    compartidas: {
        titulo: "Shared experiences",
        subTitulo: "Fly in a group at a lower cost",
    },

    privadas: {
        titulo: "Private Experiences",
        subTitulo: "Fly in an exclusive aircraft for you and your group",
    },
    itau: {
        titulo: "ITAU Experiences",
        subTitulo: "Exclusive for ITAU bank clients"
    },
    botonVerMas: "See all experiences",

}


export default experienciasHome