const login ={
    iniciaSesion: "Log in",
    yaTienesCuenta: "DO YOU ALREADY HAVE AN ACCOUNT?",
    noTienesCuenta: "YOU DO NOT HAVE AN ACCOUNT?",
    registrate: 'SIGN UP',
    correoElectronico: 'Email',
    contrasena: 'Password',
    iniciarSesion: 'Sign in',
    continuarConFacebook: "Sign up With Facebook",
    continuarConGoogle: "Sign up With Google",
}

export default login