import firebase from 'firebase'
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        comentarios: []
    },
    mutations: {
        GET_COMENTARIOS(state, comentarios) {
            state.comentarios = comentarios
        }
    },
    actions: {
        async addCommentary({ }, comentario) {
            try {
                let subirComentario = await firebase
                    .firestore()
                    .collection('comentarios')
                    .add(comentario);

                Vue.$toast.open({
                    message: "Tu evaluación ha sido registrada",
                    position: "top",
                    duration: 3000,
                });
                return true

            } catch (error) {
                console.log(error);
                Vue.$toast.open({
                    message: error.message,
                    position: "error",
                    duration: 3000,
                });
            }
        },
        async evaluarOperador({ dispatch }, evaluacion) {
            try {
                await firebase
                    .firestore()
                    .collection('comentarios')
                    .add(evaluacion);

                const operatorRef = firebase.firestore().collection('operadores').doc(evaluacion.operador.id);
                const getOperatorData = await operatorRef.get();
                const documentoOperador = await getOperatorData.data()
                let cantidadEvaluaciones = documentoOperador.calificaciones.length
                const ratingActual = documentoOperador.rating
                const arregloCalificaciones = documentoOperador.calificaciones
                let ratings = arregloCalificaciones.map(c => c.rating)
                //Agregando nuevo rating
                ratings.push(evaluacion.rating)
                cantidadEvaluaciones++

                const sumaDeRatings = ratings.reduce((a, c) => a + c)
                const promedioRatings = sumaDeRatings / cantidadEvaluaciones



                await operatorRef.update({
                    calificaciones: firebase.firestore.FieldValue.arrayUnion(evaluacion),
                    rating: cantidadEvaluaciones === 0 ? evaluacion.rating : promedioRatings
                })

                Vue.$toast.open({
                    message: "Tu evaluación ha sido registrada",
                    position: "top",
                    duration: 3000,
                });

                return true
            } catch (error) {
                Vue.$toast.open({
                    message: error.message,
                    position: "error",
                    duration: 3000,
                });
                return false
            }
        },
        getComentarios({ commit }) {
            try {
                let comentariosRef = firebase.firestore().collection('comentarios')
                comentariosRef.onSnapshot(snap => {
                    let comentarios = []
                    snap.forEach(doc => {
                        comentarios.push({
                            data: doc.data(),
                            id: doc.id
                        })
                    })
                    commit('GET_COMENTARIOS', comentarios)
                })
            } catch (error) {
                console.log(error);
            }
        }
    },
    getters: {
    }
}
