<template>
  <div class="VerTodas d-flex align-center justify-center">
    <span>Ver todas las experiencias</span> <img :src="icons.green.arrowToRight"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "VerTodasBtn",
  computed: {
    ...mapState(['icons'])
  },
};
</script>

<style lang="scss" scoped>
.VerTodas {
  background-color: $main-purple;
  border-radius: 50px;
  color: $main-white;
  font-size: 0.9375em;
  font-weight: 600;
  height: 55px;
  padding: 11px 0;
  text-align: center;
  width: 255px;
  &:hover {
    cursor: pointer;
  }
  img{
      padding-left: 17px;
  }
}
</style>