const miPerfil = {
    editarMisDatos: "Editar mis datos", 
    cerrarSesion: "Cerrar sesión",
    misProximasExperiencias: "Mis próximas experiencias",
    visualiza: "Visualiza todo lo que necesites de tus vuelos",
    noTienesExperienciasProximas: "No tienes experiencias próximas",
    verTodasLasExperiencias: "Ver todas las experiencias",
    tienesEnMente: "¿Tienes en mente tu próximo vuelo?",
    teRecomendamos: "Te recomendamos estas experiencias",
    misViajesAnteriores: "Mis viajes anteriores",
    misExperienciasFavoritas: "Experiencias favoritas",
}

export default miPerfil