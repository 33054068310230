import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/buscar",
    name: "BusquedaMobile",
    component: () =>
      import(
        /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/Buscar.vue"
      ),
    children: [{
      path: "tipo-de-viaje",
      name: "TipoDeViaje",
      meta: {
        index: 0,
      },
      component: () =>
        import(
            /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/TipoDeViaje.vue"
        ),
    },
    {
      path: "ciudad-de-origen",
      name: "CiudadDeOrigen",
      meta: {
        index: 1,
      },
      component: () =>
        import(
            /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/CiudadDeOrigen.vue"
        ),
    },

    {
      path: "selecciona-destino",
      name: "SeleccionaDestino",
      meta: {
        index: 2,
      },
      component: () =>
        import(
            /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/SeleccionaDestino.vue"
        ),
    },
    {
      path: "tipo-de-vuelo",
      name: "TipoDeVuelo",
      meta: {
        index: 3,
      },
      component: () =>
        import(
            /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/TipoDeVuelo.vue"
        ),
    },
    {
      path: "selecciona-fecha",
      name: "SeleccionaFecha",
      meta: {
        index: 4,
      },
      component: () =>
        import(
            /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/SeleccionaFecha.vue"
        ),
    },
    {
      path: "tipo-de-aeronave",
      name: "TipoDeAeronave",
      meta: {
        index: 5,
      },
      component: () =>
        import(
            /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/TipoDeAeronave.vue"
        ),
    },
    {
      path: "cantidad-de-pasajeros",
      name: "CantidadPasajeros",
      meta: {
        index: 6,
      },
      component: () =>
        import(
            /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/CantidadPasajeros.vue"
        ),
    },
    {
      path: "tipo-de-servicio",
      name: "TipoDeServicio",
      meta: {
        index: 7,
      },
      component: () =>
        import(
            /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/TipoDeServicio.vue"
        ),
    },
    {
      path: "criterios-busqueda",
      name: "CriteriosBusqueda",
      meta: {
        index: 8,
      },
      component: () =>
        import(
            /* webpackChunkName: "buscar" */ "../views/BuscadorMobile/CriteriosBusqueda.vue"
        ),
    },
    ],
  },
  {
    path: "/resultados-busqueda",
    name: "ResultadosBusqueda",
    component: () =>
      import(
        /* webpackChunkName: "resultados-busqueda" */ "../views/ResultadosBusqueda.vue"
      ),
  },
  {
    path: "/vuelos-escogidos",
    name: "VuelosEscogidos",
    component: () =>
      import(
        /* webpackChunkName: "vuelos-escogidos" */ "../views/VuelosEscogidos.vue"
      ),
  },
  {
    path: "/terminos-y-condiciones",
    name: "TerminosYCondiciones",
    component: () =>
      import(
        /* webpackChunkName: "terminos-y-condiciones" */ "../views/TerminosYCondiciones.vue"
      ),
  },
  {
    path: "/terminos-y-condiciones/compra",
    name: "TerminosYCondicionesCompra",
    component: () =>
      import(
        /* webpackChunkName: "terminos-y-condiciones" */ "../views/TerminosYCondicionesCompra.vue"
      ),
  },
  {
    path: "/pago",
    name: "Pago",
    component: () => import(/* webpackChunkName: "pago" */ "../views/Pago.vue"),
  },
  {
    path: "/compra-realizada/:id",
    name: "CompraRealizada",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "compra-realizada" */ "../views/CompraRealizada.vue"
      ),
  },
  {
    path: "/check-in/:idCompra/:idVuelo",
    name: "CheckIn",
    props: true,
    meta: {
      login: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "check-in" */ "../views/CheckIn.vue"
      ),
  },
  {
    path: "/viajes/:tipoDeViaje/:id",
    name: "Detalle",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "check-in" */ "../views/Detalle.vue"
      ),
  },
  {
    path: "/mis-viajes/:idCompra/:id",
    name: "MiVueloDetalle",
    props: true,
    meta: {
      login: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "check-in" */ "../views/MiVueloDetalle.vue"
      ),
  },
  {
    path: "/vuelos",
    name: "Vuelos",
    component: () =>
      import(/* webpackChunkName: "vuelos" */ "../views/Vuelos.vue"),
  },
  {
    path: "/experiencias",
    name: "Experiencias",
    component: () =>
      import(/* webpackChunkName: "experiencias" */ "../views/Experiencias.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/registrate",
    name: "Registrate",
    component: () =>
      import(/* webpackChunkName: "Registrate" */ "../views/Registrate.vue"),
  },
  {
    path: "/mi-perfil",
    name: "MiPerfil",
    meta: {
      login: true,
    },
    component: () =>
      import(/* webpackChunkName: "mi-perfil" */ "../views/MiPerfil.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(
      /* webpackChunkName: "AgregarVuelo" */ "@/views/Admin/Admin.vue"
      ),
    meta: {
      login: true,
      rolAdmin: true
    },
    children: [
      {
        path: "aerodromos",
        name: "AerodromosAdmin",
        component: () =>
          import(
        /* webpackChunkName: "AgregarVuelo" */ "@/views/Admin/Aerodromos.vue"
          ),
      },
      {
        path: "operaciones",
        name: "Operaciones",
        component: () =>
          import(
        /* webpackChunkName: "AgregarVuelo" */ "@/views/Admin/Operaciones/Operaciones.vue"
          ),
      },
      {
        path: "agregar-vuelo",
        name: "AgregarVuelo",
        component: () =>
          import(
        /* webpackChunkName: "AgregarVuelo" */ "@/views/Admin/AgregarVuelo.vue"
          ),
      },
      {
        path: "editar-experiencia/:id",
        name: "EditarExperiencia",
        props: true,
        component: () =>
          import(
        /* webpackChunkName: "AgregarVuelo" */ "@/views/Admin/EditarExperiencia.vue"
          ),
      },
      {
        path: "noticias",
        name: "Noticias-Admin",
        component: () =>
          import(
        /* webpackChunkName: "AgregarExperiencia" */ "@/views/Admin/Noticias.vue"
          ),
      },
      {
        path: "subscriptores",
        name: "Subscriptores",
        component: () =>
          import(
        /* webpackChunkName: "AgregarExperiencia" */ "@/views/Admin/Subscriptores.vue"
          ),
      },
      {
        path: "agregar-experiencia",
        name: "AgregarExperiencia",
        component: () =>
          import(
        /* webpackChunkName: "AgregarExperiencia" */ "@/views/Admin/AgregarExperiencia.vue"
          ),
      },
      {
        path: "agregar-operador",
        name: "AgregarOperador",
        component: () =>
          import(
        /* webpackChunkName: "AgregarOperador" */ "@/views/Admin/AgregarOperador.vue"
          ),
      },
      {
        path: "viajes",
        name: "Viajes",
        component: () =>
          import(
        /* webpackChunkName: "Viajes" */ "@/views/Admin/Viajes.vue"
          ),
      },
      {
        path: "ventas",
        name: "Ventas",
        component: () =>
          import(
        /* webpackChunkName: "Ventas" */ "@/views/Admin/Ventas.vue"
          ),
        children: [
          {
            path: ':idCompra',
            name: 'DetalleCompra',
            props: true,
            component: () =>
              import(
        /* webpackChunkName: "DetalleCompra" */ "@/views/Admin/DetalleCompra.vue"
              ),
          }
        ]
      },

      {
        path: "busquedas",
        name: "Busquedas",
        component: () =>
          import(
        /* webpackChunkName: "Viajes" */ "@/views/Admin/Busquedas.vue"
          ),
      },


      {
        path: "cotizaciones",
        name: "Cotizaciones",
        component: () =>
          import(
        /* webpackChunkName: "Viajes" */ "@/views/Admin/Cotizaciones.vue"
          ),
      },

      {
        path: "usuarios",
        name: "Usuarios",
        component: () =>
          import(
        /* webpackChunkName: "Viajes" */ "@/views/Admin/Usuarios.vue"
          ),
      },
      {
        path: "calificaciones",
        name: "Calificaciones",
        component: () =>
          import(
        /* webpackChunkName: "Viajes" */ "@/views/Admin/Calificaciones.vue"
          ),
      },


    ],
  },
  {
    path: "/calificaciones/:idExperiencia",
    name: "Calificacion",
    component: () =>
      import(
    /* webpackChunkName: "Calificacion" */ "@/views/Calificacion.vue"
      ),
  },
  {
    path: "/seguridad-operacional",
    name: "Seguridad",
    component: () =>
      import(
        /* webpackChunkName: "Ventas" */ "@/views/SeguridadOperacional.vue"
      ),
  },
  {
    path: "/operadores",
    name: "Operadores-Form",
    component: () =>
      import(
        /* webpackChunkName: "Ventas" */ "@/views/OperadoresForm.vue"
      ),
  },

  {
    path: "/noticias",
    name: "Noticias",
    component: () =>
      import(
        /* webpackChunkName: "Ventas" */ "@/views/Noticias.vue"
      ),
  },

  {
    path: "/cotizacion",
    name: "Cotizacion",
    component: () =>
      import(
        /* webpackChunkName: "Ventas" */ "@/views/Cotizacion.vue"
      ),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () =>
      import(
        /* webpackChunkName: "Ventas" */ "@/views/FAQ.vue"
      ),
  },
  {
    path: "/aerodromos",
    name: "Aerodromos",
    component: () =>
      import(
        /* webpackChunkName: "Ventas" */ "@/views/Aerodromos.vue"
      ),
  },
  {
    path: "/*",
    name: "Error",
    component: () =>
      import(
        /* webpackChunkName: "Ventas" */ "@/views/Error.vue"
      ),
  },


];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const user = store.state.Auth.usuario;
  const rolAdmin = user ? user.rol === 'admin' : false;
  const authRequired = to.matched.some(route => route.meta.login);
  const adminRequired = to.matched.some(route => route.meta.rolAdmin);
  if (!user && authRequired) {
    next({ name: 'Login' })
  }
  else if (!user && authRequired && adminRequired) {
    next({ name: 'Login' })
  }
  else if (user && adminRequired && !rolAdmin) {
    next('/')
  }
  else {
    next()
  }

  next()

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 300);
  next()
});

export default router;
