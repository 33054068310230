import firebase from 'firebase'
import moment from "moment";

export default {
    namespaced: true,
    state: {
        vuelos: []
    },
    mutations: {
        GET_VUELOS(state, vuelos) {
            state.vuelos = vuelos
        }
    },
    actions: {
        async getVuelos({ commit }) {
            try {
                await firebase
                    .firestore()
                    .collection('vuelos')
                    .onSnapshot(snapshot => {
                        let vuelos = []
                        snapshot.forEach(vuelo => {
                            vuelos.push({
                                data: vuelo.data(),
                                id: vuelo.id
                            })
                        })
                        commit('GET_VUELOS', vuelos)
                    })
            } catch (error) {
                alert(error)
            }
        },
        async agregarVuelo({ commit }, vuelo) {
            try {
                await firebase.firestore().collection('vuelos').add(vuelo);
                alert(`Vuelo ${vuelo.origen} - ${vuelo.destino} el ${vuelo.fecha} agregado`)
                return true
            } catch (error) {
                alert(error)
                return false
            }
        }
    },
    getters: {
        proximosVuelos: (state) => {
            const ordenarPorFecha = (a, b) => {
                if (a.data.fecha > b.data.fecha) {
                    return 1;
                }
                if (a.data.fecha < b.data.fecha) {
                    return -1;
                }
                return 0;
            };

            let vuelosOrdenadosPorFecha = state.vuelos.sort(ordenarPorFecha);
            let sinVuelosPasados = vuelosOrdenadosPorFecha.filter(v => {
                return moment(`${v.data.fecha} ${v.data.horaDespegue}`).isSameOrAfter(moment().add(2, 'h'))
                && (v.data.capacidad - v.data.ventas) > 0

            })

            return sinVuelosPasados.slice(0, 9)
        },
        destinosVuelosYVentas: (state) => {

            let vuelos = state.vuelos || []
            let destinos = [];

            const ordernarPorVentas = (a, b) => {
                if (a.ventas > b.ventas) {
                    return 1;
                }
                if (a.ventas < b.ventas) {
                    return -1;
                }
                return 0;
            };

            vuelos.forEach(vuelo => {
                destinos.push({
                    destino: vuelo.data.destino,
                    ventas: vuelo.data.ventas
                })
            })

            let destinosSinRepeticion = destinos.reduce((acu, act) => {
                const destinoRepetido = acu.find(el => el.destino === act.destino);

                if (destinoRepetido) {
                    return acu.map(el => {
                        if (el.destino === act.destino) {
                            return {
                                ...el,
                                ventas: el.ventas + act.ventas
                            }
                        }
                        return el;
                    })
                }
                return [...acu, act];
            }, [])

            let destinosOrdenadosPorVentas = destinosSinRepeticion.sort(ordernarPorVentas).reverse()

            return destinosOrdenadosPorVentas;
        },
        soloDestinos: (state, getters) => {
            const getterDestinosYVentas = getters.destinosVuelosYVentas

            const destinos = getterDestinosYVentas.map(v => v.destino)
            return destinos
        },
        getVueloById: (state) => (id) => {
            const vuelos = state.vuelos
            const selected = vuelos.find((v) => v.id === id);

            return selected;
        },
        getVuelosByOperador: state => id => {
            return state.vuelos.filter(v => v.data.operador.id === id)
        }
    }
}