<template>
  <div id="Footer" class="py-3">
    <section class="container">
      <div class="contain">
        <div>
          <h3 class="mb-2">Humming Bird</h3>
          <h4>Email: contato@hummingbird.cl</h4>
          <h4 class="mb-3">
            {{ lenguages[idioma].footer.telefono || "Fono" }}

            : +56 9 5635 0990
          </h4>
          <routerLink to="/faq"
            ><h4 class="font-weight-bold">
              {{
                lenguages[idioma].footer.preguntasFrecuentes ||
                  "Preguntas frecuentes"
              }}
            </h4></routerLink
          >
          <routerLink to="/terminos-y-condiciones"
            ><h4 class="font-weight-bold">
              {{
                lenguages[idioma].footer.terminosYCondiciones ||
                  "Términos y condiciones"
              }}
            </h4></routerLink
          >
          <a target="_blank" :href="privacidad"
            ><h4 class="font-weight-bold">
              {{
                lenguages[idioma].footer.politicasYCookies ||
                  "Políticas de Privacidad y Cookies"
              }}
            </h4></a
          >
        </div>
        <div>
          <img
            :src="logos.hummingbird.main"
            alt="logo humming bird"
            class="logo"
          />
        </div>

        <div>
          <img width="150" :src="medios_de_pago" />
        </div>
        <div>
          <a href="https://www.instagram.com/hummingbird.aero/">
            <v-icon large color="white" class="mx-3">mdi-instagram </v-icon></a
          >
          <a href="https://www.facebook.com/hummingbird.aero">
            <v-icon large color="white" class="mx-3">mdi-facebook </v-icon>
          </a>
          <a href="https://www.youtube.com/channel/UCZcTxIw446Mms5nrI_tZi3w">
            <v-icon x-large color="white">mdi-youtube </v-icon>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
#Footer {
  background: #645dc7;
  color: white;
}

.logo {
  width: 100px;
}

.contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 600px) {
  #Footer {
    margin-bottom: 83px;
  }

  .contain {
    flex-direction: column;
    text-align: center;
  }
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "Footer",

  computed: {
    ...mapState(["colors", "logos"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
  },
  data() {
    return {
      privacidad:
        "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/Pol%C3%ADtica%20de%20Privacidad%20%26%20Cookies.docx.pdf?alt=media&token=0ed441cb-9aec-4e1e-a432-9b03f9998e70",
      medios_de_pago:
        "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2FimagenesDestinos%2Fmedios-de-pago.png?alt=media&token=8b330cd6-462f-4690-8d97-8ec9dc062c1a",
    };
  },
};
</script>
