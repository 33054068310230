const heroSection = {
    dondeViajar: "Where do you want to travel?",
    buscador: {
        experiencias: "Experiences",
        vuelos: "Flyes",
        origen: "Origin",
        destino: "Destination",
        fecha: "Date",
        aeronave: "Aircraft",
        pasajeros: "Passengers",
        desdeDonde: "From where?",
        ingresaElDestino: "Enter the destination",
        seleccionaTusFechas: "Select your dates",
        seleccionaTuAeronave: "Select your aircraft",
    }
}


export default heroSection