import firebase from "firebase";
const db = () => firebase.firestore().collection("noticias")
export default {
    namespaced: true,
    state: {
        noticias: []
    },
    mutations: {
        GET_NOTICIAS(state) {
            db().onSnapshot(snapshot => {
                const noticias = []
                snapshot.forEach((doc) => {
                    const noticia = doc.data()
                    noticias.push({ id: doc.id, data: noticia })
                })
                state.noticias = noticias
            })
        }
    },
};
