export default [
    {
        zona: "Norte",
        regiones: [
            { region: 'Region de Arica y Parinacota', number: 'XV' },
            { region: 'Region de Tarapacá', number: 'I' },
            { region: 'Region de Antofagasta', number: 'II' },
            { region: 'Region de Atacama', number: 'III' },
            { region: 'Region de Coquimbo', number: 'IV' },
        ]
    },

    {
        zona: "Centro",
        regiones: [
            { region: 'Región de Valparaíso', number: 'V' },
            {
                region: "Región del Libertador Gral. Bernardo O'Higgins",
                number: 'VI'
            },
            { region: 'Región del Maule', number: 'VII' },
            { region: 'Región del Biobío', number: 'VIII' },
            {
                region: 'Región Metropolitana de Santiago',
                number: 'XIII'
            },
            { region: 'Región de Ñuble', number: 'XVI' }
        ]
    },
    {
        zona: "Sur",
        regiones: [
            {
                region: 'Región de la Araucanía',
                number: 'IX'
            },
            { region: 'Región de los Lagos', number: 'X' },
            { region: 'Región de los Ríos', number: 'XIV' },
            {
                region: 'Región Aisén del Gral. Carlos Ibañez del Campo',
                number: 'XI'
            },
            {
                region: 'Región de Magallanes y de la Antártica Chilena',
                number: 'XII'
            },
        ]
    },
]