<template>
  <div class="InputHeroText px-6">
    <h2>
      {{ lenguages[idioma].heroSection.buscador.destino || "Destino" }}
    </h2>
    <v-combobox
      class="InputHeroText__textField"
      :placeholder="
        lenguages[idioma].heroSection.buscador.ingresaElDestino ||
          'Ingresa el destino a viajar'
      "
      v-model="destinoEscogido"
      flat
      solo
      list="Destinos"
      @change="realizarBusqueda"
      :items="destinos"
      :item-color="colors.mainPurple"
      :color="colors.mainPurple"
      no-data-text="No existen destinos"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> No hay coincidencias </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "ElegirDestino",
  data() {
    return {
      destinoEscogido: "",
    };
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Buscador", ["busqueda"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapGetters("Buscador", ["destinos"]),
    ...mapGetters("Vuelos", ["soloDestinos"]),
    ...mapGetters("Experiencias", ["soloDestinosExperiencias"]),
  },
  methods: {
    ...mapMutations("Buscador", ["SET_BUSQUEDA_PARAMS"]),
    realizarBusqueda() {
      this.SET_BUSQUEDA_PARAMS({ destino: this.destinoEscogido });
    },
  },
  mounted() {
    this.destinoEscogido = this.busqueda.destino;
  },
};
</script>

<style lang="scss" scoped>
.InputHeroText {
  @include inputInside;
  @include flex(column, flex-start, flex-start);
  height: 73px;
  overflow: hidden;
  line-height: 18px;
  padding-top: 6px;
  padding: 10px;
  input {
    box-shadow: none;
    font-size: 0.9375em;
    padding: 0;
  }
}

.InputHeroText__textField {
  width: 100%;
}
</style>
