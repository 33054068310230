const pago = {
    metodosDePagoYDatosDelPasajero: "Payment methods and passenger data",
    datosDeLosPasajeros: "Passenger data",
    nombreCompleto: "Full Name",
    tipoYNumeroDeDocumento: "Type and number of document",
    paisDeOrigen: "Country",
    correoElectronico: "Email",
    numeroMovil: "Phone",
    direccion: "Address",
    peso: "Weight",
    siguiente: "Next",
    continuar: "Done",
    comoVasAPagar: "How are you going to pay?",
    fechaDeReserva: "Select the date of your reservation",
    declaro: "I declare that the data entered is correct",
    terminos: "I have read and accept the HummingBird Terms and Conditions",
    pagar: "Pay",
    paraFinalizar: "To finalize your reservation you must log in",
    iniciarConFB: "Log in with Facebook",
    iniciarConGoogle: "Log in with Google",
    actualizar: "Update my user data",
    enviarCorreo: "Send email to all passengers",
    codigoPromocional: "Enter promotional code",
    aceptar: "Accept",
    promocionAplicada: "Promotion applied successfully!",
    codigoIncorrecto: "Incorrect code",
    detallesDelVuelo: "Flight details",
    itauExperiencia: "This is an exclusive experience for Banco ITAU customers, you must enter the promotional code to continue"
}

export default pago