<template>
  <div class="CardNextFlight mx-auto" :class="{ addRating: mostrarRating }">
    <div class="CardNextFlight__info">
      <span v-if="$route.name != 'MiPerfil'" class="chip_destino">{{
        infoVuelo.data.destino
      }}</span>
      <h3 v-if="mostrarStatus" :style="colorStatus">
        {{ infoVuelo.data.status }}
      </h3>
      <div class="d-flex justify-space-between">
        <div class="CardNextFlight__info__departure text-left">
          <!-- <h3>ORIGEN</h3> -->
          <h3>
            {{ infoVuelo.data.origen }}
          </h3>
          <h4>{{ fechaConMesEscrito(infoVuelo.data.fecha) }}</h4>
          <h4>{{ infoVuelo.data.horaDespegue }}</h4>
        </div>
        <div class="CardNextFlight__info__conveyance">
          <img
            v-if="infoVuelo.data.aeronave === 'avion'"
            :src="icons.purple.plane"
          />
          <img v-else :src="icons.purple.helicopter.inclined" />
          <div class="CardNextFlight__info__conveyance__dots"></div>
          <div></div>
        </div>
        <div class="CardNextFlight__info__return text-right">
          <!-- <h3>DESTINO</h3> -->
          <h3>
            {{ infoVuelo.data.destino }}
          </h3>
          <h4 class="white-space">A</h4>
          <h4>{{ infoVuelo.data.horaAterrizaje }}</h4>
        </div>
      </div>
      <div class="CardNextFlight__info__divider"></div>
      <div class="CardNextFlight__info__price">
        <h2>${{ precioEnMiles(infoVuelo.data.precio) }}</h2>
        <span class="text-right">Precio por persona</span>
      </div>
      <div class="CardNextFlight__info__rating d-flex align-center">
        <span v-if="mostrarOperador" class="pr-3 d-none d-sm-block"
          ><b>{{ infoVuelo.data.operador }}</b></span
        >
        <span v-if="mostrarRating" class="pr-1 d-flex align-center">
          <b class="d-none d-sm-block"
            >{{
              infoVuelo.data.operador.id
                ? Math.round(
                    getRatingByOperadorId(infoVuelo.data.operador.id) * 10
                  ) / 10
                : Math.round(infoVuelo.data.rating * 10) / 10
            }}
          </b>
          <v-rating
            color="warning"
            :value="
              infoVuelo.data.operador.id
                ? getRatingByOperadorId(infoVuelo.data.operador.id)
                : +infoVuelo.data.rating
            "
            background-color="lighten-3"
            size="15"
            class="cardExperiencia__sales__rating"
            half-increments
            readonly
          />
        </span>
      </div>
    </div>

    <!--IMG -->
    <div
      class="CardNextFlight__img"
      :style="`backgroundImage: url('${infoVuelo.data.img}')`"
    ></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "CardProximoVuelo",
  props: ["infoVuelo"],
  computed: {
    ...mapState(["icons", "colors"]),
    ...mapGetters("Operadores", ["getRatingByOperadorId"]),
    fechaConMesEscrito: () => (fecha) => {
      const separarFecha = fecha.split("-").reverse();

      let mes = separarFecha[1];
      switch (mes) {
        case "01":
          mes = "ENE";
          break;
        case "02":
          mes = "FEB";
          break;
        case "03":
          mes = "MAR";
          break;
        case "04":
          mes = "ABR";
          break;
        case "05":
          mes = "MAY";
          break;
        case "06":
          mes = "JUN";
          break;
        case "07":
          mes = "JUL";
          break;
        case "08":
          mes = "AGO";
          break;
        case "09":
          mes = "SEP";
          break;
        case "10":
          mes = "OCT";
          break;
        case "11":
          mes = "NOV";
          break;
        case "12":
          mes = "DIC";
          break;
      }
      separarFecha[1] = mes;
      const newFecha = separarFecha.join("-").slice(0, 6);
      return newFecha;
    },
    precioEnMiles: () => (numero) => {
      if (numero === undefined) {
        numero = 0;
      }

      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    mostrarRating() {
      return this.$route.name === "ResultadosBusqueda" ||
        this.$route.name === "Home" ||
        this.$route.name === "Vuelos" ||
        this.$route.name === "MiPerfil"
        ? true
        : false;
    },
    mostrarOperador() {
      return this.$route.name === "CheckIn" ? true : false;
    },
    mostrarStatus() {
      return this.$route.name === "MiPerfil" ? true : false;
    },
    colorStatus() {
      return {
        fontSize: "14px",
        color:
          this.infoVuelo.data.status === "Confirmado"
            ? `${this.colors.green}`
            : this.infoVuelo.data.status === "Abierto"
            ? `${this.colors.beige}`
            : "inherit",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.CardNextFlight {
  background-color: $main-white;
  box-shadow: 3px 8px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  height: 145px;
  // max-width: 345px;
  transition: all 0.5s;
  &:hover {
    box-shadow: 3px 8px 15px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  &__info {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 3%;
    width: 62%;
    &__conveyance {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      img {
        width: 27px;
      }
      &__dots {
        border-bottom: 2px dashed #34d82c;
      }
    }
    &__divider {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    &__price {
      align-items: center;
      display: flex;
      justify-content: space-between;
      h2 {
        color: $main-purple;
      }
      span {
        font-size: 0.625em;
      }
    }

    &__rating {
      span {
        font-size: 12px;
        &:nth-child(2) {
          font-weight: 600;
        }
      }
    }
  }
  &__img {
    background-size: cover;
    background-position: center;
    border-radius: 0px 20px 20px 0px;
    height: 100%;
    position: relative;
    width: 39%;
  }
}
.addRating {
  height: 180px;
}

.chip_destino {
  background-color: $main-purple;
  border-radius: 30px;
  color: $main-white;
  font-size: 0.625em;
  font-weight: 700;
  margin-bottom: 8px;
  padding: 3px 14px;
  text-align: left;
  width: fit-content;
}

.white-space {
  visibility: hidden;
}

@media (min-width: 600px) {
  .CardNextFlight {
    min-width: 277px;
  }
}
@media (min-width: 730px) {
  .CardNextFlight {
    min-width: 331px;
  }
}

@media (min-width: 960px) {
  .CardNextFlight {
    min-width: 294px;
  }
}
</style>