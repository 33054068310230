const nav = {
    vuelos: "Vuelos",
    experiencias: "Experiencias",
    seguridadOperacional: "Seguridad Operacional",
    eresOperador: "¿Eres operador?",
    noticias: "Noticias",
    aerodromos: "Aeródromos",
    faq: "Preguntas Frecuentes",
    ingresa: "Ingresar",
    buscarVuelos: "Buscar",
    miPerfil: "Mi Perfil",
    seleccioneElIdioma: "Seleccione el idioma",
    seleccioneLaMoneda: "Seleccione la moneda"
}

export default nav