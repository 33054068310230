import Vue from 'vue'
import Vuex from 'vuex'
import Vuelos from './Vuelos'
import Experiencias from './Experiencias'
import Auth from './Auth'
import Buscador from './Buscador'
import Comentarios from './Comentarios'
import Operadores from './Operadores'
import Aerodromos from './Aerodromos'
import Aeronaves from './Aeronaves'
import Operaciones from './Operaciones'
import Ventas from './Ventas'
import Noticias from './Noticias'
import Lenguages from './Lenguages'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    colors: {
      mainPurple: '#645DC7',
      grayDisabled: '#C4C4C4',
      green: '#3CD52B',
      beige: '#DD8218'
    },
    icons: {
      purple: {
        experiencias: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2Ficono%20experience.svg?alt=media&token=d394e0b7-09dc-4186-8af9-937f28e5a769',
        vuelos: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2Ficono%20trayecto.svg?alt=media&token=a1196385-fd28-4549-acf9-75e2986a6a7f',
        mapMarker: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2Fpin%201%20map%20marker%20purple.svg?alt=media&token=410ef94d-9ee1-4969-ad7c-db0491ae1941',
        magnify: "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2FVectorpurple%20magnify.svg?alt=media&token=ca362d2f-7547-429c-b121-fbe4fa5689dd",
        user: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2Fuser(1)%201.svg?alt=media&token=0d79c69e-c563-4810-afc8-d5c54ecabf89',
        plane: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2FVector%20purple%20plane.svg?alt=media&token=62a5ef11-2154-4d7f-b98f-7195280e6948',
        doublePlane: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2Fdoubleplane%20(1).png?alt=media&token=fbcb1fb3-a723-4387-9285-09161e0d3f21',
        helicopter: {
          inclined: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2Faircraft%201purple%20helicopter.svg?alt=media&token=40e68cb1-0827-4def-85d9-0b7a54c40aca',
          straight: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2Faircraft%202%20(2).svg?alt=media&token=c2e50dcb-d83d-4d63-893f-5247a955d991'
        },
        helicopterAndPlane: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2FhelicopterAndPlane%20(1).png?alt=media&token=3ffa1ced-5e68-4477-9a71-3eec1f4a9619',
        seat: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2FGroup%2034%20purple%20seat.svg?alt=media&token=7d5e1edd-a08d-4bc7-a2a1-7db9be2f2202',
        luggage: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fpurple%2FVectorluggage.svg?alt=media&token=010d09e4-d0ae-41c8-a5c4-d0c6d6b66166'
      },
      gray: {
        backArrow: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fgray%2FArrow%202%20gray%20back%20arrow.svg?alt=media&token=fc913ae8-1e2d-48d9-9e9f-00a8d6e472a4'
      },
      white: {
        magnify: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fwhite%2FVectormagnify%20white.svg?alt=media&token=698eb6a7-e248-46d0-9efb-4c0040478ae8',
        backArrow: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fwhite%2FArrow%204to%20left%20white.svg?alt=media&token=4ffafbab-1c14-47b4-8daf-4e2df855ab66',
        helicopter: {
          straight: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fwhite%2Faircraft%201white.svg?alt=media&token=34eabfe3-e011-4f12-a513-673c8db3648d'
        },
        plane: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fwhite%2Fwhiteplane%20(1).png?alt=media&token=1963796a-7254-4133-9a6c-d9d2e124daa8'
      },
      green: {
        arrowToRight: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fgreen%2FArrow%201.svg?alt=media&token=89183daf-5c7d-4b9f-8ce1-9cd6726699a9'
      }
    },
    logos: {
      hummingbird: {
        main: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Flogos%2FLogotipo-09%20(1).png?alt=media&token=a000e345-0c82-4aac-8a35-0397449c29c7',
        mainSinBorde: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Flogos%2Flogosinborde%20(1).png?alt=media&token=4d392145-c4f1-499d-b67c-67c609741d3b',
        estiradoNegro: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Flogos%2Flogo%20negro.png?alt=media&token=baf775b3-4262-409e-b06d-0762af74593d'
      },
      whatsapp: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Flogos%2FGroupwhatsapp_logo.svg?alt=media&token=acc01fe2-4867-48ef-b6b5-f19adcfda155',
      facebook: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Fwhite%2FVectorfacebook%20outlined%20white.svg?alt=media&token=b7358fe2-930a-4c3f-9f6f-f9a2b730dcba',
      webpay: 'https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Flogos%2F1.WebpayPlus_FB_300px.png?alt=media&token=c6c732c2-0d71-464f-a4fc-a6b11344f9a3',
      flow: "https://firebasestorage.googleapis.com/v0/b/hummingbird-453db.appspot.com/o/assets%2Ficons%2Flogos%2Flogo-flow.svg?alt=media&token=c38ccdf6-eb8f-4353-a1cb-a84ee8086114"
    },
    contacto: {
      whatsapp: 56956350990
    },
    loading: false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Vuelos,
    Experiencias,
    Auth,
    Buscador,
    Ventas,
    Comentarios,
    Aerodromos,
    Aeronaves,
    Operaciones,
    Operadores,
    Noticias,
    Lenguages
  },
  plugins: [createPersistedState({
    paths: ['Ventas.vuelosElegidos', 'Ventas.datosVenta', 'Auth.usuario'],
  }),],
})